import React from "react"
import CustomTable from "../../../../../shared/customTable"
import { Link } from "react-router-dom"
import validate from "../../../../../shared/validation"
import MetaInfo from "../../../../../shared/getMetaInfo"
import parser from "html-react-parser"
import ApproveExpense from "../../ApproveExpense"
import RejectExpense from "../../RejectExpense"
import EmployeeHoverCard from '../../../../../shared/components/EmployeeHoverCard';
import Button from 'react-bootstrap/Button'

import {
  Avatar,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Badge,
  AppBar,
  Typography,
  Box,
  ButtonGroup,
} from "@material-ui/core"
import MaterialTable, { MTableToolbar } from "material-table"
import CustomFilters from "../../../../../shared/components/CustomFilter"
import FlairTable from "../../../../../shared/FlairTable"

import EditIcon from "@material-ui/icons/Edit"

function ToolBarComponent() {
  return (
    <Link to="/console/expenseslist/expense/new">
      <Button color="primary" variant="contained">
        Add New Expense
      </Button>
    </Link>
  )
}

function Presentation(props) {
  const { state, condition, tabPair, loggedInEmployee,modules,branchList } = props
  const metaInfo = new MetaInfo()
  const lookupBranchList = Object.assign({}, branchList);

  const columns = [
    {
      title: "Employee",
      field: "empCode",
      render: (row) => {
        return <EmployeeHoverCard employeeID={row.empCode} />;
      },
      dataType: "String",
    },
    {
      title: "Branch",
      field: "branch",
      cellStyle: {
        width: 100,
        minWidth: 100
        },
      dataType: "",
      lookup: lookupBranchList,
    },
    { title: "Spent date", 
      field: "spentDate",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "Date" },
    { title: "Expense Type",
      field: "expenseType",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "String" },
    { title: "Amount",
      field: "amount",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "Numeric" },
    {
      title: "Expense Doc",
      field: "attachmentName",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      render: (rowData) => (
        <div>
          {rowData.attachmentDetails.publicURL !== "" ? (
            <a
              style={{ fontSize: 10 }}
              rel="noopener noreferrer"
              target="_blank"
              href={rowData.attachmentDetails.publicURL}
            >
              {" "}
              {rowData.attachmentName}{" "}
            </a>
          ) : true ? (
            "---"
          ) : (
            rowData.attachmentDetails.sourcePath.slice(
              rowData.attachmentDetails.sourcePath.lastIndexOf("/") + 1
            )
          )}
        </div>
      ),
      dataType: "",
    },
    { title: "Vendor",
      field: "vendor",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "String" },
    { title: "Receipt",
      field: "receipt",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "String" },
    { title: "Departure Date",
      field: "departureDate",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "Date" },
    { title: "Return Date",
      field: "returnDate",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
        dataType: "Date" },
    {
      title: "Additional Details",
      field: "additionalDetails",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "",
    },
    { title: "Comment",
      field: "comment",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "" },
    {
      title: "Actions",
      field: "actions",
      dataType: "",
      render: (row) => (
        <div className="d-flex">
          <ApproveExpense row={row} />
          <RejectExpense row={row} />
          {((row.empCode === loggedInEmployee && !row.isApproved ) || ((modules.includes("timesheets-manager"))))? (
            <Link to={"/console/expenseslist/" + row.id + "/edit"}>
              <IconButton color="default">
                <EditIcon />
              </IconButton>
            </Link>
          ) : null}
        </div>
      ),
    },
  ]

  const data = Object.values(state[tabPair[condition]].data).map((item) => {
    const branch = metaInfo.getEmployeeKey(item.employeeID, "branch")
    return {
      empCode: item.employeeID,
      name: metaInfo.emailToName(item.employeeID),
      imageURL: metaInfo.getImage(item.employeeID),
      spentDate: validate.dateFormatter(item.spentDate),
      expenseType: item.expenseType,
      amount: "$" + item.amount,
      expenseDoc: item.expenseDoc,
      vendor: item.vendor,
      receipt: item.receipt,
      departureDate:
        item.hasOwnProperty("departureDate") &&
        !isNaN(Date.parse(new Date(item.departureDate)))
          ? validate.dateFormatter(item.departureDate)
          : "---",
      returnDate:
        item.hasOwnProperty("returnDate") &&
        !isNaN(Date.parse(new Date(item.returnDate)))
          ? validate.dateFormatter(item.returnDate)
          : "---",
      additionalDetails: item.additionalDetails.length
        ? parser(item.additionalDetails)
        : "---",
      id: item.id,
      branch: branch === undefined ? -1 : branchList.indexOf(branch),
      placementID: item.placementID,
      isApproved: item.isApproved,
      isRejected: item.isRejected,
      comment: item.hasOwnProperty("rejectedDetails")
        ? parser(item.rejectedDetails.reason)
        : "---",
      attachmentDetails: item.attachmentDetails,
      attachmentName: `${metaInfo.emailToName(item.employeeID)}_${metaInfo.clientIdToName(item.clientId)}_${
        item.placementID
      }`.replace(/\s/g, ""),
    }
  })
  console.log(data)
   const [newColumns, setNewColumns] = React.useState([])
   const [filterBy, setFilterBy] = React.useState({
     data: [],
     isFiltered: false,
   })
   const handleChange = (
     filteredData = [],
     isFiltered = false,
     newCol = columns
   ) => {
     console.log(filteredData, isFiltered, newCol)
     setFilterBy({
       data: filteredData,
       isFiltered: isFiltered,
     })

     setNewColumns(newCol)
   }


  const sendActions = {
  icon:()=> <Link to="/console/expenseslist/expense/new"><Button className="btn btn-success px-4 btn-md text-high">Add New Expense</Button></Link>,
            tooltip: "Add New Expense",
            isFreeAction: true,
            position: "toolbar",
  }


  return (
    <div>
      <FlairTable
      title="Letter Request Report"
      data={!filterBy.isFiltered ? data : filterBy.data}
      columns={columns}
      isLoading={
        state[tabPair[condition]].noOfLoadings === 0
          ? state[tabPair[condition]].isLoading
          : false
      }
      actions={sendActions}
    />
    </div>
  )
}

export default Presentation
