import React from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import validation from '../../../../../../shared/validation';
import { updateProfile } from '../../../../middleware/dynamicProfileRender';
import DeleteModal from '../DeleteModal/delete';
import EmployementHistoryEditModal from './employment';


const EmploymentHistory = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowDel, delModalShow] = React.useState(false);
    const {data,employeeID,isPersonal } = props
    const [index, setIndex] = React.useState(undefined)

    const handleEdit = (idx) => {
        setModalShow(true)
        setIndex(idx)
    }

    const handleAdd = () => {
        setModalShow(true)
        setIndex(undefined)
    }

    const dispatch = useDispatch()

    const handleDelete = (idx) => {
        delModalShow(true);
        setIndex(idx)
      };
  
    const handleDeleteData = (index) => {
        if (index > -1) { 
            data.splice(index, 1); 
          }
        delModalShow(false)
        let employmenthistory = data?.map((item) => item)
        const payload = {
            employmenthistory
        }
        setModalShow(false)
        dispatch(updateProfile(payload,employeeID,payload))
    }
    return (
        <>
            <EmployementHistoryEditModal
                show={modalShow}
                employeeID={employeeID}
                data={data}
                idx={index}
                onHide={() => setModalShow(false)}
            />
            <DeleteModal
                 show={modalShowDel}
                 index={index}
                 handleDelete={() => handleDeleteData(index)}
                 onHide={() => delModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Employment History</h5>
                        {
                            isPersonal ? <></> : <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleAdd()}><i className="fa-solid fa-square-plus fs-17"></i></Link>
                        }
                        
                    </div>
                   
                        {
                            data?.map((res, idx) => {
                                return (
                                    <div className="emplbg mb-2">
                                     <small>{'Employment ' + idx}</small>
                        <div className="d-flex justify-content-between mt-2">
                            <div>
                                <div className="d-flex">
                                    <div className="keyicon">
                                        <i className="fa-solid fa-city"></i>
                                    </div>
                                    <div className="ms-2">
                                        <h6 className="text-muted fw-normal fs-15 mt-0 mb-1"><span className=" align-self-center fw-bold fs-15 pe-2">{res?.client}</span> {validation.dateFormatter(res?.from)} - {validation.dateFormatter(res?.to)}</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-0">{res?.clientaddress}</h6>
                                        <h6 className="text-muted fw-normal mt-2 mb-1">{res?.vendorname}</h6>
                                        <h6 className="text-muted fw-normal mt-1 mb-1">{res?.vendoremail}</h6>
                                        <h6 className="text-muted fw-normal mt-1 mb-1">{res?.vendorphone}</h6>
                                    </div>
                                </div>
                            </div>
                            {
                                isPersonal ? <></> :
                                <div className="d-flex">
                                <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleEdit(idx)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                <Link to="#" className="linkbtn danger mt-0" onClick={() => handleDelete(idx)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                            </div>
                            }
                           
                        </div>
                                    </div>
                                )
                            })
                        }
                       
                   
                    {/* <div className="emplbg mb-2">
                        <small>Employment 2</small>
                        <div className="d-flex justify-content-between mt-2">
                            <div>
                                <div className="d-flex">
                                    <div className="keyicon">
                                        <i className="fa-solid fa-city"></i>
                                    </div>
                                    <div className="ms-2">
                                        <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">Infosya Pvt Limited</h5>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">UI/UX Designer</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Oct 2021 - Present</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-0">Hyderabad Telangana, India</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <Link to="#" className="linkbtn me-1 mt-0" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                <Link to="#" className="linkbtn danger mt-0" onClick={() => delModalShow(true)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="emplbg">
                        <small>Employment 3</small>
                        <div className="d-flex justify-content-between mt-2">
                            <div>
                                <div className="d-flex">
                                    <div className="keyicon">
                                        <i className="fa-solid fa-city"></i>
                                    </div>
                                    <div className="ms-2">
                                        <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">Infosya Pvt Limited</h5>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">UI/UX Designer</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Oct 2021 - Present</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-0">Hyderabad Telangana, India</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <Link to="#" className="linkbtn me-1 mt-0" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                <Link to="#" className="linkbtn danger mt-0" onClick={() => delModalShow(true)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                            </div>
                        </div>
                    </div> */}
                </Card.Body>
            </Card>
        </>
    );
};
export default EmploymentHistory;