import MaterialTable from 'material-table'
import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import MetaInfo from '../../../../shared/getMetaInfo'
import { useBulkPaymentsContext } from './context'
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom"

function SearchByReference(props) {
  const { state, services } = useBulkPaymentsContext()
  const metaData = new MetaInfo()
  const history = useHistory()

  useEffect(() => {
    services.loadAllPayments()
  }, [])

  const columns = [
    {
      title: "Unique ID",
      field: "bulkPaymentId",
      dataType: "String",
      defaultGroupOrder: 0,
      customSort: (a, b) => {
        const aVal = a.replace("PAY-", "")
        const bVal = b.replace("PAY-", "")
        return Number(bVal) - Number(aVal)
      }
    },
    {
      title: "Client",
      field: "clientName",
      dataType: "String",
      render: (row) => {
        if (row.clientID)
          return <Link to={"/console/clientslist/" + row.clientID}>{row.clientName}</Link>
        return "--"
      },
    },
    {
      title: "Reference number",
      field: "referenceNum",
    },
    {
      title: "Invoice Number",
      field: "invoiceID",
      dataType: "String",
    },
    {
      title: "Payment Date",
      field: "paymentDate",
      dataType: "Date",
    },
    {
      title: "Payment type",
      field: "paymentType",
      lookup: {
        0: "online",
        1: "cheque"
      }
    },
    {
      title: "Payment Amount",
      field: "paymentAmount",
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      render: (row) => {
        return (
          <span>
            <IconButton
              onClick={(e) => {
                if (row.bulkPaymentId.trim(" ").length) {
                  history.push('?tab=7&bulkPaymentId=' + row.bulkPaymentId)
                  return window.location.reload()
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </span>
        )
      },
    },
  ]

  const rows = state.allPayments.map((payment) => {
    return {
      invoiceID: payment.invoiceID,
      bulkPaymentId: payment?.bulkPaymentId || '',
      clientName: metaData.clientIdToName(payment.clientID),
      referenceNum: payment?.otherInfo?.referenceNumber || '',
      paymentDate: payment.paymentDate,
      paymentType: payment?.otherInfo?.paymentType || '',
      paymentAmount: payment.paymentAmount,
      clientID: payment.clientID
    }
  })

  return (
    <div>
      <MaterialTable
        title=""
        data={rows}
        columns={columns}
        isLoading={state.showLoader}
        // onSelectionChange={(rows) => setSelectedRows(rows)}
        options={{
          pageSize: 10,
          filtering: true,
          columnsButton: true,
          paginationPosition: "top",
          paginationType: "normal",
          selection: true,
          grouping: true
        }}
      />
    </div>
  )
}

export default SearchByReference
