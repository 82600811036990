import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { deleteEmployee, inviteEmployee, getTotalAndActivePlacements,movingEmployee } from "../../middleware";
import Presentation from "./Presentation";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

function Container(props) {
  const { clients, placements_settings_list,employee_suspended_list_data } = props
  const [placements, setPlacements] = useState([])
  const onInviteAgain = (listOfMails) => {
    const { inviteEmployee } = props;
    listOfMails.forEach((list) => {
      inviteEmployee({
        firstname: list.firstname,
        lastname: list.lastname,
        phonenumber: list.phone,
        email: list.email,
        dob: "",
        branch: list.employeeBranch,
      });
    });
  };

  const fetchPlacements = async () => {
    try {
      const data = await getTotalAndActivePlacements()
      setPlacements(data)
    } catch (error) {
      console.error(error);
    }
  }

  const getEndClientsForEmployee = (employeeId) => {
    const filteredPlacements = placements.filter((placement) => placement.employeeID === employeeId);
  
    const endClientIds = filteredPlacements.flatMap((placement) => {
      const clientDetails = placements_settings_list.find((c) => c.placementID === placement.id);
      if (!clientDetails) return [];
      const clients = Object.values(clientDetails.clients || {});
      return clients
        .filter((client) => client.clientType === "End Client")
        .map((client) => client.clientId);
    });
  
    const endClientNames = endClientIds.map((clientId) => {
      const endClient = clients.find((client) => client.clientId === clientId);
      return endClient ? endClient.businessName : "";
    });
  
    return endClientNames;
  };
        

  const onMovingEmployee = async (uid,payload) => {
    await movingEmployee(uid,payload)
  }

  useEffect(() => {
    fetchPlacements()
  }, [])

  if(!isLoaded(employee_suspended_list_data)) return <div>Loading....</div>
  return (
    <div>
      <Presentation placements={placements} {...props} onInviteAgain={onInviteAgain} onMovingEmployee={onMovingEmployee}  getEndClientsForEmployee={getEndClientsForEmployee} employee_suspended_list_data={employee_suspended_list_data}/>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [],
    clients: state.firestore.ordered.clients_list,
    placements_settings_list: state.firestore.ordered.placements_settings_list,
    employee_suspended_list_data: state.firestore.ordered.employee_suspended_list_data,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    inviteEmployee: (payload) => {
      dispatch(inviteEmployee(payload));
    },
    onDelete: (uid) => {
      dispatch(deleteEmployee(uid));
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "CLIENTS",
        where: [["isExist", "==", true]],
        storeAs: "clients_list",
      },
      {
        collectionGroup: "SETTINGS",
        where: [["id", "==", "client_details"]],
        storeAs: "placements_settings_list",
      },
      {
        collectionGroup: "WORK_QUEUE",
        where:[["id" ,"==" , "suspended_list_data"]],
        storeAs: "employee_suspended_list_data",
      }
    ]
  })
)(Container)
