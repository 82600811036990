import { Button, Dialog, DialogContent, IconButton, Typography, DialogActions, styled } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import styles from "../styles/dialogStyles"
import CloseIcon from '@material-ui/icons/Close';



const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose,hideDefaultClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      { !hideDefaultClose && onClose? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function useCustomDialog() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderContent = (props) => {
    const { dialogContent, title, buttonTitle, hideButton, maxWidth = "md",hideDefaultClose = false,disableEscapeKeyDown = false, btnSize = "small",dialogActions,disableBackdropClick  = false } = props;
    return (
      <div>
        <Button disableElevation variant="contained" color="primary" size={btnSize} hidden={hideButton} onClick={handleClickOpen}>
          <span>
            {buttonTitle}
          </span>
        </Button>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth={maxWidth}
          disableEscapeKeyDown = {disableEscapeKeyDown}
          disableBackdropClick = {disableBackdropClick }
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} hideDefaultClose={hideDefaultClose}>
            {title || ''}
          </DialogTitle>
          <DialogContent>
            {dialogContent}
          </DialogContent>
          {
            dialogActions ?
              <DialogActions>
                {dialogActions}
              </DialogActions>
            :
              null
          }
        </Dialog>
      </div>
    );
  };

  return {
    isDialogOpen: open,
    renderContent,
    closeDialog: handleClose,
    openDialog: handleClickOpen
  };

}

export default useCustomDialog;