import React, { useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";

const schema = yup.object().shape({
	tilte: yup.string().required("Title is required"),
	expirydate: yup.date().required("Expiry Date is required"),
	attachment:yup.string().required()
});

export default function DocumentsEditModal(props) {
	const {
		data,
		employeeID,
		idx,
		onHide,
		isFromWorkQueue = false,
		onUpdate = () => {},
	} = props;
	
	const dispatch = useDispatch();

	const handleSubmit = (values, actions) => {
		actions.setSubmitting(true);

		const callback = () => {
			actions.setSubmitting(false);
			onHide();
		};

		const formData = { ...values };

		if (isFromWorkQueue && onUpdate) {
			onUpdate(formData);
			return;
		}

		let documents = data?.map((item) => item) || [];

		if (!isNaN(idx)) {
			documents[idx] = formData;
		} else {
			documents?.push(formData);
		}

		const payload = {
			documents,
		};

		dispatch(updateProfile(payload, employeeID, payload, callback));
	};

	const loadInitialValues = () => {
		if (isFromWorkQueue && data) {
			return data;
		} else if (!isNaN(idx)) {
			return data[idx];
		} else {
			return {};
		}
	};

	const getSaveButtonText = () => {
		if (isFromWorkQueue && data) {
			return "Approve";
		} else if (!isNaN(idx)) {
			return "Save Changes";
		} else {
			return "Create";
		}
	};

	return (
		<Modal
			{...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			enforceFocus={!isFromWorkQueue}
		>
			<Formik
				validationSchema={schema}
				initialValues={loadInitialValues()}
				onSubmit={handleSubmit}
				enableReinitialize={true}
			>
				{(formik) => {
					return (
						<Form>
							<AppModalHeader
								title={!isNaN(idx) ? "Edit Documents" : "Create Documents"}
							/>
							<Modal.Body>
								<Row>
									<Col xl={4}>
										<Field
											name="tilte"
											type="text"
											label={"Title *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="expirydate"
											type="date"
											label={"Expiry Date *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										 <Field
											name="attachment"
											component={FileUploadForm}
											label="Document *"
											type="text"
											employeeId={employeeID}
											sectionKey="documents"
											/>
									</Col>
								</Row>
							</Modal.Body>
							{props.actions ? (
								props.actions(formik)
							) : (
								<AppModalFooter
									saveButtonText={getSaveButtonText()}
									saveButtonDisabled={
										!formik.isValid ||
										formik.isSubmitting ||
										!formik.dirty 
									}
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
