import React from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateProfile } from '../../../../middleware/dynamicProfileRender';
import SSNEditModal from './ssn';
import DeleteModal from '../DeleteModal/delete';


function SsnEditModal(props) {
    return (
        <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Edit Work Authorization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Type *</Form.Label>
                            <Form.Select>
                                <option>Type 1</option>
                                <option>Type 2</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Phone *</Form.Label>
                            <InputGroup className="mb-3">
                                <DropdownButton variant="outline-secondary" title="+1" id="phonenum">
                                    <Dropdown.Item href="#">+1</Dropdown.Item>
                                    <Dropdown.Item href="#">+99</Dropdown.Item>
                                    <Dropdown.Item href="#">+880</Dropdown.Item>
                                    <Dropdown.Item href="#">+1</Dropdown.Item>
                                    <Dropdown.Item href="#">+99</Dropdown.Item>
                                    <Dropdown.Item href="#">+880</Dropdown.Item>
                                </DropdownButton>
                                <Form.Control type="" placeholder="" />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Issue Date *</Form.Label>
                            <Form.Control type="date" placeholder="" />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control type="date" placeholder="" />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Conuntry</Form.Label>
                            <Form.Select>
                                <option>Bangladesh</option>
                                <option>India</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Valid From *</Form.Label>
                            <Form.Control type="date" placeholder="" />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Aline Number</Form.Label>
                            <Form.Control type="" placeholder="" />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>N/A</Form.Label>
                            <Form.Select>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Upload Document *</Form.Label>
                            <Form.Control type="file" placeholder="" />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="text-end">
                    <button className="btn btn-success rounded-pill px-4 btn-lg">Save Changes</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}



const Ssn = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowDel, delModalShow] = React.useState(false);
    const {data,employeeID,isPersonal} = props
    const [index, setIndex] = React.useState(undefined)

    const handleEdit = (idx) => {
        setModalShow(true)
        setIndex(idx)
    }

    const handleAdd = () => {
        setModalShow(true)
        setIndex(undefined)
    }

    const dispatch = useDispatch()

    const handleDelete = (idx) => {
        delModalShow(true);
        setIndex(idx)
      };
  
    const handleDeleteData = (index) => {
        if (index > -1) { 
            data.splice(index, 1); 
          }
        delModalShow(false)
        let ssn = data?.map((item) => item)
        const payload = {
            ssn
        }
        setModalShow(false)
        dispatch(updateProfile(payload,employeeID,payload))
    }

    return (
        <>
            <SSNEditModal
                show={modalShow}
                employeeID={employeeID}
                data={data}
                idx={index}
                onHide={() => setModalShow(false)}
            />
             <DeleteModal
                 show={modalShowDel}
                 index={index}
                 handleDelete={() => handleDeleteData(index)}
                 onHide={() => delModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">SSN</h5>
                        {
                            isPersonal ? <></> :
                            <Link to="#"className="linkbtn me-1 mt-0" onClick={() => handleAdd()}><i className="fa-solid fa-square-plus fs-17"></i></Link>
                        }
                    </div>
                    {
                        data?.map((res, idx) => {
                            return (
                                <div className="emplbg mb-2">
                        <div className="d-block d-lg-flex justify-content-between my-1">
                            <div>       
                                    <div className="ms-2">
                                        <h4 className="text-muted fw-bold mt-2">{res?.ssn}</h4>
                                    </div>
                            </div>
                            <div className="d-flex mt-2 mt-lg-0">
                                <a href={res?.ssndocument} target="_blank" class="wauth me-1 badge-soft-danger badge rounded-pill fw-medium" rel="noreferrer"><i className="fa-regular fa-folder-open pe-1"></i>View Document</a>
                                {
                                    isPersonal ? <></> :
                                    <>
                                     <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleEdit(idx)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                <Link to="#" className="linkbtn danger mt-0" onClick={() => handleDelete(idx)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                            
                                    </>
                                }
                               </div>
                        </div>
                    </div>
                            )
                        })
                    }
                    
                </Card.Body>
            </Card>
        </>
    );
};
export default Ssn;