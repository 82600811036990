import { isBefore } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import CircularSpinner from "../../../../shared/circularSpinner";
import Presentation from "./Presentation";

function Container(props) {
  const { clients, placements_list, names, placements_settings_list } = props

  const [state, setState] = useState({
    data: [],
    status: false,
  })

  useEffect(() => {
    const statusList = ["Active", "Inactive"]
    if (isLoaded(clients) && isLoaded(placements_list) && isLoaded(placements_settings_list)) {
      const getTotalPlacements = (clientId) => {
        return placements_list
					.filter((p) => {
            const clientDetails = placements_settings_list?.find(
							(c) => c.placementID === p.id
						);
						if (!clientDetails) return false;
						const clients = Object.keys(clientDetails?.clients || {});
						const isClientExists = clients.includes(clientId);
						return isClientExists && p.draft === false;
					})
					.map((item) => item.employeeID);
      };
      const getActivePlacements = (clientId) => {
        return placements_list
					.filter((p) => {
            const clientDetails = placements_settings_list?.find(
							(c) => c.placementID === p.id
						);
						if (!clientDetails) return false;
						const clients = Object.keys(clientDetails?.clients || {});
						const isClientExists = clients.includes(clientId);
						return (
							isClientExists &&
							p.draft === false &&
							isBefore(new Date(), new Date(p.endDate)) &&
							!p?.closingReason?.length > 0
						);
					})
					.map((item) => item.employeeID);
      };

      const getActiveConsultants = (clientId) => {
        const activeConsultants = getActivePlacements(clientId);
        return activeConsultants.map((employeeID) => names[employeeID]);
      };

      let data = clients.map((client) => {
        return {
          businessDisplayName: client.businessDisplayName,
          businessName: client.businessName,
          clientType: client.category,
          clientId: client.clientId,
          netTerms: client.netTerms,
          jobTerminationNotice: client.jobTerminationNotice,
          status:
            getActiveConsultants(client.clientId).length === 0
              ? statusList.indexOf("Inactive")
              : statusList.indexOf("Active"),
          activeConsultants: getActiveConsultants(client.clientId).length,
          totalPlacements: getTotalPlacements(client.clientId).length,
          activePlacements: getActivePlacements(client.clientId).length,
        };
      });
      setState((prevState) => ({
        ...prevState,
        data,
        status: true,
      }))
    }
  }, [clients, placements_list, names, placements_settings_list])
  if (state.status) return <Presentation {...state} />
  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.firestore.ordered.clients_list,
    placements_list: state.firestore.ordered.placements_list,
    names: state.employee.employeeList.metaInfoofEmployees.data,
    placements_settings_list: state.firestore.ordered.placements_settings_list,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "CLIENTS",
        where: [["isExist", "==", true]],
        storeAs: "clients_list",
      },
      {
        collectionGroup: "PLACEMENTS",
        where: [["isExist", "==", true]],
        orderBy: [["id", "asc"]],
        storeAs: "placements_list",
      },
      {
        collectionGroup: "SETTINGS",
        where: [["id", "==", "client_details"]],
        storeAs: "placements_settings_list",
      }
    ]
  })
)(Container)