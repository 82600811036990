import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import firebase from "../../config/fbConfig";
import { successMsg, errorMsg } from "../../shared/SnackBars/index"
import make_API_call from "../../providers/REST_API"

const AnnouncementsContext = createContext();

const useAnnouncementContext = () => useContext(AnnouncementsContext);

const initialState = {
  branchList: [],
  departmentList: [],
  categoryList: [],
  employeesList: [],
  announcement: {},
  showLoader: false,
  allAnnouncements:[],
  viewAnnouncement:{},
  employeeAnnouncements:[],
  employeeStatusList: []
};

function AnnouncementsContextProvider(props) {
  const [branchList, setBranchList] = useState(initialState.branchList);
  const [departmentList, setDepartmentList] = useState(initialState.departmentList);
  const [categoryList, setCategoryList] = useState(initialState.categoryList);
  const [employeesList, setEmployeesList] = useState(initialState.employeesList);
  const [announcement, setAnnouncement] = useState(initialState.announcement);
  const [showLoader, setShowLoader] = useState(initialState.showLoader);
  const [allAnnouncements, setAllAnnouncements] = useState(initialState.allAnnouncements);
  const [viewAnnouncement, setViewAnnouncement] = useState(initialState.viewAnnouncement);
  const [employeeAnnouncements, setEmployeeAnnouncements] = useState(initialState.employeeAnnouncements);
  const [employeeStatusList, setEmployeeStatusList] = useState(initialState.employeeStatusList);

  const state = {
    branchList,
    departmentList,
    categoryList,
    employeesList,
    announcement,
    showLoader,
    allAnnouncements,
    viewAnnouncement,
    employeeAnnouncements,
    employeeStatusList
  };

  const stateSetters = {
    setBranchList,
    setDepartmentList,
    setCategoryList,
    setEmployeesList,
    setAnnouncement,
    setShowLoader,
    setAllAnnouncements,
    setViewAnnouncement,
    setEmployeeAnnouncements,
    setEmployeeStatusList
  };

  const branches = useSelector(
    (state) =>
      state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter(
        (field) => field.name === "branch"
      )?.[0]?.values || []
  );

  const getBranchList = () => {
    setBranchList(branches);
  };

  const getDepartmentList = () => {
    setDepartmentList([
      "Java",
      "DevOps/Cloud",
      "Networking/Security",
      "Python",
      "QA",
      ".Net",
      "Data Science",
      "Big Data",
      "CRM",
      "Legal",
      "HR",
      "Accounts",
      "Bench sales",
    ])
  }

  const getCategoryList = () => {
    setCategoryList([
      "W2",
      "C2C"
    ])
  }

  const getEmployeeStatusList = () => {
    setEmployeeStatusList([
      "Bench",
      "Working",
      "Training",
    ])
  }

  const getEmployeesList = async () => {
    try {
      setShowLoader(true);
      const employeesSnapshot = await firebase
        .firestore()
        .collection("EMPLOYEES")
        .where("status", "==", "active")
        .where("isExist", "==", true)
        .get();
      const employees = employeesSnapshot.docs.map((doc) => {
        const perosonalData = doc.data()?.personal;
        const name = [
          perosonalData.firstname,
          perosonalData.middlename,
          perosonalData.lastname,
        ]
          .filter((i) => i)
          .join(" ");
        return { name, id: doc.id };
      });
      setEmployeesList(employees);
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  };

  const getAnnouncement = async () => {
    try {
      const res = await make_API_call('get',`/console/announcements/view`)
      setAnnouncement(res.announcement)
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to get Announcement`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements -  get announcement',
        functionName: 'getAnnouncement'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  };

  const getAllAnnouncements = async () => {
    try {
      setShowLoader(true);
      const res = await make_API_call('get',`/console/announcements/allannouncements`)
      setAllAnnouncements(res.announcements)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to get Announcements`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements -  get all announcements',
        functionName: 'getAllAnnouncements'
      }
      make_API_call('post', '/errors/report', errorInfo)
      setShowLoader(false);
    }
  };

  const addNewAnnouncemnt = async (payload, callback) => {
    try {
      await make_API_call('post',`/console/announcements/addannouncement`,payload)
      successMsg(`Announcement successfully sent`)
      getAllAnnouncements()
      callback();
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to Send Announcement`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements -  new announcements',
        functionName: 'addNewAnnouncemnt'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  };

  const acknowledgeAnnouncement = async (closeDialog,announcementId) => {
    const payload = {announcementId : announcementId}
    try {
      setShowLoader(true);
      await make_API_call('put',`/console/announcements/acknowledged`,payload)
      await getAnnouncement()
      closeDialog()
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to Acknowledge Announcement`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements - acknowledge Announcement',
        functionName: 'acknowledgeAnnouncement'
      }
      make_API_call('post', '/errors/report', errorInfo)
      setShowLoader(false);
    }
  };

  const declineAnnouncement = async (closeDialog,announcementId) => {
    const payload = {announcementId : announcementId}
    try {
      setShowLoader(true);
      await make_API_call('put',`/console/announcements/declined`,payload)
      await getAnnouncement()
      closeDialog()
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to decline Announcement`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements -  decline Announcement',
        functionName: 'declineAnnouncement'
      }
      make_API_call('post', '/errors/report', errorInfo)
      setShowLoader(false);
    }
  };

  const viewAnnouncementbyId = async (payload) => {
    const {announcementId} = payload
    try {
      setShowLoader(true);
      const res = await make_API_call('get',`/console/announcements/${announcementId}`)
      setViewAnnouncement(res.announcement)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to get Announcement`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements -  View announcement',
        functionName: 'viewAnnouncementbyId'
      }
      make_API_call('post', '/errors/report', errorInfo)
      setShowLoader(false);
    }
  };

  const getEmployeeAnnouncements = async (employeeID) => {
    try {
      setShowLoader(true);
      const res = await make_API_call('get',`/console/announcements/employee/${employeeID}`)
      console.log("kalyan",res)
      setEmployeeAnnouncements(res.announcements)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg(`Failed to get Announcements`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Announcements -  get Employee announcements',
        functionName: 'getEmployeeAnnouncements'
      }
      make_API_call('post', '/errors/report', errorInfo)
      setShowLoader(false);
    }
  };

  const services = {
    getBranchList,
    getDepartmentList,
    getCategoryList,
    getEmployeesList,
    getAnnouncement,
    addNewAnnouncemnt,
    acknowledgeAnnouncement,
    declineAnnouncement,
    getAllAnnouncements,
    viewAnnouncementbyId,
    getEmployeeAnnouncements,
    getEmployeeStatusList
  };

  return (
    <AnnouncementsContext.Provider value={{ state, stateSetters, services }}>
      {props.children}
    </AnnouncementsContext.Provider>
  );
}

export { useAnnouncementContext, AnnouncementsContextProvider };
