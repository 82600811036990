import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getFirebase } from "react-redux-firebase";

const store = createStore(
  rootReducer,
  window.location.href.includes("http://localhost")
    ? composeWithDevTools(
        applyMiddleware(thunk.withExtraArgument({ getFirebase }))
      )
    : applyMiddleware(thunk.withExtraArgument({ getFirebase }))
);

export default store;
