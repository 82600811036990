import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup  from 'yup'
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import { RegexExp } from "../../../../../../shared/regex";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";

const schema = yup.object().shape({
    client: yup.string().matches(RegexExp.name, 'client name should be valid'),
    clientaddress: yup.string().matches(RegexExp.alphanumeric, 'Client adaress is not valid'),
    experience: yup.string().required().matches(RegexExp.number, 'experience should be valid'),
    vendorname: yup.string().matches(RegexExp.name, 'vendor name should be valid'),
    vendoremail: yup.string().matches(RegexExp.email ,  'email should be valid'),
    vendorphone: yup.string().matches(RegexExp.phonenumber, 'vendor phone should be valid'),
    yourworkingmailid: yup.string().matches(RegexExp.email ,  'email should be valid'),
    from:yup.date(),
    to:yup.date()
  });

export default function EmployementHistoryEditModal(props) {
  const { data,employeeID, idx, onHide } = props;

  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return data[idx];
    } else {
      return {};
    }
  };

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit Employement History";
    } else {
      return "Create Employement History";
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const callback = () => {
      actions.setSubmitting(false);
      onHide();
    };

    const formData = { ...values };

    let employmenthistory = data?.map((item) => item) || [];

    if (!isNaN(idx)) {
      employmenthistory[idx] = formData;
    } else {
      employmenthistory.push(formData);
    }

    const payload = {
      employmenthistory,
    };

    dispatch(updateProfile(payload, employeeID, payload, callback));
  };
  const dispatch = useDispatch()
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Formik
        validationSchema={schema}
        validateOnMount={true}
        initialValues={loadInitialValues()}
        onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <Form>
              <AppModalHeader title={getTitleText()} />
              <Modal.Body>
              <Row>
                <Col xl={4}>
                  <Field
                    name="client"
                    type="text"
                    label={'Client'}
                    component={TextFormField}
                  />
                </Col>
                <Col xl={4}>
                <Field
                    name="clientaddress"
                    type="text"
                    label={'Client address'}
                    component={TextFormField}
                  />
                </Col>
                {/* <Col xl={4}>
                <StateField {...data}/>
                </Col>
                <Col xl={4}>
                <CountryField {...data}/>
                </Col> */}
                 <Col xl={4}>
                <Field
                    name="yourworkingmailid"
                    type="text"
                    label={'Manager reference email'}
                    component={TextFormField}
                  />
                </Col>
                <Col xl={4}>
                  <Field
                    name="vendorname"
                    type="text"
                    label={'Vendor name'}
                    component={TextFormField}
                  />
                </Col>
                <Col xl={4}>
                  <Field
                    name="vendorphone"
                    type="text"
                    label={'Vendor phone'}
                    component={TextFormField}
                  />
                </Col>
                <Col xl={4}>
                  <Field
                    name="vendoremail"
                    type="text"
                    label={'Vendor email'}
                    component={TextFormField}
                  />
                </Col>
                <Col xl={4}>
                  <Field
                    name="from"
                    type="text"
                    label={'From date'}
                    component={DateFormField}
                  />
                </Col>
                <Col xl={4}>
                  <Field
                    name="to"
                    type="text"
                    label={'To date'}
                    component={DateFormField}
                  />
                </Col>
                <Col xl={4}>
                  <Field
                    name="experience"
                    type='number'
                    label={'Experience *'}
                    component={TextFormField}
                  />
                </Col>
              </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText={getSaveButtonText()}
                saveButtonDisabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty
                }
              />
            </Form>
              
            );
          }}
        
        </Formik>
    </Modal>
  );
}
