import React, { useEffect, useState } from "react"
import MaterialTable from "material-table"
import { IconButton } from "@material-ui/core"
import { Edit as EditIcon } from "@material-ui/icons"
import validate from "../../../../../shared/validation"
import { useInvoiceListContext } from "../context"
import firebase from "../../../../../config/fbConfig"
import { errorMsg } from "../../../../../shared/SnackBars/index"
import make_API_call from "../../../../../providers/REST_API"
import { useHistory } from "react-router-dom"

function PaymentHistory(props) {
  const {
    handleUpdate,
    handleTabChange,
    setIsEdit,
    invoicedAmount,
    setIsUpdatePayment,
    invoiceID,
  } = props
  const { state, services } = useInvoiceListContext()
  const [showLoader, setShowLoader] = useState(true);
  const [paymentHistory, setpaymentHistory] = useState([]);
  const history = useHistory()


  const getPaymentHistory = async () => {
    try {
      setShowLoader(true);
      await firebase
        .firestore()
        .collection("INVOICES")
        .doc(invoiceID)
        .collection("PAYMENTS_HISTORY")
        .onSnapshot(snap => {
          const data = snap.docs.map(d => d.data())
          setpaymentHistory(data)
        })

      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Sent Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - payment History of Invoices',
        functionName: 'getPaymentHistory'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  useEffect(() => {
    getPaymentHistory()
  }, [invoiceID])

  // console.log(history, "s")
  const columns = [
    { title: "Date/ Time", field: "createdAt" },
    { title: "Payment Method", field: "paymentMethod" },
    { title: "Payment Amount", field: "paymentAmount" },
    { title: "Payment Date", field: "paymentDate" },
    { title: "Refernce Number", field: "referenceNumber" },
    {
      title: "Attachment",
      field: "files",
      render: (row) =>
        row.noReference ? null :
          (row.attachment !== "" ? (
            <a
              className="custom-chip text-underline c-pointer"
              target="_blank"
              href={row.attachment.publicURL}
            >
              {`${row.attachment.sourcePath}`}
            </a>
          ) : null),
    },
    { title: "Action By", field: "createdBy" },

    {
      title: "Description",
      field: "description",
    },
    {
      title: "Actions",
      field: "",
      render: (rowData) => {
        // console.log(rowData)
        return (
          <span>
            <IconButton
              onClick={(e) => {
                if (rowData?.bulkPaymentId?.length) {
                  history.push('?tab=7&bulkPaymentId=' + rowData.bulkPaymentId)
                  return window.location.reload()
                }
                handleTabChange(null, 1)
                handleUpdate(e, rowData.data)
                setIsEdit(true)
                setIsUpdatePayment(true)
              }}
            >
              <EditIcon />
            </IconButton>
          </span>
        )
      },
    },
  ]

  let data =
    paymentHistory?.map((record) => {
      // console.log(record.noReference)
      return {
        // ...record,
        data: {
          clientId: record.clientID,
          paymentMethod: record.otherInfo.paymentType,
          paymentDate: record.paymentDate,
          referenceNumber: record.otherInfo.referenceNumber
            ? record.otherInfo.referenceNumber
            : "",
          attachment: Object.keys(record.otherInfo.attachmentDetails).length
            ? record.otherInfo.attachmentDetails : {
              publicURL: "",
              sourcePath: ""
            },
          isUploading: false,
          paymentAmount: record.paymentAmount ? record.paymentAmount : 0,
          discountDetails: record.discountDetails.length
            ? record.discountDetails
            : [
              {
                name: "",
                value: 0,
                type: "",
              },
            ],
          receivedAmount: 0,
          noReference: record.noReference ? record.noReference :
            (Object.keys(record.otherInfo.attachmentDetails).length ?
              (record?.otherInfo?.attachmentDetails?.publicURL?.length < 1) : true),
          paymentDoneAmount: record.paymentAmount,
          discountDoneDetails: services.calculateDiscount(
            invoicedAmount,
            record.discountDetails
          ),
          paymentID: record.id,
        },
        paymentMethod: record.otherInfo.paymentType,
        paymentAmount: record.paymentAmount ? record.paymentAmount : 0,
        paymentDate: record.paymentDate,
        noReference: record.noReference ? record.noReference :
          (Object.keys(record.otherInfo.attachmentDetails).length ?
            (record?.otherInfo?.attachmentDetails?.publicURL?.length < 1) : true),
        referenceNumber: record.otherInfo.referenceNumber
          ? record.otherInfo.referenceNumber
          : "----",
        attachment: Object.keys(record.otherInfo.attachmentDetails).length
          ? record.otherInfo.attachmentDetails : {
            publicURL: "",
            sourcePath: ""
          }
        ,
        createdAt: validate.dateAndTimeFormatter(record.createdAt),
        createdBy: record.createdBy,
        bulkPaymentId: record?.bulkPaymentId || ''
      }
    }) || []
  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        options={{
          paginationPosition: "top",
          pageSize: 10,
          paginationType: "normal",
          columnsButton: true,
          filtering: true,
        }}
      />
    </div>
  )
}

export default PaymentHistory
