import React,{useEffect} from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Typography, DialogContent, Button, DialogActions, IconButton, CircularProgress, FormHelperText } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { useNewInvoiceContext } from "./context";
import styles from "../../styles/dialogStyles"

function GenerateButton() {
  const { state, services,stateSetters } = useNewInvoiceContext()
  const [open, setOpen] = React.useState(false);
  const placement = services.getPlacementById(state.placementID)
  const client = services.getClientById(state.clientID)
  const employee=services.getEmployeeById(state.employeeID)
  const category=employee?.personal?.category
  console.log("internalclient",client?.isInternal)
  console.log("emppppp",category)

  if(client?.isInternal){
    stateSetters.setTag("Internal")
  }
  else if(category==="C2C"){
    stateSetters.setTag("Sub Contract")
  }
  else if (placement?.paidWhenPaid){
  stateSetters.setTag("Paid When Paid")
  }
  else
  stateSetters.setTag("")


  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onGenerate = () => {
    handleClose();
    services.createNewInvoice(history)
  }


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div className="text-center" >
      <Button
        disabled={state.isDisableButton || state.isNewInvoiceCreating}
        onClick={handleClickOpen} 
        size="large" 
        color="primary" 
        variant="contained" >
        {state.isNewInvoiceCreating ? "Generating" : "Generate"}
      </Button>
      <Dialog 
        onClose={handleClose} 
        open={open}
        aria-labelledby="customized-dialog-title">
        <DialogTitle 
        onClose={handleClose}
        id="customized-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You want to generate the invoice
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={onGenerate} 
            disabled={state.isDisableButton} 
            variant="contained" 
            color="primary">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GenerateButton