import {
	errorMsg,
	waitingMsg,
	stopWaitMsg,
	successMsg,
} from '../../../shared/SnackBars/index'
import axios from 'axios'
import {
	locationsFailure,
	locationsRequest,
	locationsSuccess,
	placementTimesheetsDataFailure,
	placementTimesheetsDataReq,
	placementTimesheetsDataSuccess,
	setListener,
	employeePlacementDataReq,
	employeePlacementDataSuccess,
	employeePlacementDataFailure
} from '../actions/actionCreators'
import firebase from '../../../config/fbConfig'
import make_API_call from '../../../providers/REST_API'

export function initiateNewPlacement(payload, history, closeTab) {
	return (dispatch) => {
		waitingMsg('Placement initiating...')
		const {
			clientId,
			description,
			clientDomain,
			jobTitle,
			netTerms,
			startDate,
			endDate,
			projectEndDate,
			employeeID,
			paidWhenPaid
		} = payload

		const newPlacementInputs = {
			clientId,
			description,
			clientDomain,
			jobTitle,
			netTerms,
			startDate,
			endDate,
			projectEndDate,
			employeeID,
			paidWhenPaid
		}
		Object.entries(newPlacementInputs).forEach(([key, value]) => {
			if (!value) {
				stopWaitMsg()
				return () => errorMsg('Please fill all the required fields')
			}
		})

		axios
			.post(
				`/employees/${newPlacementInputs.employeeID}/placements/new`,
				newPlacementInputs
			)
			.then((response) => {
				console.log(response.data)
				stopWaitMsg()
				successMsg(response.data.message)
				closeTab('general')
				history.push(
					`/console/placements/${newPlacementInputs.employeeID}/${response.data.placementID}`
				)
			})
			.catch((err) => {
				console.error(err)
				stopWaitMsg()
				errorMsg(err.data.message)
			})
	}
}

export function addSectionToPlacement(
	payload,
	sectionName,
	uid,
	placementID,
	callback,
	closeTab = (dontCare) => { }
) {
	return (dispatch) => {
		waitingMsg('Creating...')
		axios
			.post(
				`/employees/${uid}/placements/${placementID}/addsection?name=${sectionName}`,
				payload
			)
			.then((response) => {
				stopWaitMsg()
				console.log(response)
				successMsg(response.data.message)
				closeTab(sectionName)
				callback()
			})
			.catch((err) => {
				console.error(err)
				stopWaitMsg()
				errorMsg(err?.data?.message)
			})
	}
}

export function updatePlacement(
	payload,
	sectionName,
	uid,
	placementID,
	callback = () => { },
	closeTab = (dontCare) => { }
) {
	return (dispatch) => {
		waitingMsg(`Updating ${sectionName}...`)
		axios
			.put(
				`/employees/${uid}/placements/${placementID}/updateplacement?name=${sectionName}`,
				payload
			)
			.then((response) => {
				console.log(response)
				stopWaitMsg()
				closeTab(sectionName)
				successMsg(response.data.message)
				callback()
			})
			.catch((err) => {
				console.error(err)
				stopWaitMsg()
				errorMsg(err.data.message)
			})
	}
}

export function deletePlacement(placementID, uid) {
	return (dispatch) => {
		waitingMsg(`Deleting ${placementID}...`)
		axios
			.delete(`/employees/${uid}/placements/${placementID}/delete`)
			.then((response) => {
				console.log(response)
				stopWaitMsg()
				successMsg(response.data.message)
			})
			.catch((err) => {
				console.error(err)
				stopWaitMsg()
				errorMsg(err.data.message)
			})
	}
}

export function getLocations(clientId) {
	return function (dispatch, getState, { getFirebase }) {
		dispatch(locationsRequest())
		return firebase
			.firestore()
			.collectionGroup('CLIENTS_LOCATIONS')
			.where('isExist', '==', true)
			.where('clientId', '==', clientId)
			.get()
			.then((snap) => {
				if (snap.size < 1) return dispatch(locationsFailure(null))
				return dispatch(locationsSuccess(snap.docs.map((doc) => doc.data())))
			})
			.catch((err) => {
				console.error(err)
				return dispatch(locationsFailure(err))
			})
	}
}

const timesheetsData = (placementID, dispatch) => {
	const db = firebase.firestore()
	dispatch(placementTimesheetsDataReq())
	const subscribe = db
		.collectionGroup('SETTINGS')
		.where('id', '==', 'timesheets')
		.where('placementID', '==', placementID)
		.limit(1)
		.onSnapshot(
			(snap) => {
				if (snap.size > 0) {
					const data = snap.docs[0].data()
					return dispatch(placementTimesheetsDataSuccess({ data }))
				}
			},
			(err) => {
				console.error(err)
				return dispatch(placementTimesheetsDataFailure(err))
			}
		)

	dispatch(setListener(subscribe, 'timesheets'))
}

const projectList = (dispatch) => {
	const db = firebase.firestore()
	dispatch(placementTimesheetsDataReq())
	db.collection('PROJECTS').onSnapshot(
		(projectsSnap) => {
			const data = {}
			projectsSnap.docs.forEach((doc) => {
				data[doc.id] = doc.data()
			})
			return dispatch(
				placementTimesheetsDataSuccess({ projectsList: Object.values(data) })
			)
		},
		(err) => {
			console.error(err)
			return dispatch(placementTimesheetsDataFailure(err))
		}
	)
}

export const getPlacementsTimesheetsData =
	(placementID, employeeID) => (dispatch, getState) => {
		const data = {
			approvals: [],
		}
		const empModules = getState().employee.employeeModules.allModules
		dispatch(placementTimesheetsDataReq())

		try {
			timesheetsData(placementID, dispatch);
			projectList(dispatch);
			data["approvals"] = empModules
				.filter((item) => {
					if (item.uid !== employeeID) {
						if (
							item.accessibles.includes("console-customization") ||
							item.accessibles.includes("timesheets-manager")
						) {
							return true;
						}
						return false;
					}
					return false;
				})
				.map((item) => item.uid);
			console.log(data);
			return dispatch(placementTimesheetsDataSuccess(data));
		} catch (err) {
			console.error(err);
			return dispatch(placementTimesheetsDataFailure(err));
		}
	};
export const getEmployeePlacements =
	(employeeID) => (dispatch, getstate) => {
		const db = firebase.firestore();
		dispatch(employeePlacementDataReq);
		db.collection("EMPLOYEES").doc(employeeID).collection("PLACEMENTS").onSnapshot((placementsSnap) => {
			const data = {};
			placementsSnap.docs.forEach((doc) => {
				data[doc.id] = doc.data();
			})
			console.log(data, "data9")
			return dispatch(employeePlacementDataSuccess({ placementsList: Object.values(data) }))
		}, (err) => {
			console.error(err);
			return dispatch(employeePlacementDataFailure(err));
		})
	};

export function closePlacement(
	payload,
	uid,
	placementID,
) {
	return (dispatch) => {
		waitingMsg(`Updating Close Placement...`);
		axios
			.put(
				`/employees/${uid}/placements/${placementID}/closeplacement`,
				payload
			)
			.then((response) => {
				console.log(response);
				stopWaitMsg();
				successMsg(response.data.message);
			})
			.catch((err) => {
				console.error(err);
				stopWaitMsg();
				errorMsg(err.data.message);
			});
	};
}

export function reopenPlacement(
	payload,
	uid,
	placementID,
) {
	return (dispatch) => {
		waitingMsg(`Updating Reopen Placement...`);
		axios
			.put(
				`/employees/${uid}/placements/${placementID}/reopenplacement`,
				payload
			)
			.then((response) => {
				console.log(response);
				stopWaitMsg();
				successMsg(response.data.message);
			})
			.catch((err) => {
				console.error(err);
				stopWaitMsg();
				errorMsg(err.data.message);
			});
	};
}

export function getActiveEmployees() {
	return firebase.firestore().collection("EMPLOYEES")
		.where('status', '==', 'active')
		.where('isExist', '==', true)
		.get()
		.then(snap => snap.docs.map((doc) => doc.data()))
		.catch(err => {
			console.error(err);
			errorMsg('Failed to load employees')
		})
}
export function getEndClientContacts(clientID) {
	console.log(clientID, "clientdetails_data")
	if (clientID !== "") {
		return firebase.firestore().collectionGroup("CLIENTS_CONTACTS")
			.where('clientId', '==', clientID)
			.get()
			.then(snap => snap.docs.map((doc) => doc.data()))
			.catch(err => {
				console.error(err);
				errorMsg('Failed to load employees')
			})

	}
	return []
}

export const getAllClientContacts = async (clientList) => {
	console.log(clientList, "clientdetails_data")
	const promises = []
	let contacts = []
	clientList.map((id) => {
		const promise = firebase.firestore().collectionGroup("CLIENTS_CONTACTS")
			.where('clientId', '==', id)
			.get()
		promises.push(promise)
	});
	const finalPromise = Promise.all(promises)
	return finalPromise
		.then((item) => {
			item.forEach((snap) => {
				contacts.push(...snap.docs.map((doc) => doc.data()))
			})
			return contacts
		})
		.catch((err) => err)

}

export const getPlacementsAudit = async (isPageZero = false,isNextPage = false,isPrevPage = false,page,pageSize = 10,firstDocID,lastDocID) => {
  try{
    let payload = {isPageZero,isNextPage,isPrevPage,pageSize,firstDocID,lastDocID}
    let data = await make_API_call("post","/employees/placement-audit",payload)
    return{
      data: data.timesheetHistory,
      firstDocID: data.firstDocument,
      lastDocID: data.lastDocument,
      page: page,
      rowsPerPage: pageSize
    }
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Placements - Placement Audit',
      functionName: 'getPlacementsAudit'
    }
    make_API_call('post', '/errors/report', errorInfo)
    return{
      data: [],
      firstDocID: firstDocID,
      lastDocID: lastDocID,
      page: page,
      rowsPerPage: pageSize
    }
  }
}