import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from "material-table"
import EditVoidInvoice from '../ListingInvoices/Actions/EditVoidInvoice'
import EditFollowup from '../ListingInvoices/Actions/EditFollowup'
import { Link } from "react-router-dom"
import MetaInfo from '../../../../shared/getMetaInfo'
import { useBulkPaymentsContext } from './context'
import { Button, IconButton, TextField } from '@material-ui/core'
import {
  CurrencyFormatter as CustomCurrencyFormat,
} from "../../../../shared/customNumberFormats"
import AddDiscount from './AddDiscount'
import Accounts from '../../../../utils/Accounts'

function InvoicesPaymentDivison() {
  const { state, stateSetters, services } = useBulkPaymentsContext();
  const [openedDialog, openDialog] = React.useState(null);
  const [theInvoicesList, setTheInvoicesList] = React.useState([])

  useEffect(() => {
    setTheInvoicesList(JSON.parse(JSON.stringify(state.invoicesList)))
  }, [JSON.stringify(state.invoicesList)])



  const closeDialog = () => openDialog(null)

  const columns = [
    {
      title: "Invoice no. ",
      field: "id",
      dataType: "String",
      render: (row) => {
        if (row.isVoid) return <span className='d-flex' >
          <EditVoidInvoice invoiceID={row.id} voidReason={row.voidReason} />
          &nbsp;
          <span className="custom-chip chip-danger"><span style={{ fontSize: '12px' }} >{`${row.id}`}</span></span>
        </span>
        else if (row.isPaymentDone)
          return <span className="custom-chip chip-success">{`${row.id}`}</span>
        else if (row.isMailedToClient)
          return <span className='d-flex' >
            {row.followupComment && <EditFollowup invoiceID={row.id} followupComment={row.followupComment} />}
            &nbsp;
            <span className="custom-chip chip-warning">{`${row.id}`}</span>
          </span>
        return <span className="custom-chip  c-bg-sky" >{`${row.id}`}</span>
      },
    },
    {
      title: "PC Number",
      field: "placementID",
      dataType: "String",
      render: (rowData) => {
        if (rowData.placementID !== "--")
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID}>
              {rowData.placementID}
            </Link>
          )
        return "--"
      },
    },
    {
      title: "Employee",
      field: "employeeName",
      dataType: "String",
      render: (row) => {
        if (row.employeeID !== "--")
          return <Link to={"/console/employees/" + row.employeeID}>{row.employeeName}</Link>
        return "--"
      },
    },
    {
      title: "Invoiced Date",
      field: "invoiceDate",
      dataType: "Date",
    },
    {
      title: "Due Date",
      field: "invoiceDueDate",
      dataType: "Date",
    },
    {
      title: "Inv Amount",
      field: "invoicedAmount",
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Received Amount",
      field: "receivedAmount",
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Open Balance",
      field: "openBalance",
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Enter Payment",
      field: "",
      render: (row) => {
        return (
          <div className='d-flex' >
            <div>
              <TextField
                fullWidth
                name="amount"
                type={'text'}
                value={state.payDivisions[row.invoiceID]}
                onChange={(e) => {
                  const payAmount = e.target.value
                  const immutableInv = state.invoicesList.filter((inv) => inv.id === row.invoiceID)?.[0]
                  const immutableOpenBal = immutableInv.grandTotal - (immutableInv?.receivedAmount || 0) - (immutableInv?.paymentDiscountAmount || 0)
                  if (!isNaN(Number(payAmount))) {
                    const existingPayDivisions = JSON.parse(JSON.stringify(state.payDivisions))
                    existingPayDivisions[row.invoiceID] = payAmount
                    stateSetters.setPayDivisions(existingPayDivisions)
                  }
                }}
                size="small"
              // InputProps={{
              //   inputComponent: CustomCurrencyFormat,
              // }}
              />
            </div>

          </div>
        )
      }
    },
    {
      title: "Total Discount",
      field: "discountAmount",
      render: (row) => {
        return (
          <div className='d-flex' style={{ textAlign: 'right' }} >
            <div style={{ marginTop: '15px' }} >
              {row.discountAmount}
            </div>
            <div>
              <AddDiscount
                openedDialog={openedDialog}
                invoiceID={row.invoiceID}
                closeDialog={closeDialog}
                openDialog={openDialog}
                invoicedAmount={row.invoicedAmount}
              />
            </div>
          </div>
        )
      }
    }
  ]

  const rows = theInvoicesList?.map((invoice) => {
    return {
      id: invoice.id,
      invoiceID: invoice.id,
      createdAt: invoice.createdAt,
      createdBy: invoice.createdBy,
      invoiceDate: invoice.invoiceDate,
      invoiceDueDate: invoice.dueDate,
      invoiceDueDateTimestamp: invoice.dueDate,
      clientID: invoice.clientID ? invoice.clientID : "",
      clientName: invoice.clientID
        ? new MetaInfo().clientIdToName(invoice.clientID)
        : "--",
      companyDetails: invoice.companyDetails,
      invoiceBy: invoice.invoiceBy,
      placementID: invoice.invoiceBy !== "external" ? invoice.placementID : "--",
      employeeID: invoice.invoiceBy !== "external" ? invoice.employeeID : "--",
      employeeName:
        invoice.invoiceBy !== "external"
          ? new MetaInfo().emailToName(invoice.employeeID)
          : "--",
      isMailedToClient: invoice.isMailedToClient,
      isVoid: invoice.isVoid,
      isPaymentDone: invoice.isPaymentDone,
      selectedTimesheets: invoice?.selectedTimesheets || {},
      selectedExpenses: invoice.selectedExpenses || [],
      discount: invoice.discount,
      grandTotal: invoice?.grandTotal || 0,
      invoicedAmount: invoice?.grandTotal || 0,
      receivedAmount: invoice?.receivedAmount || 0,
      latestPaymentDate: invoice?.latestPaymentDate || "",
      isClientApproved: invoice.isClientApproved,
      isClientRejected: invoice.isClientRejected,
      openBalance:
        invoice.grandTotal - (invoice?.receivedAmount || 0) - (invoice?.paymentDiscountAmount || 0) - (state.payDivisions?.[invoice.id] || 0) + services.calculateDiscount(invoice.grandTotal, state.discountDivisions?.[invoice.id] || []),
      notifiers: invoice.notifiers,
      discountAmount: (invoice?.paymentDiscountAmount || 0) - services.calculateDiscount(invoice.grandTotal, state.discountDivisions?.[invoice.id] || []),
      voidReason: invoice?.voidReason || '',
      followupComment: invoice?.followupComment || ''
    };
  })

  // set open balance
  useEffect(() => {
    const amount = rows.reduce((init, item) => init + item.openBalance, 0)
    const finalAmount = Accounts.roundOffAmount(amount)
    stateSetters.setOpenBalance(Number(finalAmount))
  }, [JSON.stringify(rows.map(r => r.openBalance))])

  return (
    <div>
      <MaterialTable
        title=""
        data={rows}
        columns={columns}
        isLoading={state.showLoader}
        // onSelectionChange={(rows) => setSelectedRows(rows)}
        options={{
          pageSize: 10,
          filtering: true,
          columnsButton: true,
          paginationPosition: "top",
          paginationType: "normal",
          selection: true,
        }}
      />
    </div>
  )
}

export default InvoicesPaymentDivison
