import React from "react";
import CustomPopover from "../../../../../shared/components/Popover";
import validation from "../../../../../shared/validation"
import Progress from "../../ProfilePercentage/index";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import SuspendEmployee from "../../SuspendEmployee";
import EditIcon from "@material-ui/icons/Edit";
import {IconButton} from '@material-ui/core';
import Validations from "../../../../../shared/validation";
import firebase from "firebase";
import MetaInfo from "../../../../../shared/getMetaInfo";
import parse from 'html-react-parser'
import AvatarCrop from "../../../../../shared/cropper"
import Tooltip from "@material-ui/core/Tooltip"
import { FaPen } from "react-icons/fa"
import { useDialog } from "../../../../../hooks/useDialog";

const PopoverContent = ({onUpdate, reasons=[],employeeID}) => {
const loggedInId = firebase.auth().currentUser?.uid
const metaInfo = new MetaInfo();
  if(reasons)
  return(
        <div>      
          <Timeline align='left'>
          {
             reasons?.map((res, idx) => {
              return (
                <TimelineItem>
                <TimelineOppositeContent>
                <div> {Validations.dateAndTimeFormatter(res?.sysTime)}</div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={{color:'#2C9273'}}/>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {
                    res.title === 'Exit By' ?
                    <SuspendEmployee
                isEdit={true}
                index={idx}
                onUpdate={onUpdate}
                suspensionReason={res?.reason}
                relievingDate={res?.updatedAt}
                employeeID={employeeID}
                ClickableContent={({ openDialog }) => {
                  return (
                    <div>
                    <div style={{width:240,wordWrap:'break-word',fontWeight:'bold'}}>{res.title}</div>
                    <div style={{width:240,wordWrap:'break-word',color:'#43d39e',fontSize:14,fontWeight:'bold'}}>{metaInfo.emailToName(res?.updatedBy) + `${res?.department && `(${res?.department})`} `}
                    {
                      loggedInId === res?.updatedBy ? 
                      <IconButton onClick={openDialog} size='small'><EditIcon /> </IconButton>               
                      : <></>
                    }
                    </div>
                    </div>
                  )
                }}
              />   :
             <div>
               <div style={{width:240,wordWrap:'break-word',fontWeight:'bold'}}>{res.title}</div>
                    <div style={{width:240,wordWrap:'break-word',color:'#43d39e',fontSize:14,fontWeight:'bold'}}>{metaInfo?.emailToName(res?.updatedBy) + `${res?.department && `(${res?.department})`}`} 
                    </div>
             </div>
                  }                    
                  <div style={{width:240,wordWrap:'break-word',fontWeight:'bold'}}>{res?.title === 'Exit By' ? 'Exit Date' : 'Rejoined Date'}</div>
                  <div style={{width:240,wordWrap:'break-word'}}>{res?.title === 'Exit By' ? res?.updatedAt : res?.enabledDate}</div>
                  <div style={{width:240,wordWrap:'break-word',fontWeight:'bold'}}>{"Reason : "}</div>
                  <div style={{width:240,wordWrap:'break-word',fontStyle:'italic'}}>{parse(res?.reason)}</div>
                </TimelineContent>
              </TimelineItem>
              )})}
               </Timeline>
          
        </div>
  )
  return(
    <div>loading Timeline</div>
  )


}



const Usertop = (props) => {
  const {handleChange,relievingDate,suspendedReasons,employeeID,onUpdate,employeeProfileData,imageURL } = props;
  const profile = employeeProfileData;
  const { open, handleOpen, handleClose } = useDialog();
  return (
    <div className="prousg mb-3">
      <div className="d-flex mb-3 me-3">
        <div className="imguser">
          <img
            src={imageURL}
            className="avatar-md rounded-circle"
            alt=""
          />         
        </div>
        <div style={{ marginRight: 20, marginTop: 4 }}>
        <Tooltip title="Upload New Image" aria-label="excel_tooltip">
        <IconButton style={{marginBottom:5}} size="small" onClick={handleOpen} aria-label="new">
          <FaPen size={12} className="text-success"/>
        </IconButton>
        </Tooltip>
            <AvatarCrop
              handleChange={handleChange}
              useremail={profile?.email}
              imageURL={imageURL}
              open={open}
				      handleClose={handleClose}
            />
          </div>
        <div className="ms-2">
          <div className="d-flex">
            <h4 className="mb-0 mt-0 align-self-center">
              {[
                profile?.personal?.firstname,
                profile?.personal?.middlename,
                profile?.personal?.lastname,
              ]
                .filter((i) => i)
                .join(" ")}
            </h4>{" "}
            {profile?.personal?.supervisor ? (
              <span className="ms-2 badge-soft-danger badge rounded-pill fs-12 px-2 py-15 fw-medium">
                Supervisor
              </span>
            ) : (
              <></>
            )}
          </div>
          <h6 className="text-muted fw-normal mt-1 mb-0">
            {profile?.personal?.jobtitle}
          </h6>
        </div>
      </div>
      <div>
        <div className="prousg-right">
          <div className="d-flex me-5 mb-2">
            <i className="fa-solid fa-id-card text-danger pe-2 icondu"></i>{" "}
            <span>{profile?.uid}</span>
          </div>
          <div className="d-flex me-5 mb-2">
            {profile?.personal?.gender === "Male" ? (
              <i className="fa-solid fa-male text-info pe-2 icondu"></i>
            ) : (
              <i className="fa-solid fa-female text-danger pe-2 icondu"></i>
            )}

            <span>{validation.dateFormatter(profile?.personal?.dob)}</span>
          </div>
          <div className="d-flex me-5 mb-2">
            {profile?.personal?.category === "W2" ||
            profile?.personal?.category === "C2C" ? (
              <div className="boxde4 me-2">{profile?.personal?.category}</div>
            ) : (
              <div className="boxde4 me-2">X</div>
            )}
            <div className="d-flex">
              <span>
                {validation.dateFormatter(profile?.personal?.dateofjoining)}{" "}
                <span>{relievingDate ? "- " + relievingDate : ""}</span>{" "}
              </span>
              <span style={{ marginLeft: 4, marginBottom: 1 }}>
                {
                  suspendedReasons?.length > 0 ?
                  <CustomPopover
                    RenderContent={() => (
                      <PopoverContent
                        reasons={suspendedReasons}
                        employeeID={employeeID}
                        onUpdate={onUpdate}
                      />
                    )}
                    isInfo={true}
                  />
                  : <></>
                }
                  
              </span>
            </div>
          </div>

          <span>
            {profile?.status === "suspended" ? (
              <div className="d-flex text-danger">
                <i className="fa-solid fa-user-shield pe-2 icondu"></i>{" "}
                <span>{profile?.status}</span>
              </div>
            ) : profile?.status === "active" ? (
              <div className="d-flex text-success">
                <i className="fa-solid fa-user-shield pe-2 icondu"></i>{" "}
                <span>{profile?.status}</span>
              </div>
            ) : (
              <div className="d-flex text-warning">
                <i className="fa-solid fa-user-shield pe-2 icondu"></i>{" "}
                <span>{profile?.status}</span>
              </div>
            )}
          </span>
        </div>
        {/* <AssignRole id={employeeID} profile={profile} /> */}
        <div className="procom">
          <div className="mb-1">Profile Filled</div>
          <Progress profile={profile} />
        </div>
        {/* <div className="procom">
          <div className="mb-1">Profile Filled</div>
          <Progress profile={employeeProfile.data} />
        </div> */}
      </div>
    </div>
  );
};
export default Usertop;
