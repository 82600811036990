import React from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import CircularSpinner from "../../../../../../shared/circularSpinner"
import Presentation from "./Presentation"

function Container(props) {
  const { accounts, clients_locations } = props

  if (isLoaded(accounts) && isLoaded(clients_locations))
    return (
      <div>
        <Presentation {...props} />
      </div>
    )

  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    accounts: state.firestore.ordered.accounts,
    placements_list: state.firestore.ordered.placements_list,
    names: state.employee.employeeList.metaInfoofEmployees.data,
    clients_locations:state.firestore.ordered.clients_locations,
    placements_settings_list: state.firestore.ordered.placements_settings_list,

  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "CLIENTS",
        doc: props.client.id,
        subcollections: [
          {
            collection: "CLIENTS_ACCOUNTS",
            where: [["isExist", "==", true]],
          },
        ],
        storeAs: "accounts",
      },
      {
        collection: "CLIENTS",
        doc: props.client.id,
        subcollections: [
          {
            collection: "CLIENTS_LOCATIONS",
            where: [["isExist", "==", true]],
          },
        ],
        storeAs: "clients_locations",
      },
    ]
  })
)(Container)
