import React, { useEffect, useState, useContext } from "react";
import Presentation from "./Presentation";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { Context } from "../component_state/context";
import {
  nextPage,
  prevPage,
  timeSheetsList
} from "../../../middleware/timesheetsList";

function Container(props) {
  const {
    timesheets,
    loggedInEmployee,
    settings_info,
    condition,
    modules,
    _timesheet_list,
    timesheets_data,
    count,
    branchList,
    employees
  } = props;
  const [state, handle] = useContext(Context);

  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    });
  };
  useEffect(() => {
    setState({
      isFetchingTimesheets: true,
      condition: condition
    });
    const employeeID = state.listAll ? loggedInEmployee : state.employeeID;
    const isManager = state.listAll
      ? modules.includes("timesheets-manager") ||
      props.modules.includes("console-customization")
      : false;
    // handle({
    //   type: "LOAD_TIMESHEETS",
    //   condition: condition,
    //   employeeID,
    //   isManager,
    //   setState
    // });
    handleCurrentPage(10);
  }, [condition, state.loadComponent, state.listAll, state.employeeID]);

  useEffect(() => {
    setState({
      isSettingsLoading: true
    });
    if (!timesheets_data.isLoading) {
      const placements = state[condition].map((item) => item.placementID);
      handle({
        type: "LOAD_SETTINGS",
        placementIDs: placements,
        setState
      });
    }
  }, [timesheets_data.isLoading]);

  const handleCurrentPage = (pageSize) => {
    const employeeID = state.listAll ? loggedInEmployee : state.employeeID;
    const isManager = state.listAll
      ? modules.includes("timesheets-manager") ||
      props.modules.includes("console-customization")
      : false;
    if (employeeID) _timesheet_list(condition, employeeID, isManager, pageSize);
  };

  const handleNextPage = (pageSize) => {
    const employeeID = state.listAll ? loggedInEmployee : state.employeeID;
    const isManager = state.listAll
      ? modules.includes("timesheets-manager") ||
      props.modules.includes("console-customization")
      : false;
    props._next_page(condition, employeeID, isManager, pageSize);
  };

  const handlePrevPage = (pageSize) => {
    const employeeID = state.listAll ? loggedInEmployee : state.employeeID;
    const isManager = state.listAll
      ? modules.includes("timesheets-manager") ||
      props.modules.includes("console-customization")
      : false;
    props._previous_page(condition, employeeID, isManager, pageSize);
  };

  console.log(state);
  return (
    <div>
      <Presentation
        state={state}
        isLoaded={isLoaded(timesheets)}
        timesheets={timesheets}
        settings_info={settings_info}
        loggedInEmployee={loggedInEmployee}
        condition={condition}
        timesheets_data={timesheets_data}
        nextPage={handleNextPage}
        previousPage={handlePrevPage}
        currentPage={handleCurrentPage}
        count={count}
        modules={modules}
        branchList={branchList}
        employees={employees}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    modules: state.employee.employeeModules.accessModules,
    loggedInEmployee: state.firebase.auth.uid,
    timesheets: state.firestore.ordered.timesheets,
    settings_info: state.timesheets.info.settings_info,
    timesheets_data: state.timesheets.list,
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [],
    employees: state.employee.employeeList.metaInfoofEmployees.data,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _timesheet_list: (condition, employeeID, isManager, pageSize) => {
      dispatch(timeSheetsList(condition, employeeID, isManager, pageSize));
    },
    _next_page: (condition, employeeID, isManager, pageSize) => {
      dispatch(nextPage(condition, employeeID, isManager, pageSize));
    },
    _previous_page: (condition, employeeID, isManager, pageSize) => {
      dispatch(prevPage(condition, employeeID, isManager, pageSize));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
