import React from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { compose } from "redux"
import CircularSpinner from "../../../../../../../shared/circularSpinner"

function Container(props) {
  const { accounts } = props
  if (isLoaded(accounts)) {
    return (
      <div>
        <Presentation {...props} />
      </div>
    )
  }

  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    accounts: state.firestore.ordered.accounts,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "CLIENTS",
        doc: props.client.id,
        subcollections: [
          {
            collection: "CLIENTS_ACCOUNTS",
            where: [["isExist", "==", true]],
          },
        ],
        storeAs: "accounts",
      },
    ]
  })
)(Container)
