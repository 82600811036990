import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { deletePlacement,getActiveEmployees } from "../../../middleware"
import CircularSpinner from "../../../../../shared/circularSpinner"
import Presentation from "./Presentation"

function Container(props) {
  const { placements_list, projects_meta_info,placements_settings_list,clients,payment_settings_list,worklocation_settings_list,clients_contacts_list,recruitment_details_settings_list } = props
  const [state, setState] = useState({
    placementsList: [],
    employeeList:[]
  })

  const fetchActiveEmployees = async () => {
    try {
      const data = await getActiveEmployees()
      setState((prevState) => {
        return {
          ...prevState,
          employeeList: data,
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  const getEndClientNames = (placementId) => {
    const filteredPlacements = placements_list.filter((p) => p.id === placementId);
  
    const endClientIds = filteredPlacements.flatMap((p) => {
      const clientDetails = placements_settings_list.find((c) => c.placementID === p.id);
      if (!clientDetails) return [];
      const clients = Object.values(clientDetails.clients || {});
      return clients
        .filter((client) => client.clientType === "End Client")
        .map((client) => client.clientId);
    });
  
    const endClientNames = endClientIds.map((clientId) => {
      const endClient = clients.find((client) => client.clientId === clientId);
      return endClient ? endClient.businessName : "";
    });
  
    return endClientNames;
  };
  

  useEffect(() => {
    fetchActiveEmployees()
  }, [])

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        placementsList: placements_list,
      }
    })
  }, [placements_list])


  if (isLoaded(placements_list) && isLoaded(projects_meta_info) && isLoaded(placements_settings_list) && isLoaded(clients) && isLoaded(payment_settings_list) && isLoaded(worklocation_settings_list) && isLoaded(clients_contacts_list) && isLoaded(recruitment_details_settings_list)) {
    return (
      <div>
        <Presentation {...props} placementsList={state.placementsList} employeeList={state.employeeList} fetchActiveEmployees={fetchActiveEmployees}  getEndClientNames={getEndClientNames}/>
      </div>
    )
  }
  return <CircularSpinner />
}

const mapStateToProps = (state, ownProps) => {
  const { ordered, data } = state.firestore
  return {
    placements_list: ordered.placements_list,
    projects_meta_info: data.projects_meta_info,
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [],
    clients: ordered.clients_list,
    placements_settings_list: ordered.placements_settings_list,
    payment_settings_list:ordered.payment_settings_list,
    worklocation_settings_list:ordered.worklocation_settings_list,
    clients_contacts_list:ordered.clients_contacts_list,
    recruitment_details_settings_list:ordered.recruitment_details_settings_list
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deletePlacement: (placementID, uid) => {
      dispatch(deletePlacement(placementID, uid))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collectionGroup: "PLACEMENTS",
        orderBy: [["createdAt", "asc"]],
        where: [["isExist", "==", true]],
        storeAs: "placements_list",
      },
      {
        collection: "META_INFO",
        doc: "projects",
        storeAs: "projects_meta_info",
      },
      {
        collection: "CLIENTS",
        where: [["isExist", "==", true]],
        storeAs: "clients_list",
      },
      {
        collectionGroup: "SETTINGS",
        where: [["id", "==", "client_details"]],
        storeAs: "placements_settings_list",
      },
      {
        collectionGroup:"SETTINGS",
        where:[["id","==","payments"]],
        storeAs:"payment_settings_list"
      },
      {
        collectionGroup:"SETTINGS",
        where:[["id","==","worklocation"]],
        storeAs:"worklocation_settings_list"
      },
      {
        collectionGroup:"SETTINGS",
        where:[["id","==","recruitment_details"]],
        storeAs:"recruitment_details_settings_list"
      },
      {
        collectionGroup:"CLIENTS_CONTACTS",
        storeAs:"clients_contacts_list"
      }
    ]
  })
)(Container)
