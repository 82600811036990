import React, { useEffect, useState } from "react";
import { useInvoiceListContext } from "../context";
import InvoiceListTable from "../InvoiceListTable";
import { useQuery } from "@tanstack/react-query";
import { getInvoices } from "../../../../../api/services/default/invoices";

export default function VoidInvoices({
  isEmployeeInvoices,
  employeeID,
  isClientInvoices,
  clientID,
}) {
  const { state } = useInvoiceListContext();

  const { data, isLoading } = useQuery({
    queryKey: ["getInvoices", "void", { clientID, employeeID }],
    queryFn: () => getInvoices("void", clientID, employeeID),
  })
 
  return (
    <InvoiceListTable
      condition={state.tabValue}
      isEmployeeInvoices={false}
      employeeID={employeeID}
      clientID=""
      isClientInvoices={false}
      invoicesList={data}
      isLoading={isLoading}
    />
  );
}
