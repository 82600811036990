import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {string} employeeId
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getEmployee = (employeeId, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/employee/${employeeId}/emp_details/dashboard`,
    signal,
  });
};

/**
 * @param {string} employeeId
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateEmployee = (employeeId, data, signal) => {
  return defaultInstance({
    method: 'PUT',
    url: `/employee/${employeeId}/updateprofile`,
    data,
    signal,
  });
};

/**
 * @param {string} schoolId
 * @returns {Promise<Array<Record>>}
 */
export const getEmployeesBySchoolId = (schoolId) => {
  return defaultInstance({
    method: 'GET',
    url: `/employee/getBySchoolId?schoolId=${schoolId}`,
  });
};



