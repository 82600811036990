import React from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateProfile } from '../../../../middleware/dynamicProfileRender';
import DeleteModal from '../DeleteModal/delete';
import EmargencyContactEditModal from './emergency';



const EmergencyContact = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowDel, delModalShow] = React.useState(false);
    const {data,employeeID ,isPersonal} = props
    const [index, setIndex] = React.useState(undefined)

    const handleEdit = (idx) => {
        setModalShow(true)
        setIndex(idx)
    }

    const handleAdd = () => {
        setModalShow(true)
        setIndex(undefined)
    }

    const dispatch = useDispatch()

    const handleDelete = (idx) => {
        delModalShow(true);
        setIndex(idx)
      };
  
    const handleDeleteData = (index) => {
        if (index > -1) { 
            data.splice(index, 1); 
          }
        delModalShow(false)
        let emergencycontact = data?.map((item) => item)
        const payload = {
            emergencycontact
        }
        setModalShow(false)
        dispatch(updateProfile(payload,employeeID,payload))
    }
    return (
        <>
            <EmargencyContactEditModal
                show={modalShow}
                employeeID={employeeID}
                data={data}
                idx={index}
                onHide={() => setModalShow(false)}
            />
            <DeleteModal
                 show={modalShowDel}
                 index={index}
                 handleDelete={() => handleDeleteData(index)}
                 onHide={() => delModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Emergency Contacts</h5>
                        {
                            isPersonal ?
                            <></>
                            :
                            <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleAdd()}>
                            <i className="fa-solid fa-square-plus fs-17"></i>
                            </Link>
                        }
                      
                    </div>
                    <Row>
                        {
                            data?.map((res, idx) => {
                                return (
                            <Col xl={6}>
                            <div className="emerbg mb-3 mb-xl-0 mt-2">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="text-muted mb-2">Name : <span className="fs-14 fw-medium text-base">{res?.name}</span></div>
                                        <div className="text-muted mb-2">Phone : <span className="fs-14 fw-medium text-base">{res?.phone}</span></div>
                                        <div className="text-muted mb-2">Email : <span className="fs-14 fw-medium text-base">{res?.emailid}</span></div>
                                        <div className="text-muted mb-2">Relation : <span className="fs-14 fw-medium text-base">{res?.relation}</span></div>
                                    </div>
                                    {
                                        isPersonal ?
                                        <></>
                                        :
                                        <div className="d-flex">
                                        <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleEdit(idx)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                        <Link to="#" className="linkbtn danger mt-0" onClick={() => handleDelete(idx)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                                    </div>
                                    }
                                </div>
                            </div>
                        </Col>
                                )
                            })
                        }
                        
                        {/* <Col xl={6}>
                            <div className="emerbg">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="text-muted mb-2">Name : <span className="fs-14 fw-medium text-base">Akhil Kumar</span></div>
                                        <div className="text-muted mb-2">Phone : <span className="fs-14 fw-medium text-base">+91-9505654466</span></div>
                                        <div className="text-muted mb-2">Email : <span className="fs-14 fw-medium text-base">akhil98k@gmail.com</span></div>
                                        <div className="text-muted mb-2">Relation : <span className="fs-14 fw-medium text-base">Brother</span></div>
                                        <div className="text-muted">Status : <span className="fs-14 fw-medium text-base">Single</span></div>
                                    </div>
                                    <div className="d-flex">
                                        <Link to="#" className="linkbtn me-1 mt-0" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                        <Link to="#" className="linkbtn danger mt-0" onClick={() => delModalShow(true)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
export default EmergencyContact;