import React, { useEffect } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { getAllModules } from "../../../../EmployeeManagment/middleware"
import { compose } from "redux"
import { firestoreConnect, isLoaded as isLoad } from "react-redux-firebase"
import { getCountries } from "../../../../../shared/middleware"
import { withRouter } from "react-router-dom"
import CircularSpinner from "../../../../../shared/circularSpinner"

function Container(props) {
  const {
    getAllModules,
    allModules,
    isLoaded,
    profile,
    isEdit,
    placement,
    id,
    clients_meta_info,
    projects_meta_info,
    isLoaded_countries,
    getCountries,
    isLoaded_allModules,
    loggedInEmployee,
    clientDetails_data
  } = props

  useEffect(() => {
    if (id === loggedInEmployee) props.history.push(`/console/employees/${id}`)
  }, [id, loggedInEmployee])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllModules()
    getCountries()
  }, [])

  if (
    isLoaded &&
    isLoad(placement) &&
    isLoad(clients_meta_info) &&
    isLoad(projects_meta_info) &&
    isLoaded_countries &&
    isLoaded_allModules
  ) {
    return (
      <div>
        <Presentation
          allModules={allModules}
          profile={profile}
          isEdit={isEdit}
          placement={placement[0]}
          id={id}
          clientDetails_data={clientDetails_data}
        />
      </div>
    )
  }
  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { allModules, isLoaded, isEmpty } = state.employee.employeeModules
  const shared = state.shared.geoInfo.countries
  const { ordered, data } = state.firestore
  return {
    allModules,
    isLoaded,
    isEmpty,
    profile: data.names[ownProps.id],
    placement:
      Array.isArray(ordered.placement) && ordered.placement.length
        ? ordered.placement
        : new_placement,
    projects_meta_info: data.projects_meta_info,
    clients_meta_info: data.clients_meta_info,
    isLoaded_countries: shared.isLoaded,
    isLoaded_allModules: state.employee.employeeModules.isLoaded,
    loggedInEmployee: state.firebase.auth.uid,
    clientDetails_data: data.clientDetails_data,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAllModules: () => {
      dispatch(getAllModules())
    },
    getCountries: () => {
      dispatch(getCountries())
    },
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const totalCollections = [
      {
        collection: "CLIENTS",
        where: [
          ["isExist", "==", true],
        ],
        storeAs: "clients_meta_info",
      },
      {
        collection: "META_INFO",
        doc: "projects",
        storeAs: "projects_meta_info",
      },
    ]
    if (!props.docId)
      return [
        ...totalCollections,
        {
          collectionGroup: "PLACEMENTS",
          where: [
            ["employeeID", "==", props.id],
            ["draft", "==", true],
            ["isExist", "==", true],
          ],
          storeAs: "placement",
        },
      ]
    if (props.docId) {
      return [
        ...totalCollections,
        {
          collectionGroup: "PLACEMENTS",
          where: [["id", "==", props.docId]],
          storeAs: "placement",
        },
      ]
    }
    return []
  })
)(Container)

let new_placement = [
  {
    billableClient: "",
    clientId: "",
    createdAt: "",
    createdBy: "",
    description: "",
    draft: false,
    employeeID: "",
    endDate: "",
    fillableSections: [
      "payments",
      "documents",
      "worklocation",
      "timesheets",
      "timesheets",
      "client_details",
      "invoices",
      "recruitment_details",
      "expense_details",
    ],
    id: "",
    isExist: false,
    placementID: "",
    projectEndDate: "",
    startDate: "",
    status: "",
    updatedAt: [],
    updatedBy: [],
    netTerms: "",
    jobTitle: "",
  },
]
