import React from "react";
import EmployeeListPage from "./pages/EmployeeListPage";
import InviteEmployeePage from "./pages/InviteEmployeePage";
import ProjectListPage from "./pages/ProjectsListPage";
import ProjectHandlePage from "./pages/ProjectHandlerPage";
import TaskHandlerPage from "./pages/TaskHandlerPage";
import WikiHomePage from "./pages/WikiHomePage";
import PlacementViewPage from "./pages/PlacementViewPage";
import PlacementListPage from "./pages/PlacementListPage";
import PlacementEditPage from "./pages/PlacementEditPage";
import ClientListPage from "./pages/ClientListPage";
import NewClientPage from "./pages/NewClientPage";
import ViewClientPage from "./pages/ViewClientPage";
import InvoicesListPage from "./pages/InvoicesListPage";
import CreateInvoicePage from "./pages/CreateInvoicePage";
import TimesheetsListPage from "./pages/TimesheetsListPage";
import TimesheetSubmitPage from "./pages/TimesheetSubmitPage";
import CreateExpensesPage from "./pages/CreateExpensesPage";
import ExpenseListPage from "./pages/ExpenseListPage";
import CompanyDetailsPage from "./pages/CompanyDetailsPage";
import DeductionListPage from "./pages/DeductionListPage";
import ConsolePage from "./pages/ConsolePage";
import LabelsPage from "./pages/LabelsPage";
import MoreArticlesPage from "./pages/MoreArticlesPage";
import ViewArticlePage from "./pages/ViewArticlePage";
import EditArticlePage from "./pages/EditArticlePage";
import ArticleHistoryPage from "./pages/ArticleHistoryPage";
import CreateArticlePage from "./pages/CreateArticlePage";
import WikiSettingsPage from "./pages/WikiSettingsPage";
import RevertChangesPage from "./pages/RevertChangesPage";
import EmployeeProfilePage from "./pages/EmployeeProfilePage";
import PayrollsListPage from "./pages/PayrollsListPage";
import Alert from "@material-ui/lab/Alert";
import ModuleAccessPage from "./pages/ModuleAccessPage";
import LetterRequestPage from "./pages/LetterRequestPage";
import ESSRequestPage from "./pages/ESSRequestPage";
import LetterTemplatesPage from "./pages/LetterTemplatesPage";
import AuthorizedSignaturesPage from "./pages/AuthorizedSignaturesPage";
import MyRequestPage from "./pages/MyRequestsPage";
import ProfileBuilderPage from "./pages/ProfileBuilderPage";
import ProjectTasksPage from "./pages/ProjectTasksPage";
// import homePage from "./pages/homePage";
import EmployeeExpiredDocPage from "./pages/employeeExpiredList";
import StaffListPage from "./pages/StaffListPage"
import AllAnnouncementPage from "./pages/AllAnnouncementPage";
import ViewAnnouncementPage from "./pages/ViewAnnouncementPage";
import EmployeeSkillPage from "./pages/EmployeeSkillPage";
import SchoolListPage from "./pages/SchoolListPage";
import ViewSchoolPage from "./pages/ViewSchoolPage";


function ComingSoon(props) {
  return (
    <div>
      <Alert className="text-center" variant="filled" severity="warning">
        <h3>This Module is coming soon.</h3>
      </Alert>
    </div>
  );
}

const routes = [
  {
    path: "/console/wiki",
    component: WikiHomePage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/:search/create",
    component: CreateArticlePage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/morearticles",
    component: MoreArticlesPage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/settings",
    component: WikiSettingsPage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/:articleId/history",
    component: ArticleHistoryPage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/:articleId",
    component: ViewArticlePage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/:articleId/edit",
    component: EditArticlePage,
    moduleName: "wiki",
  },
  {
    path: "/console/wiki/:articleId/:historyId",
    component: RevertChangesPage,
    moduleName: "wiki",
  },
  {
    path: "/console/expenseslist/:expenseId/:actionType",
    component: CreateExpensesPage,
    moduleName: "timesheets",
  },
  {
    path: "/console/timesheets/:type",
    component: TimesheetSubmitPage,
    moduleName: "timesheets",
  },
  {
    path: "/console/placements",
    component: PlacementListPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/placements/:id/:docId",
    component: PlacementEditPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/clientslist",
    component: ClientListPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/timesheets",
    component: TimesheetsListPage,
    moduleName: "timesheets",
  },
  {
    path: "/console/invoiceslist",
    component: InvoicesListPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/deductionslist",
    component: DeductionListPage,
    moduleName: "accounts-manager",
  },
  {
    path: "/console/newclient",
    component: NewClientPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/clientslist/:clientId",
    component: ViewClientPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/employees/:id/newplacement",
    component: PlacementViewPage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/invoiceslist/:type",
    component: CreateInvoicePage,
    moduleName: "timesheets-manager",
  },
  {
    path: "/console/expenseslist",

    component: ExpenseListPage,
    moduleName: "timesheets",
  },
  {
    path: "/console/immigration",
    component: ComingSoon,
    moduleName: "immigration",
  },
  {
    path: "/console/createtemplate",
    component: ComingSoon,
    moduleName: "console-customization",
  },
  {
    path: "/console/projects",
    component: ProjectListPage,
    moduleName: "task-management",
  },
  {
    path: "/console/projects/:projectId",
    component: ProjectHandlePage,
    moduleName: "task-management",
  },
  {
    path: "/console/projects/:projectId/tasks",
    component: ProjectTasksPage,
    moduleName: "task-management",
  },
  {
    path: "/console/projects/:projectId/labels",
    component: LabelsPage,
    moduleName: "task-management",
  },
  {
    path: "/console/projects/:projectId/:taskId",
    component: TaskHandlerPage,
    moduleName: "task-management",
  },
  {
    path: "/console/projects/:projectId/:taskId/:subtaskId",
    component: ComingSoon,
    moduleName: "task-management",
  },
  {
    path: "/console/myrequests",
    component: MyRequestPage,
    moduleName: "employee-self-services",
  },
  {
    path: "/console/letterrequests",
    component: ESSRequestPage,
    moduleName: "employee-self-services-manager",
  },
  {
    path: "/console/discussions",
    component: ComingSoon,
    moduleName: "discussions",
  },
  {
    path: "/console/employees",
    component: EmployeeListPage,
    moduleName: "employees-manager",
  },
  {
    path: "console/staff",
    component: StaffListPage,
    moduleName: "employees-manager",
  },
  {
    path: "/console/employees/:id",
    component: EmployeeProfilePage,
    moduleName: "employees-manager",
  },
  {
    path: "/console",
    component: ConsolePage,
    moduleName: "console-customization",
  },
  {
    path: "/console/inviteemployee",
    component: InviteEmployeePage,
    moduleName: "console-customization",
  },
  {
    path: "/console/templates/lettertemplates",
    component: LetterTemplatesPage,
    moduleName: "console-customization",
  },
  {
    path: "/console/templates/emailtemplates",
    component: ComingSoon,
    moduleName: "console-customization",
  },
  {
    path: "/console/companydetails",
    component: CompanyDetailsPage,
    moduleName: "console-customization",
  },
  {
    path: "/console/authorizedsignatures",
    component: AuthorizedSignaturesPage,
    moduleName: "console-customization",
  },
  {
    path: "/console/moduleaccess",
    component: ModuleAccessPage,
    moduleName: "console-customization",
  },
  {
    path: "/console/payrolls",
    component: PayrollsListPage,
    moduleName: "accounts-manager",
  },
  {
    path: "/console/requestLetter",
    component: LetterRequestPage,
    moduleName: "employee-self-services",
  },
  {
    path: "/console/profilebuilder",
    component: ProfileBuilderPage,
    moduleName: "console-customization",
  },
  {
    path: "/expiredList/:empId",
    component: EmployeeExpiredDocPage,
    moduleName: "discussions",
  },
  {
    path: "/console/Announcements",
    component: AllAnnouncementPage,
    moduleName: "Announcements",
  },
  {
    path: "/console/Announcements/:announcementId",
    component: ViewAnnouncementPage,
    moduleName: "View Announcement",
  },
  {
    path: "/console/ResourceManagementGroup",
    component: EmployeeSkillPage,
    moduleName: "employees-manager",
  },
  {
    path: "/console/schools",
    component: SchoolListPage,
    moduleName: "employees-manager",
  },
  {
    path: "/console/schools/:id",
    component: ViewSchoolPage,
    moduleName: "employees-manager",
  }
];
export default routes;
