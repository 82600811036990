import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Constants } from "../../../../../../shared/constants";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { saveWorkAuthActive, updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { v4 as uuid4 } from "uuid";
import { differenceInDays } from "date-fns";
import { AlertService } from "../../../../../../shared/alerts";

const schema = yup.object().shape({
	work_type: yup.string().required("Work Type is required"),
	work_number: yup.string().required("Work Number is required"),
	work_issue: yup.date().required("Work Issue Date is required"),
	validfrom: yup
		.date()
		.required("Valid From Date is required"),
		// .test(
		// 	"validfrom",
		// 	"Valid From Date should be greater than or equal Work Issue Date",
		// 	function (value) {
		// 		const { work_issue } = this.parent;
		// 		return new Date(value) >= new Date(work_issue);
		// 	}
		// ),
	work_exp: yup
		.date()
		.required("Work Expiry Date is required")
		.test(
			"work_exp",
			"Work Expiry Date should be greater than Work Valid From Date",
			function (value) {
				const { validfrom } = this.parent;
				return new Date(value) > new Date(validfrom);
			}
		),
	document: yup.string().required(),
	active: yup
		.string()
		.required("Status is required")
		.test(
			"active",
			"Status should be active if the current date is between the Valid From and Work Expiry Date",
			function (value) {
				const { validfrom, work_exp } = this.parent;
				const currentDate = new Date();
				if (value === "Y") {
					return (
						currentDate >= new Date(validfrom) &&
						currentDate <= new Date(work_exp)
					);
				}
				return true;
			}
		)
		.test(
			"H-1B Consular",
			"Status cannot be active if the work type is H1B Consular",
			function (value) {
				const { work_type } = this.parent;
				return value === "N" || work_type !== "H-1B Consular";
			}
		),
		minWage: yup
		.number()
		.when("work_type", {
		  is: "H-1B",
		  then: yup.number().required("Min Wage is required"),
		  otherwise: yup.number(),
		}),
		maxWage: yup
		.number()
		.required("Max Wage is required"),
});

const _alertService = new AlertService();

export default function WorkAuthorizationEditModal(props) {
	const {
		data,
		employeeID,
		idx,
		onHide,
		isFromWorkQueue = false,
		onUpdate = () => {},
	} = props;
	
	const dispatch = useDispatch();

	const optionMapper = (i) => ({ value: i, label: i });

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		const clearState = () => {
			actions.setSubmitting(false);
			onHide();
		};

		const isUpdate = !isNaN(idx);

		const payload = {};

		// check the data if there are any docs 
		// that are already active and if the expiry date is less than the current date
		const activeDocs =
			data?.filter(
				(item) =>
					item.active === "Y" &&
					differenceInDays(new Date(), new Date(item.work_exp))
			) ?? [];

		if (activeDocs.length > 0 && values.active === "Y") {
			const activeDoc = activeDocs[0];
			payload.inactiveWorkAuthData = null;     //clearing the field to null

			if ((isUpdate && data[idx].active !== "Y" && activeDoc.id !== data[idx].id) || !isUpdate) {
				// show confirm message that there is already an active doc
				const result = await _alertService.confirmMessage(
					"Are you sure you want to continue?",
					`<div>
						<p>There is already an active work authorization with the following details:</p>
						<div>
							<ul style="list-style: none;">
								<li>Work Type: ${activeDoc.work_type}</li>
								<li>Work Number: ${activeDoc.work_number}</li>
								<li>Work Issue Date: ${activeDoc.work_issue}</li>
								<li>Valid From Date: ${activeDoc.validfrom}</li>
								<li>Work Expiry Date: ${activeDoc.work_exp}</li>
							</ul>
						</div>
					</div>`
				);

				if (!result.isConfirmed) {
					clearState();
					return;
				}
			}
		}

		const formData = { 
			...values ,
			minWage: values.minWage || null,
			maxWage: values.maxWage || null,
		};
		
		if (isFromWorkQueue && onUpdate) {
			onUpdate(formData);
			clearState();
			return;
		}

		let docs = data?.map((item) => item) ?? [];

		if (!isNaN(idx)) {
			docs[idx] = formData;
		} else {
			formData["id"] = uuid4();
			docs.push(formData);
		}

		payload.workauth = docs

		const callback = () => {
			if (loadInitialValues()?.active !== "Y" || !loadInitialValues()?.active) {
				dispatch(saveWorkAuthActive(formData, employeeID, clearState));
			} else {
				clearState();
			}
		};

		dispatch(updateProfile(payload, employeeID, payload, callback));
	};

	const loadInitialValues = () => {
		if (isFromWorkQueue && data) {
			return data;
		} else if (!isNaN(idx)) {
			return data[idx];
		} else {
			return {};
		}
	};

	const getSaveButtonText = () => {
		if (isFromWorkQueue && data) {
			return "Approve";
		} else if (!isNaN(idx)) {
			return "Save Changes";
		} else {
			return "Create";
		}
	};

	const workAuthList = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.workauth?.fields?.filter((field) => field.name === "work_type")?.[0]?.values || [])

	return (
		<Modal
			{...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			enforceFocus={!isFromWorkQueue}
			keyboard={false}
		>
			<Formik
				validationSchema={schema}
				initialValues={loadInitialValues()}
				onSubmit={handleSubmit}
				enableReinitialize={true}
				validateOnMount={true}
			>
				{(formik) => {
					console.log("formik", formik.initialValues);
					return (
						<Form>
							<AppModalHeader
								title={
									!isNaN(idx)
										? "Edit Work Authorization"
										: "Create Work Authorization"
								}
							/>
							<Modal.Body>
								<Row>
									<Col xl={4}>
										<Field
											name="work_type"
											type="select"
											options={workAuthList?.map(optionMapper)}
											label={"Type *"}
											component={SelectFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="work_number"
											type="text"
											label={"Number *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="work_issue"
											type="date"
											label={"Issue Date *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="validfrom"
											type="date"
											label={"Valid From *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="work_exp"
											type="date"
											label={"Expiry Date *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="active"
											type="select"
											options={Constants.ACTIVE_STATUS}
											label={"Status *"}
											component={SelectFormField}
										/>
									</Col>
									<Col xl={4}>
                                            <Field
											name="document"
											component={FileUploadForm}
											label="Document *"
											type="text"
											employeeId={employeeID}
											sectionKey="workauth"
											/>
									</Col>
									<Col xl={4}>
										<Field
											name="minWage"
											type="number"
											label={formik.values.work_type === 'H-1B' ? 'Min Wage *' : 'Min Wage'}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="maxWage"
											type="number"
											label={"Max Wage *"}
											component={TextFormField}
										/>
									</Col>
								</Row>
							</Modal.Body>
							{props.actions ? (
								props.actions(formik)
							) : (
								<AppModalFooter
									saveButtonText={getSaveButtonText()}
									saveButtonDisabled={
										!formik.isValid ||
										formik.isSubmitting ||
										!formik.dirty
									}
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
