import React, { useEffect, useState } from "react"
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  AppBar,
  Dialog,
  Typography,
  FormControlLabel,
  Checkbox,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  List,
  FormHelperText,
  Tab,
  Tabs
} from "@material-ui/core"

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  InfoOutlined
} from "@material-ui/icons"
import { GoFileSymlinkFile } from "react-icons/go"
import {
  MobileNumberFormatter,
  CurrencyFormatter,
} from "../../../../../shared/customNumberFormats"
import { Close as CloseIcon } from "@material-ui/icons"
import { FaCashRegister } from "react-icons/fa"
import useStyles from "../../../../../shared/styles/dialogStyles"
import validate from "../../../../../shared/validation"
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter"
import { useInvoiceListContext } from "../context";
import { uploadToStorage } from "../../../../../shared/fileUploader"
import TabPanel from "../../../../../shared/tabPanel"
import History from './paymentHistory'
import { Link } from "react-router-dom" 


function Payment(props) {
  const { invoiceID, openBalance, invoicedAmount, receivedAmountTillNow, discountReceivedTillNow, isPaymentDone
  } = props
  const { state, services } = useInvoiceListContext()
  const classes = useStyles()
  const [open, setOpen] = useState(false)


  const initialState = {
    clientId: "",
    paymentMethod: "",
    paymentDate: CalendarFormatter.standardDateFormat(new Date()),
    referenceNumber: "",
    attachment: {
      sourcePath: "",
      publicURL: "",
    },
    isUploading: false,
    paymentAmount: "",
    discountDoneDetails: 0,
    discountDetails: [
      {
        name: "",
        value: 0,
        type: "",
      },
    ],
    receivedAmount: 0,
    noReference: false,
    paymentDoneAmount: 0,
    paymentID: ""

  }
  const [isEdit, setIsEdit] = useState(false)
  const [isUpdatePayment, setIsUpdatePayment] = useState(false)
  const [paymentState, setPaymentState] = useState(initialState)
  const [componentKey, setComponentKey] = useState(Date.now())
  const [value, setValue] = useState(0)
  const handleTabChange = (_, value) => setValue(value)
  // console.log(isUpdatePayment)
  const handleUpdate = (e, data) => {
    e.preventDefault()
    setPaymentState(() => ({
      ...data,
    }))
  }
  const handleClickOpen = () => {
    // componentDidMount()
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setIsEdit(false)
    setIsUpdatePayment(false)
    setValue(0)
    // clearState()
  }

  useEffect(() => {
    if (open) {
      setPaymentState(initialState)
      setComponentKey(Date.now())
    }
  }, [open])

  const handleChange = (key, value) => {
    if (key === "paymentAmount") {
      setIsEdit(false)
      setPaymentState((prevState) => {
        return ({ ...prevState, [key]: value })
      })
    }
    else {
      setPaymentState((prevState) => ({ ...prevState, [key]: value }))
    }
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setPaymentState((prevState) => ({ ...prevState, file, isUploading: true }))
      const fileName = `${paymentState.referenceNumber}`
      const filePath = `Payments/${invoiceID}/${fileName}`
      uploadToStorage(file, filePath, fileName, "file")
        .then((url) => {
          setPaymentState((prevState) => ({
            ...prevState,
            attachment: {
              publicURL: url,
              sourcePath: `${filePath}.${file.name.split(".")[1]}`,
            },
            isUploading: false,
          }))
        })
        .catch((err) => {
          console.error(err)
          setPaymentState((prevState) => ({ ...prevState, isUploading: false }))
        })
    }
  }

  const handleDiscountDetails = (event, index) => {
    setIsEdit(false)
    const { name, value } = event.target
    let data = paymentState.discountDetails
    if (index <= data.length - 1) {
      if (name === "value") {
        if (value === "") {
          data[index]["value"] = "";
        } else {
          data[index][name] = Number(value)
        }
      } else {
        if (name === "type")
          data[index]["value"] = "";
        data[index][name] = value;
      }
    }
    setPaymentState((prevState) => ({ ...prevState, discountDetails: data }))
  }

  const onAddDiscount = () => {
    let discountDetails = paymentState.discountDetails
    discountDetails = [
      ...discountDetails,
      {
        name: "",
        value: 0,
        type: "",
      },
    ]
    setPaymentState((prevState) => ({ ...prevState, discountDetails }))
  }

  const onRemoveDiscount = (index) => {
    let discountDetails = []
    discountDetails = paymentState.discountDetails.filter((_, ind) => index !== ind)
    setPaymentState((prevState) => ({ ...prevState, discountDetails }))
  }

  const clearState = () => {
    setPaymentState(initialState)
    setIsEdit(false)
    setIsUpdatePayment(false)
  }
  const handleUpdatePayment = (e, handleClose) => {
    e.preventDefault();
    let emptyDiscount = [
      {
        name: "",
        value: 0,
        type: "",
      },
    ]
    const discount = paymentState.discountDetails
    const payload = {
      paymentType: paymentState.paymentMethod,
      referenceNumber: paymentState.referenceNumber,
      attachmentDetails: {
        sourcePath: paymentState.attachment.sourcePath,
        publicURL: paymentState.attachment.publicURL,
      },
      paymentDate: paymentState.paymentDate,
      clientID: paymentState.clientId,
      paymentAmount: paymentState.paymentAmount,
      discountDetails: discount.length < 2 && discount[0].type === "" ? [] : discount ? discount : emptyDiscount,
      invoiceID: invoiceID,
      receivedAmount: paymentState.receivedAmount,
      noReference: paymentState.noReference,
      paymentDoneAmount: 0,
      paymentID: paymentState.paymentID
    }
    // console.log(paymentState)
    services.updatePayment(payload, clearState, handleClose)
  }
  const handlePayment = (e, handleClose) => {
    e.preventDefault();
    const discount = paymentState.discountDetails
    const payload = {
      paymentType: paymentState.paymentMethod,
      referenceNumber: paymentState.referenceNumber,
      attachmentDetails: {
        sourcePath: paymentState.attachment.sourcePath,
        publicURL: paymentState.attachment.publicURL,
      },
      paymentDate: paymentState.paymentDate,
      clientID: paymentState.clientId,
      paymentAmount: paymentState.paymentAmount,
      discountDetails: discount.length < 2 && discount[0].type === "" ? [] : discount,
      invoiceID: invoiceID,
      receivedAmount: paymentState.receivedAmount,
      noReference: paymentState.noReference,
      paymentDoneAmount: 0
    }
    // console.log("----", payload)
    services.newPayment(payload, clearState, handleClose)
  }
  // console.log(initialState, paymentState)

  return (
    <div key={componentKey} >
      <Tooltip title="Make Payment">
        <Link style={{ color: "#2C9273", paddingLeft: "5px", paddingRight: "5px"}} 
          onClick={(e) => {
            e.preventDefault()
            handleClickOpen()
          }}
        >
          <FaCashRegister size={20} /></Link>
      </Tooltip>
      <Dialog disableEscapeKeyDown disableBackdropClick open={open} fullWidth maxWidth="lg">
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component="h1" variant="h4" align="center" style={{ color: "whitesmoke" }}>
                Payment
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            isUpdatePayment ?
              handleUpdatePayment(e, handleClose) : handlePayment(e, handleClose)

          }}
        >
          <Tabs value={value} onChange={handleTabChange} variant="scrollable">
            <Tab label="History" />
            <Tab label="Payment" disabled={isPaymentDone} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <History
              // invoiceID={invoiceID}
              invoicedAmount={invoicedAmount}
              services={services}
              invoiceID={invoiceID}
              setIsEdit={setIsEdit}
              setIsUpdatePayment={setIsUpdatePayment}
              handleUpdate={handleUpdate}
              // history={state.paymentHistory}
              handleTabChange={handleTabChange}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <main className={classes.layout}>
              <div>
                <div>
                  <Paper elevation={2} style={{ padding: '10px', marginBottom: '10px' }} >
                    <div style={{ fontSize: '18px', fontWeight: 'lighter' }} className="d-flex justify-content-spacebetween">
                      <span>
                        <u>Invoiced Amount: {validate.currencyFormatterUs(invoicedAmount)}</u>
                      </span>
                      <span>
                        <u>Received Amount: {validate.currencyFormatterUs(receivedAmountTillNow)}</u>
                      </span>
                      <span>
                        <u>Total Discount received till now: {validate.currencyFormatterUs(discountReceivedTillNow)}</u>
                      </span>
                      <span>
                        <u>Open Balance:
                          {validate.currencyFormatterUs(openBalance + (isEdit ? 0 : Math.abs(paymentState.discountDoneDetails) + paymentState.paymentDoneAmount) - (isEdit ? 0 : Math.abs(paymentState.paymentAmount))
                            - (isEdit ? 0 : Math.abs(services.calculateDiscount(invoicedAmount, paymentState.discountDetails))))}</u>
                      </span>
                    </div>
                  </Paper>
                  <Paper elevation={2} style={{ padding: '10px' }} >
                    <div>
                      <h3>
                        <u>Payment:</u>
                      </h3>
                    </div>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            required
                            select
                            style={{ minWidth: "100%", marginTop: "-1px" }}
                            label="Payment method"
                            value={paymentState.paymentMethod}
                            name="paymentMethod"
                            onChange={(e) => handleChange("paymentMethod", e.target.value)}
                          >
                            <MenuItem value="cheque">By Cheque</MenuItem>
                            <MenuItem value="online">By Online</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                handleChange("paymentAmount", Number(e.target.value))
                            }}
                            value={paymentState.paymentAmount}
                            fullWidth
                            required
                            label="Payment Amount"
                            name="paymentAmount"
                            InputProps={{
                              inputComponent: CurrencyFormatter,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              size="small"
                              required
                              fullWidth
                              value={paymentState.paymentDate ? paymentState.paymentDate : null}
                              label="Payment Date"
                              name="paymentDate"
                              format="MM/dd/yyyy"
                              onChange={(date) => {
                                if (!isNaN(Date.parse(date)))
                                  handleChange("paymentDate", CalendarFormatter.standardDateFormat(date))
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={paymentState.noReference}
                                onChange={(e) => {
                                  handleChange("noReference", e.target.checked)
                                }}
                                disabled={state.isInvoiceEdit}
                              />
                            }
                            label="No Reference Number"
                          />
                        </Grid>
                        {
                          !paymentState.noReference ?
                            <>
                              <Grid item xs={4}>
                                <TextField
                                  size="small"
                                  required={!paymentState.noReference}
                                  fullWidth
                                  label="Reference Number"
                                  name="refereceNumber"
                                  value={paymentState.referenceNumber}
                                  onChange={(e) => e.target.value.match(/^[a-z0-9]+$/i) || e.target.value.length === 0 ? handleChange("referenceNumber", e.target.value) : () => { }}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  // style={{ display: "none" }}
                                  id="contained-button-file-wafile"
                                  type="file"
                                  onChange={handleFile}
                                  disabled={!paymentState.referenceNumber}
                                  required={!paymentState.noReference}
                                  variant="outlined"
                                  size="small"
                                  label="Reference Document"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                                <div className="d-flex justify-content-between">
                                  <p>
                                    {paymentState.attachment.publicURL.length ? (
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={paymentState.attachment.publicURL}
                                      >
                                        <GoFileSymlinkFile size={22} />
                                      </a>
                                    ) : null}{" "}
                                  </p>
                                </div>
                                {paymentState.isUploading ? <p>Uploading please wait...</p> : ""}
                              </Grid>
                            </>
                            :
                            null
                        }

                      </Grid>
                    </div>
                    <div>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <List dense>
                              <ListItem>
                                <ListItemText>
                                  {(
                                    <h2 style={{ fontWeight: 500 }}>Payment Amount</h2>
                                  )}
                                </ListItemText>
                                <ListItemSecondaryAction>
                                  <h1>
                                    {validate.currencyFormatterUs(paymentState.paymentAmount)}
                                  </h1>
                                </ListItemSecondaryAction>
                              </ListItem>
                              <h3>
                                <u>Amount to be Discounted:</u>
                                <Tooltip title={`Applied using invoiced amount ${validate.currencyFormatterUs(invoicedAmount)}`}>
                                  <InfoOutlined style={{ marginBottom: '3px' }} />
                                </Tooltip>
                              </h3>
                              {paymentState.discountDetails.map((item, index) => {
                                return (
                                  <ListItem style={{ marginTop: '5px' }} >
                                    <ListItemIcon>
                                      <div className="d-flex flex-row">
                                        <div className="mr-1">
                                          <TextField
                                            size="small"
                                            variant="outlined"
                                            value={item["name"]}
                                            name="name"
                                            onChange={(e) => handleDiscountDetails(e, index)}
                                            label="Name"
                                            fullWidth
                                          />
                                        </div>
                                        <div className="mr-1 mb-1">
                                          <TextField
                                            size="small"
                                            label="Select"
                                            variant="outlined"
                                            disabled={item.name === ""}
                                            value={item["type"]}
                                            onChange={(e) => handleDiscountDetails(e, index)}
                                            select
                                            style={{ width: '140px' }}
                                            name="type"
                                          >
                                            <MenuItem value="byValue">By Value</MenuItem>
                                            <MenuItem value="byPercentage">By Percentage</MenuItem>
                                          </TextField>
                                        </div>
                                        <div className="mr-1">
                                          <TextField
                                            size="small"
                                            type="number"
                                            variant="outlined"
                                            disabled={item.type === ""}
                                            value={item["value"]}
                                            onChange={(e) => {
                                              const val = Number(e.target.value)
                                              if (
                                                (paymentState.discountDetails[index]["type"] === "byPercentage" &&
                                                  val < 100) ||
                                                paymentState.discountDetails[index]["type"] === "byValue"
                                              ) {
                                                paymentState.discountDetails[index]["value"] = val
                                                handleDiscountDetails(e, index)
                                              }
                                            }}
                                            label="Discount"
                                            name="value"
                                          />
                                        </div>
                                        <div className="d-flex">
                                          {paymentState.discountDetails.length > 1 ? (
                                            <div>
                                              <Tooltip title="Remove this discount">
                                                <IconButton onClick={() => onRemoveDiscount(index)}>
                                                  <RemoveCircleOutlineIcon
                                                    className="text-danger"
                                                    fontSize="small"
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          ) : null}
                                          {index === paymentState.discountDetails.length - 1 ? (
                                            <div>
                                              <Tooltip title="Add multiple discounts">
                                                <IconButton onClick={onAddDiscount}>
                                                  <AddCircleOutlineIcon fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </ListItemIcon>
                                    <ListItemSecondaryAction>
                                      <h2 style={{ fontWeight: 500 }}>
                                        $ {services.calculateDiscount(invoicedAmount, [paymentState.discountDetails[index]])}
                                      </h2>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Paper>

                </div>
                <br />
                <div className="text-center">
                  {isUpdatePayment ?
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={paymentState.paymentAmount === "" || paymentState.paymentAmount < 0 || state.isAddingPayment}
                    >
                      Update Payment
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={paymentState.paymentAmount === "" || paymentState.paymentAmount < 0 || state.isAddingPayment}
                    >
                      Make Payment
                    </Button>
                  }
                </div>
              </div>
            </main>
          </TabPanel>
        </form>
      </Dialog>
    </div>
  )
}

export default Payment
