import React from "react";
import {
  TextField,
  MenuItem,
  Button,
  Drawer,
  IconButton,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import { FiEdit3 } from "react-icons/fi";
import { FcBusinessContact } from "react-icons/fc";
import { LocationOn as LocationOnIcon } from "@material-ui/icons";
import validate from "../../../../../../../shared/validation";
import {
  MobileNumberFormatter,
  NumberFormatCustom,
} from "../../../../../../../shared/customNumberFormats";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  }
}));

function Presentation(props) {
  const {
    handleChange,
    contact,
    handleSubmit,
    handleKeyValuePair,
    btnContent,
    hidden,
    edit,
    isPlacement,
    clients_locations,
    address,
    handleChangeAddress,
    clients,
    isWorkLocation,
    clientList,
    workLocationClientId,
    setWorkLocationClientId,
    clients_list_locations,
    clearState
  } = props;
  
  const [drawerState, setState] = React.useState({
    right: false,
  });
const filteredClientList  = clientList ? Object.values(clients).filter((item) => clientList.includes(item.id)) : []
const filteredClientListLocations  = workLocationClientId ? Object.values(clients_list_locations).filter((item) => item.clientId === workLocationClientId) : []

  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...drawerState, [anchor]: open });
  };

  const genderList = ["Female", "Male", "Others"];
  const contatctList = ["Staff", "Recruiter", "Reporting Manager"];

  return (
    <div>
      {isPlacement ? (
        <Button
          hidden={hidden}
          variant="contained"
          color="secondary"
          onClick={toggleDrawer("right", true)}
        >
          <span>
            {btnContent} {!edit ? "Add Contact" : "Edit Contact"}
          </span>
        </Button>
      ) : (
        <IconButton hidden={hidden} onClick={toggleDrawer("right", true)}>
          {btnContent}{" "}
        </IconButton>
      )}
      <Drawer
        anchor={"right"}
        open={drawerState["right"]}
        onMouseEnter={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onClose={toggleDrawer("right", false)}
      >
        <div className="mt-3">
          <div className="text-center">
            {edit ? (
              <>
                <FiEdit3 fontSize="50px" />
                <h3>
                  <u>Edit Contact</u>
                </h3>
              </>
            ) : (
              <>
                <FcBusinessContact fontSize="80px" />
                <h3>
                  <u>Add Contact</u>
                </h3>
              </>
            )}
          </div>
          
              <div className="m-3">
              { 
              isWorkLocation ? 
              (<Grid item xs={12}>
                 <Autocomplete
                      options={filteredClientList}
                      value={filteredClientList[workLocationClientId]}
                      getOptionLabel={(option) => option.businessDisplayName + " - " +option.id}
                      style={{ minWidth: "100%" }}
                      onChange={(e, v) => {
                        if (v) {
                          console.log(v)
                          setWorkLocationClientId(v.id)
                          clearState();
                          // stateSetters.setClientId(v.clientId)
                          // stateSetters.setNetTerms(v?.netTerms ? parseInt(v?.netTerms) : "")
                          // services.clearState(["placementID", "employeeID", "poNumber"])
                        }
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} label="Select Client" autoComplete="do-not-autofill"/>
                      )}
                    />
              </Grid> ) : <></>}
              </div>
              {
              workLocationClientId || !isWorkLocation?
              
              <form className="m-3" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="Representative Name"
                  name="representativeName"
                  value={contact.representativeName}
                  onChange={handleChange}
                  helperText={
                    contact.representativeName.length
                      ? validate.checkName(contact.representativeName)
                        ? ""
                        : "Enter valid representative name"
                      : ""
                  }
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Contact Type"
                  name="contactType"
                  value={contact.contactType}
                  onChange={handleChange}
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  {contatctList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Job Title"
                  name="jobTitle"
                  value={contact.jobTitle}
                  onChange={handleChange}
                  // helperText={
                  //   contact.jobTitle.length
                  //     ? validate.checkName(contact.jobTitle)
                  //       ? ""
                  //       : "Enter valid job title"
                  //     : ""
                  // }
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Gender"
                  name="gender"
                  value={contact.gender}
                  onChange={handleChange}
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  {genderList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={contact.email}
                  onChange={handleChange}
                  helperText={
                    contact.email.length
                      ? validate.checkEmail(contact.email)
                        ? ""
                        : "Enter valid email"
                      : ""
                  }
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Mobile"
                  name="mobile"
                  value={contact.mobile}
                  onChange={handleChange}
                  // helperText={
                  //   contact.mobile.length
                  //     ? validate.checkNumber(contact.mobile)
                  //       ? ""
                  //       : "Enter valid phone number"
                  //     : ""
                  // }
                  size="small"
                  //required
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    inputComponent: MobileNumberFormatter,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TextField
                      label="Work Phone"
                      name="workPhone"
                      value={contact.workPhone}
                      onChange={handleChange}
                      helperText={
                        contact.workPhone.length
                          ? validate.checkNumber(contact.workPhone)
                            ? ""
                            : "Enter valid work phone"
                          : ""
                      }
                      size="small"
                      required
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        inputComponent: MobileNumberFormatter,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Ext."
                      name="workPhoneExtension"
                      value={contact.workPhoneExtension}
                      onChange={handleChange}
                      // helperText={
                      // 	workPhoneExtension.length
                      // 		? validate.checkNumber(workPhoneExtension)
                      // 			? ''
                      // 			: 'Enter valid work phone'
                      // 		: ''
                      // }
                      size="small"
                      //required
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Home Phone"
                  name="homePhone"
                  value={contact.homePhone}
                  onChange={handleChange}
                  // helperText={
                  //   contact.homePhone.length
                  //     ? validate.checkNumber(contact.homePhone)
                  //       ? ""
                  //       : "Enter valid home phone"
                  //     : ""
                  // }
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    inputComponent: MobileNumberFormatter,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  id="contacts-invoice-address-select"
                  fullWidth
                  options={isWorkLocation ? filteredClientListLocations : clients_locations}
                  classes={{
                    option: classes.icon,
                  }}
                  value={address}
                  onChange={(_, value) => {
                    if (value) {
                      handleChangeAddress(value)
                    }
                  }}
                  style={{ marginTop: "3px" }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.locationsline1 +
                    " " +
                    option.locationsline2 +
                    " " +
                    option.locationscity +
                    " " +
                    option.locationsstate_name +
                    " " +
                    option.locationszip +
                    " " +
                    option.locationscountry
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                          <span style={{ fontWeight: 700 }}>
                            {option.locationsline1 + " " + option.locationsline2}
                          </span>
                          <Typography variant="body2" color="textSecondary">
                            {option.locationscity +
                              " " +
                              option.locationsstate_name +
                              " " +
                              option.locationscountry +
                              " " +
                              option.locationszip}
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Address *"
                      id="contact-details-address"
                      size="small"
                      autoComplete="do"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "do-not-autofill", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <br />
            <div className="text-center">
              <Button
                type="submit"
                variant="contained"
                color={edit ? "secondary" : "primary"}
                onClick={toggleDrawer("right", false)}
                disabled={
                  contact.representativeName.trim().length <= 0 ||
                  !validate.checkName(contact.representativeName.trim()) ||
                  !contact.gender.trim() ||
                  contact.jobTitle.trim().length <= 0 ||
                  !validate.checkEmail(contact.email) ||
                  contact.workPhone.trim().length <= 0 ||
                  !validate.checkNumber(contact.workPhone) ||
                  !validate.checkAddress(contact?.locationsline1 || "") ||
                  !validate.checkZip(contact.locationszip) ||
                  !contact.locationscity.trim() ||
                  !contact.locationsstate_name ||
                  !contact.locationscountry
                }
              >
                {edit ? "Update" : "Add"}
              </Button>
            </div>
          </form>
        :<></>  
        }
         
        </div>
      </Drawer>
    </div>
  )
}

export default Presentation;
