import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import State from "../../../../../../shared/components/BootStrapFormFields/StateField";
import Country from "../../../../../../shared/components/BootStrapFormFields/CountryField";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";

const schema = yup.object().shape({
	line1: yup.string().required(),
	line2: yup.string(),
	country: yup.string().required(),
	state: yup.string().required(),
	zip: yup.string().required(),
	city: yup.string().required(),
});

export default function MailingAddressEditModal(props) {
	const {
		data,
		employeeID,
		onHide,
		isFromWorkQueue = false,
		onUpdate = () => {},
	} = props;
	const dispatch = useDispatch();

	const handleSubmit = (values, actions) => {
		actions.setSubmitting(true);
		const formData = { ...values };
		const payload = {
			mailingaddress: formData,
		};
		const callback = () => {
			onHide();
			actions.setSubmitting(false);
		};

		if (isFromWorkQueue && onUpdate) {
			onUpdate(formData);
			callback();
			return;
		}

		dispatch(updateProfile(payload, employeeID, payload, callback));
	}

	const loadInitialValues = () => {
		// if (isFromWorkQueue) {
		// 	return data;
		// } else {
		// 	return {};
		// }
		return data || {};
	};

	const getSaveButtonText = () => {
		if (isFromWorkQueue && data) {
			return "Approve";
		} else  {
			return "Save Changes";
		}
	};

	return (
		<Modal
			{...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			enforceFocus={!isFromWorkQueue}
		>
			<Formik
				validationSchema={schema}
				initialValues={{ ...loadInitialValues() }}
				validateOnMount={true}
				onSubmit={handleSubmit}
				enableReinitialize={true}
			>
				{(formik) => {
					return (
						<Form>
							<AppModalHeader title="Edit Mailing Address" />
							<Modal.Body>
								<Row>
									<Col xl={4}>
										<Field
											name="line1"
											type="text"
											label={"Line 1 *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="line2"
											type="text"
											label={"Line 2"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="country"
											type="select"
											label={"Country *"}
											component={Country}
											onChangeCallback={() => {
												formik.setFieldValue("state", "");
											}}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="state"
											type="select"
											country={formik.values.country}
											label={"State *"}
											component={State}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="city"
											type="text"
											label={"City *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="zip"
											type="text"
											label={"Zip *"}
											component={TextFormField}
										/>
									</Col>
								</Row>
								{/* <Row>
									<Col xl={12}>
										<pre>
											{JSON.stringify(formik.values, null, 2)}
										</pre>
									</Col>
								</Row> */}

							</Modal.Body>
							{props.actions ? (
								props.actions(formik)
							) : (
								<AppModalFooter
									saveButtonText={getSaveButtonText()}
									saveButtonDisabled={
										!formik.isValid ||
										formik.isSubmitting ||
										!formik.dirty 
									}
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
