import { getIn } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

export default function TextFormField({field, form, required, ...props }) {
    const ErrorText = getIn(form.touched, field.name) && getIn(form.errors, field.name)
  return (
    <Form.Group className="mb-3">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        //value={data?.firstname}
        isInvalid={Boolean(ErrorText)}
        {...field}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {ErrorText}
    </Form.Control.Feedback>
    </Form.Group>
  );
}
