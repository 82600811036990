import React, { useEffect, useState } from "react";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import {
  Tooltip,
  Typography,
  Grid,
  Dialog,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import SunEditor from "suneditor-react";
import ChipInput from "material-ui-chip-input";
import Slide from "@material-ui/core/Slide";
import validate from "../../../../../shared/validation"
import { useInvoiceListContext } from "../context";
import { Link } from "react-router-dom"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MailToClient(props) {
  const { invoiceID, notifiers, isPaymentDone, isVoid, isMailedToClient, clientName, companyDetails, openBalance, invoiceDueDate } = props
  const { services, state } = useInvoiceListContext()
  const [open, setOpen] = useState(false);
  const [mailToClient, setMailToClient] = useState({
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    body: "",
    helperText: {
      to: "",
      cc: "",
      bcc: "",
    },
    attachment: [],
    isUploading: false,
  });

  useEffect(() => {
    if (open) {
      const encodedID = encodeURIComponent(invoiceID)
      let content = `
          Hello ${clientName},
          <div>
            An invoice has been generated for you by ${companyDetails?.name}.          
          </div>
          <div>
            Find the details below in brief:
          </div>
          <br>
          <span class='d-flex' ><span>Invoice Number:</span> <b>${invoiceID}</b></span>\n
          <span class='d-flex' ><span>Invoice Amount:</span> <b>$ ${openBalance}</b></span>
        <br>
          Please note that the payment is due by ${invoiceDueDate}.
        <br>
          If you have any questions related to this invoice, please contact us as soon as possible.
        <br>
          Please <a href='${`${window.location.origin}/invoices?invoiceID=${encodedID}`}' target='_blank'>click here</a> to view the invoice details.
        </div>
      <hr><br>
      
        Thanks & Regards,
          `;
      setMailToClient({
        ...mailToClient,
        to: notifiers?.to,
        cc: notifiers?.cc,
        bcc: notifiers?.bcc,
        subject: isMailedToClient ? "Re: " + invoiceID : invoiceID,
        body: content,
      })
    }
  }, [open]);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (obj) => {
    setMailToClient({
      ...mailToClient,
      ...obj,
    });
  };

  const handleAdd = (name, chip) => {
    let data = mailToClient[name];
    if (validate.checkEmail(chip)) {
      data = [...data, chip];
      handleChange({
        helperText: {
          to: "",
          cc: "",
          bcc: "",
        },
      });
    } else {
      handleChange((prevmailToClient) => ({
        helperText: {
          ...prevmailToClient.helperText,
          [name]: "Enter valid email",
        },
      }));
    }
    handleChange({ [name]: data });
    console.log("add", data);
  };

  const handleDelete = (name, index) => {
    let data = mailToClient[name];
    data.splice(index, 1);
    handleChange({ [name]: data });
    console.log("delete", data);
  };

  const handleSend = () => {
    const { to, cc, bcc, subject, body } = mailToClient;
    const mail = {
      to,
      cc,
      bcc,
      subject,
      body,
    };
    handleClose();
    services.sendInoviceToClient(mail, invoiceID);
  };


  return (
    <div>
      {/* {state.isMailing ? (
        <CircularProgress
          style={{ marginTop: "12px", marginLeft: "11px" }}
          size={18}
        />
      ) : ( */}
       <Tooltip title="Send to Client">
        <Link style={{ color: "blue", paddingLeft: "5px", paddingRight: "5px"}} hidden={isVoid || isPaymentDone} onClick={handleClickOpen}>
          <ContactMailIcon size={20} /></Link>
      </Tooltip>    
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
      >
        <div className="m-4">
          <Typography variant="h6" gutterBottom></Typography>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ChipInput
                  value={mailToClient.to}
                  // onAdd={(chip) => handleAdd("to", chip)}
                  // onDelete={(_, index) => handleDelete("to", index)}
                  allowDuplicates={false}
                  label="To *"
                  fullWidth
                  helperText={mailToClient.helperText.to}
                  size="small"
                  newChipKeyCodes={[13, 9, 32]}
                />
              </Grid>
              <Grid item xs={12}>
                <ChipInput
                  value={mailToClient.cc}
                  // onAdd={(chip) => handleAdd("cc", chip)}
                  // onDelete={(_, index) => handleDelete("cc", index)}
                  allowDuplicates={false}
                  label="CC"
                  fullWidth
                  helperText={mailToClient.helperText.cc}
                  size="small"
                  newChipKeyCodes={[13, 9, 32]}
                />
              </Grid>
              <Grid item xs={12}>
                <ChipInput
                  value={mailToClient.bcc}
                  onAdd={(chip) => handleAdd("bcc", chip)}
                  onDelete={(_, index) => handleDelete("bcc", index)}
                  allowDuplicates={false}
                  label="BCC"
                  fullWidth
                  helperText={mailToClient.helperText.bcc}
                  size="small"
                  newChipKeyCodes={[13, 9, 32]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={mailToClient.subject}
                  onChange={(e) => handleChange({ subject: e.target.value })}
                  name={"subject"}
                  fullWidth
                  size="small"
                  label="Subject"
                />
              </Grid>
              <Grid item xs={12}>
                <SunEditor
                  placeholder="Body"
                  setContents={mailToClient.body}
                  onChange={(data) => handleChange({ body: data })}
                  height={200}
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "removeFormat",
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "showBlocks",
                        "codeView",
                      ],
                    ],
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <br />
          <div className="d-flex justify-content-between">
            <div />
            <div className="d-flex">
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={handleSend}
                color="primary"
                disabled={!mailToClient.to?.length || !mailToClient.body?.length}
                variant="contained"
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default MailToClient;
