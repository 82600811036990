import React from "react"
import { connect } from "react-redux"
import { firestoreConnect,isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { contactActions } from "../../../../../middleware"
import Presentation from "./Presentation"

function Container(props) {
  const {clients,clients_list_locations} = props
  if(isLoaded(props.contacts) && clients && clients_list_locations )
    return (
      <div>
        <Presentation {...props} />
      </div>
    )
  return( 
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    contacts: state.firestore.ordered.contacts,
    clients_locations:state.firestore.ordered.clients_locations,
    clients:state.firestore.ordered.clients,
    clients_list_locations:state.firestore.ordered.clients_list_locations
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteContact: (payload) => {
      dispatch(contactActions(payload, "delete"))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    
    return [
      {
        collection: "CLIENTS",
        doc: props.client.id,
        subcollections: [
          {
            collection: "CLIENTS_CONTACTS",
            where: [["isExist", "==", true]],
          },
        ],
        storeAs: "contacts",
      },
      {
        collectionGroup: "CLIENTS_LOCATIONS",
        storeAs: "clients_list_locations",
      },
      {
        collection: "CLIENTS",
        storeAs: "clients",
      }
    ]

  })
)(Container)
