import React, { useState,useEffect } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import styles from "../../styles/dialogStyles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import SunEditor from "suneditor-react";
import { CgNotes } from "react-icons/cg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateProfile } from "../../Services/EmployeeManagment/middleware";

function EmployeeNotes(props) {
  const { rowData,updateProfile,fetchActiveEmployees } = props;
  const [open, setOpen] = React.useState(false);
  const [employeenotes, setEmployeeNotes] = useState(rowData?.employeenotes ?? "<p></p>");

  const handleClickOpen = () => {
    setOpen(true);
    setEmployeeNotes(rowData?.employeenotes)
  };
  const handleClose = () => {
    setOpen(false);
    setEmployeeNotes("");
    setTimeout(() => {
      props?.fetchActiveEmployees();
    }, 4000);
  };

  const handleNotes = async () => {
    let payload = {
        employeeID : rowData.employeeid,
        data : {employeenotes:employeenotes ?? "<p></p>"},
        key : "personal"
    }
    updateProfile(payload,fetchActiveEmployees)
    handleClose();
  };

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html ?? "<p></p>";
    return tmp.textContent || tmp.innerText || "";
}

const employeeNotesInText = stripHtml(employeenotes)

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      {stripHtml(rowData?.employeenotes)?.length > 1 ? (
          <Link style={{ color: "red" }} onClick={handleClickOpen}><CgNotes size={20} /></Link>
      ) : (
        <Link style={{ color: "grey" }} onClick={handleClickOpen}><CgNotes size={20} /></Link>
      )}
      <Dialog
        onClose={handleClose}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          You are about to{" "}
          {stripHtml(rowData?.employeenotes)?.length > 1 ? (
            <span>Update Notes for {rowData?.name}</span>
          ) : (
            <span>Add Notes for {rowData?.name}</span>
          )}
          ?
        </DialogTitle>
        <DialogContent dividers>
          <SunEditor
            placeholder="Please Enter Notes here.*"
            onChange={(content) => setEmployeeNotes(content)}
            setContents={employeenotes}
            height={200}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "outdent",
                  "indent",
                  "list",
                  "table",
                  "link",
                  "image",
                  "fullScreen",
                  "showBlocks",
                  "print",
                  "save",
                ],
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleNotes}
            variant="contained"
            color="primary"
          >
            {stripHtml(rowData?.employeenotes)?.length > 1? (
            <span>Update Notes</span>
          ) : (
            <span>Add Notes</span>
          )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateProfile: (payload) => {
            dispatch(updateProfile(payload))
        }
    }
}

export default connect(null, mapDispatchToProps)(EmployeeNotes)