import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import Presentation from "./Presentation";
import Validations from "../../../../../shared/validation";
import firebase from "../../../../../config/fbConfig";
import { uploadToStorage } from "../../../../../shared/fileUploader";
import { submitExpenses, updateExpenses } from "../../../middleware";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularSpinner from "../../../../../shared/circularSpinner";
function Container(props) {
  const loginId = useSelector((state) => state.firebase.auth.uid);
  console.log(loginId);
  console.log(props.employeeId, loginId, "props.employeeId");
  const { expenseEdit } = props;
  const { actionType } = props.match.params;
  const [placementID, setPlacementID] = useState("");
  const [addDescription, setDescription] = useState("");
  const [expenseSettings, setexpenseSettings] = useState([]);
  const documentInitialState = {
    expenseDoc: "",
    isUploading: false,
    attachmentDetails: {
      publicURL: "",
      sourcePath: "",
    },
  };
  const [ExpenseDoc, setExpenseDoc] = useState(documentInitialState);
  const history = useHistory();
  console.log(expenseEdit);
  const stateInitialState = {
    redirect: false,
    spentDate: "",
    departureDate: "",
    returnDate: "",
    description: "",
    expenseType: "",
    amount: "",
    vendor: "",
    receipt: "",
    clientId: "",
    empCode: "",
    expenseApprover: "",
  };
  const [state, setState] = useState(stateInitialState);

  const expenseActionsCallback = () => {
    setState({
      ...state,
      redirect: true,
    });
  };

  const handleAdd = () => {
    const {
      spentDate,
      departureDate,
      returnDate,
      description,
      expenseType,
      amount,
      vendor,
      receipt,
      empCode,
      clientId,
    } = state;
    let payload = {
      spentDate,
      departureDate,
      returnDate,
      placementID,
      description,
      expenseType,
      amount,
      vendor,
      receipt,
      empCode,
      clientId,
      attachmentDetails: ExpenseDoc.attachmentDetails,
      additionalDetails: addDescription,
    };

    console.log(payload);
    const { submitExpense } = props;
    submitExpense(payload, props.employeeId, placementID, history);
  };
  const handleUpdate = () => {
    const {
      spentDate,
      departureDate,
      returnDate,
      description,
      expenseType,
      amount,
      vendor,
      receipt,
      empCode,
      clientId,
    } = state;
    console.log(empCode);
    let payload = {
      spentDate,
      departureDate,
      returnDate,
      placementID,
      description,
      expenseType,
      amount,
      vendor,
      receipt,
      empCode,
      clientId,
      attachmentDetails: ExpenseDoc.attachmentDetails,
      additionalDetails: addDescription,
    };
    let expenseId = props.match.params.expenseId;
    console.log(payload, props);
    const { updateExpense } = props;
    updateExpense(payload, placementID, expenseId, history);
  };
  const handleChange = (key, value) => {
    if (key === "expenseDoc") {
      // const [state] = this.context
      // const employeeCompanyID = state.employee.companyID
      const fileName = [
        state.expenseType.toUpperCase(),
        "_",
        new Date().toISOString(),
        ".",
        value.name.split(".")[1],
      ].join("");
      const filePath = `Expenses/${state.placementID}/${fileName}`;
      const attachmentDetails = ExpenseDoc.attachmentDetails;
      attachmentDetails["sourcePath"] = filePath;
      setExpenseDoc({
        ...ExpenseDoc,
        isUploading: true,
        attachmentDetails: attachmentDetails,
      });
      return uploadToStorage(value, filePath, fileName, "file")
        .then((url) => {
          attachmentDetails["publicURL"] = url;
          setExpenseDoc({
            ...ExpenseDoc,
            expenseDoc: url,
            isUploading: false,
            attachmentDetails: attachmentDetails,
          });
        })
        .catch((err) => {
          setExpenseDoc({
            ...ExpenseDoc,
            isUploading: false,
          });
          console.log(err);
        });
    }
    setState({
      ...state,
      [key]: value,
    });
  };
  const handleAddDescription = (key, value) => {
    setDescription(value);
  };
  const handlePlacement = (key, value) => {
    console.log("client", value);
    setState({
      ...state,
      placementID: value.placementID,
      clientId: value.clientId,
      empCode: value.employeeID,
    });
    setExpenseDoc(documentInitialState);
    setPlacementID(value.placementID);
    handleCheckExpenseApproval(value.placementID);
    setState(stateInitialState);
  };

  const handleCheckExpenseApproval = async (value) => {
    // const [state] = this.context
    // const list = state.expenses_list
    firebase
      .firestore()
      .collection("EMPLOYEES")
      .doc(props.employeeId)
      .collection("PLACEMENTS")
      .doc(value)
      .collection("SETTINGS")
      .doc("expense_details")
      .get()
      .then((snap) => {
        let data = snap.data();
        console.log(data);
        setexpenseSettings(data.companyIDs);
      });
  };
  useEffect(() => {
    if (actionType === "edit" && props.expenseEdit) {
      const {
        spentDate,
        departureDate,
        returnDate,
        placementID,
        description,
        expenseType,
        amount,
        vendor,
        receipt,
        additionalDetails,
        employeeID,
        clientId,
        attachmentDetails,
      } = expenseEdit;
      console.log("Expense Edit", expenseEdit);
      setState({
        ...state,
        spentDate: spentDate,
        departureDate: departureDate,
        returnDate: returnDate,
        description: description,
        expenseType: expenseType,
        attachmentDetails: attachmentDetails,
        amount: amount,
        vendor: vendor,
        receipt: receipt,
        empCode: employeeID,
        clientId: clientId,
        expenseDoc: attachmentDetails.publicURL,
      });
      setExpenseDoc({
        ...ExpenseDoc,
        isUploading: false,
        expenseDoc: attachmentDetails.publicURL,
        attachmentDetails: attachmentDetails,
      });
      setPlacementID(placementID);
      setDescription(additionalDetails);

      // handleCheckExpenseApproval(placementID)
    }
  }, [expenseEdit]);

  if (isLoaded(props.employeePlacements, props.expenseEdit)) {
    return (
      <div>
        <Presentation
          {...props}
          state={state}
          actionType={actionType}
          addDescription={addDescription}
          handleChange={handleChange}
          expenseActionsCallback={expenseActionsCallback}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          handleAddDescription={handleAddDescription}
          handlePlacement={handlePlacement}
          placementID={placementID}
          expenseSettings={expenseSettings}
          setState={setState}
          setDescription={setDescription}
          setPlacementID={setPlacementID}
          handleCheckExpenseApproval={handleCheckExpenseApproval}
          ExpenseDoc={ExpenseDoc}
        />
      </div>
    );
  }
  return (
    <CircularSpinner />
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps);
  let actionType = ownProps.match.params.actionType;
  let expenseId = ownProps.match.params.expenseId;
  let expenseEdit = [];
  if (actionType === "edit") {
    expenseEdit = state.firestore.ordered.expenses_List
      ? state.firestore.ordered.expenses_List.filter(
          (item) => item.id === expenseId
        )[0]
      : null;
  } else {
  }
  return {
    employeePlacements: state.firestore.ordered.employeePlacements,
    employeeId: state.firebase.auth.uid,
    expenseEdit: expenseEdit,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    submitExpense: (payload, EmpId, PlacementId, history) => {
      dispatch(submitExpenses(payload, EmpId, PlacementId, history));
    },
    updateExpense: (payload, EmpId, PlacementId, expenseId, history) => {
      dispatch(updateExpenses(payload, EmpId, PlacementId, expenseId, history));
    },
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.employeePlacements || !props.expenses_List)
      return [
        {
          collectionGroup: "EXPENSES",
          storeAs: "expenses_List",
        },

        {
          collectionGroup: "PLACEMENTS",
          where: [
            ["draft", "==", false],
            ["isExist", "==", true],
            ["employeeID", "==", props.employeeId],
          ],
          storeAs: "employeePlacements",
        },
      ];

    return [];
  })
)(Container);
