import { getIn } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

export default function CreatableSelectFormField({
	field,
	form,
	required,
  handleCreate,
	isMulti = false,
	onSelect = (value) => {},
	...props
}) {
  const [isLoading, setIsLoading] = React.useState(false);

	const errorText =
		getIn(form.touched, field.name) && getIn(form.errors, field.name);

	const value = props?.options?.find((res) => res.value === field.value) || null;

  const handleCreateOption = async (inputValue) => {
    setIsLoading(true);
    handleCreate(inputValue, callback);
  };

  const callback = () => {
    setIsLoading(false);
  }

	return (
		<Form.Group className="mb-3">
			<Form.Label>{props.label}</Form.Label>
			<CreatableSelect
				isClearable
				isSearchable
        isDisabled={isLoading}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: "#ced4da",
					},
				})}
				key={`select-${field.name}-${value?.value}`}
				value={value}
				options={props?.options}
				onBlur={() => form.setFieldTouched(field.name, true, true)}
				onChange={(option) => {
					if (isMulti) {
						const value = option ? option.map((item) => item.value) : [];
						form.setFieldValue(field.name, value);
						onSelect?.(value);
					} else {
						const value = option ? option.value : "";
						form.setFieldValue(field.name, value);
						onSelect?.(value);
					}
				}}
        onCreateOption={handleCreateOption}
				isMulti={isMulti}
				name={field.name}
				closeMenuOnSelect={true}
				escapeClearsValue={true}
			/>
			{errorText && <Form.Text className="text-danger">{errorText}</Form.Text>}
		</Form.Group>
	);
}
