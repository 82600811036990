import React, { useEffect, useContext } from "react";
import Presentation from "./Presentation";
import Wrapper from "../../../../../shared/wrapper";
import { ContextProvider, Context } from "../component_state/context";
import { connect } from "react-redux";
import {
  checkDateRangeIsValidOrNot,
  loadEmployeePlacements,
  loadPlacement,
  loadTimesheet,
} from "../../../middleware";
import { eachDayOfInterval } from "date-fns";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { formWorkingHours } from "../../../../../utils/TimesheetsUtils";
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter";
import { useHistory } from "react-router-dom";
import { errorMsg } from "../../../../../shared/SnackBars/index";

function Container(props) {
  const [state, handle] = useContext(Context);
  const {
    load_for_edit_or_view,
    loggedInEmployee,
    submittedTimesheets,
    timesheetID,
    placementID,
    employeeID,
    type,
    startDate,
    endDate,
    modules
  } = props;
  const history = useHistory();

  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj,
    });
  };

  async function fetchEmployeePlacements() {
    let placements = await loadEmployeePlacements(loggedInEmployee);
    const obj = {};
    placements.forEach((item) => {
      obj[item.id] = item;
    });
    setState({
      placements: obj,
      employeeID: loggedInEmployee,
      isPlacementsLoaded: true,
    });
  }

  async function fetchPlacement() {
    const placement = await loadPlacement(employeeID, placementID);
    setState({
      placements: { [placement.id]: placement },
      isPlacementsLoaded: true,
    });
  }

  async function fetchTimesheet() {
    const timesheet = await loadTimesheet(employeeID, timesheetID);
    const rangeDates = eachDayOfInterval({
      start: new Date(timesheet.startDate),
      end: new Date(timesheet.endDate),
    });
    console.log(rangeDates);
    setState({
      standardTime: timesheet.workdetails.standardTime,
      OTtime: timesheet.workdetails.OTtime,
      selectedRange: rangeDates,
      timesheetInfo: {
        startDate: timesheet.startDate,
        endDate: timesheet.endDate,
        attachmentDetails: timesheet.attachmentDetails,
      },
      clientId: timesheet.clientId,
      viewingTimesheet: timesheet,
      statusReport:timesheet.statusReport
    });
  }

  const formDefaulters = async () => {
    try {
      await checkDateRangeIsValidOrNot(placementID, startDate, endDate);
      const selectedRange = eachDayOfInterval({
        start: new Date(startDate),
        end: new Date(endDate),
      }).map((d) => CalendarFormatter.standardDateFormat(d));
      const { standardTime, OTtime } = formWorkingHours(selectedRange);
      setState({
        standardTime: standardTime,
        OTtime: OTtime,
        selectedRange: selectedRange,
      });
      fetchPlacement();
    } catch (error) {
      errorMsg("Invalid date range");
      history.push("/console/timesheets");
    }
  };

  useEffect(() => {
    setState({
      type: type,
      timesheetID,
      employeeID,
      isSubmitting: false,
      timesheetInfo: {
        ...state.timesheetInfo,
        startDate: startDate,
        endDate: endDate,
      },
      placementID,
    });

    if (type === "new") {
      fetchEmployeePlacements();
    } else if (type === "defaulter") {
      formDefaulters();
    } else {
      fetchPlacement();
      fetchTimesheet();
    }
  }, [type]);

  useEffect(() => {
    return () => {
      handle({
        type: "RESET",
      });
    }
  }, []);
  // if (props.match.params.type === "edit")
  //   return "Edit timesheet is still under working progress"
  let defaulterRanges = 0;
  if (isLoaded(submittedTimesheets) && submittedTimesheets) {
    Object.values(submittedTimesheets).forEach((item) => {
      console.log(item);
      if (typeof item === "object") {
        defaulterRanges +=
          "defaulterRanges" in item ? item.defaulterRanges.length : 0;
      }
    });
  }
  // if (defaulterRanges > 0 && state.type === "new")
  //   return <Alert severity="warning">You have pending defaulter timesheets to submit. Please submit and come back again.</Alert>

  if (isLoaded(submittedTimesheets))
    return (
      <div>
        <Presentation
          loggedInEmployee={loggedInEmployee}
          placementID={state.placementID}
          modules={modules}
          {...props}
        />
      </div>
    );
  return "Loading...";
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    submittedTimesheets: state.firestore.data.submittedTimesheets,
    modules: state.employee.employeeModules.accessModules,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (
      props.modules.includes("timesheets") ||
      props.modules.includes("console-customization")
    )
      return [
        {
          collection: "META_INFO",
          doc: "timesheets",
          subcollections: [
            {
              collection: "TRACK_SUBMISSIONS",
              where: ["employeeID", "==", props.loggedInEmployee],
              storeAs: "submittedTimesheets",
            },
          ],
          storeAs: "submittedTimesheets",
        },
      ];
    return [];
  })
)(Wrapper(ContextProvider, Container));
