import React, { useState } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import MetaInfo from "../../../../shared/getMetaInfo";
import parser from "html-react-parser";
import validate from "../../../../shared/validation";
import { connect } from "react-redux";
import { getPlacementsAudit } from "../../middleware/index";
import { IconButton, AppBar, Dialog, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "../../../../shared/styles/dialogStyles";
import { Button } from "react-bootstrap";

function PlacementAudit({ modules }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstDocID, setFirstDocID] = useState(null);
  const [lastDocID, setLastDocID] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const metaInfo = new MetaInfo();
  const columns = [
    {
      title: "Action by",
      field: "actionbyName",
      render: (rowData) => {
        if (modules.includes("console-customization")) {
          return (
            <Link to={"/console/employees/" + rowData.actionby}>
              {rowData.actionbyName}
            </Link>
          );
        } else {
          return <Link to={"/console/profile"}>{rowData.actionbyName}</Link>;
        }
      },
    },
    { title: "Timestamp", field: "timestamp" },
    {
      title: "History",
      field: "history",
      render: (rowData) => formatSubject(rowData),
    },
  ];

  function formatSubject(rowData) {
    const { type, historyObj } = rowData;
    switch (type) {
      case "timesheetUpdated":
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>Updated Timesheet .</span>;
        }
      case "timesheetSubmitted":
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>Submitted Timesheet .</span>;
        }
      case "timesheetApproved":
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>Approved Timesheet.</span>;
        }
      case "timesheetRejected":
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>Rejected Timesheet .</span>;
        }
      case "timesheetHoursUpdated":
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>Timesheet working hours updated .</span>;
        }
      case "updateTimesheetDetailsinPlacement":
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>Updated Timesheet Details in placement.</span>;
        }
      default:
        if ("actionDesc" in historyObj) {
          return <span>{parser(historyObj.actionDesc)}</span>;
        } else {
          return <span>{type}</span>;
        }
    }
  }

  const getData = async (query) => {
    let { pageSize, page } = query;
    let isPageZero = false,
      isNextPage = false,
      isPrevPage = false;
    if (page === 0) {
      isPageZero = true;
      setRowsPerPage(pageSize);
    } else {
      if (page > pageNumber) {
        isNextPage = true;
      } else {
        isPrevPage = true;
      }
    }

    const historyData = await getPlacementsAudit(
      isPageZero,
      isNextPage,
      isPrevPage,
      page,
      pageSize,
      firstDocID,
      lastDocID
    );
    setFirstDocID(historyData.firstDocID);
    setLastDocID(historyData.lastDocID);
    setPageNumber(historyData.page);

    let data =
      historyData?.data &&
      historyData?.data
        ?.map((history) => {
          return {
            actionby: history.actionBy,
            actionbyName: metaInfo.emailToName(history.actionBy),
            timestamp:
              history.createdAt === ""
                ? history.createdAt
                : validate.dateAndTimeFormatter(history.createdAt),
            type: history.type,
            subject: Object.values(history.subject),
            historyObj: history,
          };
        })
        .filter(function (item) {
          return item.historyObj.actionDesc !== "";
        });

    return { data, page, totalCount: Number.MAX_SAFE_INTEGER };
  };

  const localization = {
    pagination: {
      labelDisplayedRows: `Page: ${pageNumber}`,
    },
  };

  return (
    <div>
      <Button
        className="btn btn-success px-4 btn-md text-high"
        onClick={(e) => {
          e.preventDefault();
          handleClickOpen();
        }}
      >
        Placement Audit
      </Button>
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                style={{ color: "whitesmoke" }}
              >
                Placement Audit
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <MaterialTable
          title=""
          columns={columns}
          data={(query) => getData(query)}
          localization={localization}
          options={{
            filtering: false,
            columnsButton: false,
            showFirstLastPageButtons: false,
            pageSize: rowsPerPage,
            paginationPosition: "bottom",
            paginationType: "normal",
            pageSizeOptions: [10, 20, 50, 100, 250],
            padding: "dense",
            rowStyle: (_, index) =>
              index % 2 === 0 ? { background: "#f5f5f5" } : null,
            emptyRowsWhenPaging: false,
            search: false,
          }}
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    modules: state.employee.employeeModules.accessModules,
  };
};

export default connect(mapStateToProps, null)(PlacementAudit);
