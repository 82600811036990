import React, { useState } from "react";
import MetaInfo from "../../../shared/getMetaInfo";
import SunEditor from "suneditor-react";
import AppModalHeader from "../../../shared/components/Modals/AppModalHeader";
import { Modal } from "react-bootstrap";
import { Field, Formik, Form } from "formik";
import DateFormField from "../../../shared/components/BootStrapFormFields/DateFormField";
import AppModalFooter from "../../../shared/components/Modals/AppModalFooter";
import * as Yup from "yup";

const schema = Yup.object().shape({
	dateOfSuspension: Yup.string().required("Date is required"),
	reasonForSuspension: Yup.string()
		.required("Reason for suspension is required")
		.test("html", "Reason for suspension is required", (value) => {
			if (value) {
				const regex = /<p><br><\/p>/g;
				const result = value.replace(regex, "");
				return result.length > 0;
			}
			return false;
		}),
});


export default function SuspendEmployee(props) {
  const {
    onSuspend,
    employeeID,
    isEdit = false,
    index,
    suspensionReason ,
    suspensionDate,
    ClickableContent,
    onUpdate,
  } = props;
  const [open, setOpen] = useState(false);
  const metaInfo = new MetaInfo();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const suspendTheEmployee = (values, actions) => {
    const status = metaInfo.getStatus(employeeID);
    const payload = {
      dateOfSuspension: values.dateOfSuspension,
      reasonForSuspension: values.reasonForSuspension,
      employeeID: employeeID,
      employeeStatus: status,
    };
    if (isEdit) {
      onUpdate(payload, index);
    } else {
      onSuspend(payload);
    }
    handleClose();
    console.log({ payload })
  };

  return (
    <div>
      {isEdit ? (
        <ClickableContent openDialog={handleClickOpen} />
      ) : (
        <button
          className="btn btn-danger rounded-pill px-2 ms-2 mt-1"
          onClick={handleClickOpen}
        >
          Exit this employee
        </button>
      )}
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          validateOnMount
          validateOnChange
          initialValues={{
            dateOfSuspension: suspensionDate,
            reasonForSuspension: suspensionReason,
          }}
          onSubmit={suspendTheEmployee}
          validationSchema={schema}
        >
          {(formik) => {
            return (
              <Form>
                <AppModalHeader
                  title={
                    isEdit
                      ? "Do you want to update the exit note?"
                      : "Do you want to exit this employee?"
                  }
                />
                <Modal.Body>
                  {isEdit ? (
                    <></>
                  ) : (
                    <Field
                      name="dateOfSuspension"
                      type="date"
                      component={DateFormField}
                      label="Exit Date *"
                    />
                  )}
                  <Field
                    name="reasonForSuspension"
                    component={ReasonField}
                    label="Reason Note"
                    type="text"
                  />
                </Modal.Body>
                <AppModalFooter
                  saveButtonText={isEdit ? "Update" : "Exit employee"}
                  saveButtonDisabled={!formik.isValid || formik.isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

function ReasonField({ field, form, required, ...props }) {
  const [editorData, setEditorData] = useState('')

  return (
    <SunEditor
      placeholder="Exit Note"
      setContents={editorData}
      onChange={(data) => {
        setEditorData(data)
        return form.setFieldValue("reasonForSuspension", data, true);
      }}
      height={200}
      setOptions={{
        buttonList: [
          [
            "undo",
            "redo",
            "font",
            "fontSize",
            "formatBlock",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "removeFormat",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
            "table",
            "link",
            "image",
            "video",
            "showBlocks",
            "codeView",
          ],
        ],
      }} />
  )
}