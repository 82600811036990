import { useQuery } from "@tanstack/react-query";
import { getIn } from "formik";
import React, { useEffect } from "react";
import { loadCountries, loadStates } from "../../../api/services/default/geo";
import { Form } from "react-bootstrap";
import Select from "react-select";

export default function StateField({
	field,
	form,
	required,
	country,
	...props
}) {
	const [selectedValue, setSelectedValue] = React.useState(field.value);

	const errorText =
		getIn(form.touched, field.name) && getIn(form.errors, field.name);

	const { data: countries } = useQuery({
		queryKey: ["countries"],
		queryFn: () => loadCountries(),
	});

	const countryObj = countries?.find((res) => res.name === country);

	const countryCode = form.values.isoCode || countryObj?.iso2;

	const { data, isLoading } = useQuery({
		queryKey: ["state", countryCode],
		queryFn: () => loadStates(countryCode),
		enabled: Boolean(countryCode) && countries?.length > 0,
	});

	const options =
		data?.map((item) => ({
			value: item.name,
			label: item.name,
		})) || [];

	if (!isLoading && data.length === 0) {
		options.push({
			value: "None",
			label: "None",
		});
	}

	useEffect(() => {
		setSelectedValue(field.value);
	}, [field.value]);

	return (
		<Form.Group className="mb-3">
			{props.label && <Form.Label>{props.label}</Form.Label>}
			<Select
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: "#ced4da",
					},
				})}
				value={options?.find((res) => res.value === selectedValue) || null}
				options={options}
				onBlur={() => form.setFieldTouched(field.name, true, true)}
				onChange={(option) =>
					form.setFieldValue(field.name, option.value, true)
				}
				name={field.name}
				closeMenuOnSelect={true}
				escapeClearsValue={true}
			/>
			{errorText && <Form.Text className="text-danger">{errorText}</Form.Text>}
		</Form.Group>
	);
}
