import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { uploadToStorage } from "../../fileUploader";
import { errorMsg } from "../../SnackBars/index";
import { getIn } from "formik";

export const FileUploadForm = ({
	field,
	form,
	required,
	employeeId,
	sectionKey,
	label,
	fileName,
	storageDir,
	...props
}) => {
	const errorText =
		getIn(form.touched, field.name) && getIn(form.errors, field.name);

	const [spin, setSpin] = useState(false);
	const [fileURL, setURL] = useState(field.value);

	const handleFile = async (e) => {
		const file = e.target.files[0];
		let _filePath, _fileName;

		if (sectionKey && employeeId) {
			fileName = [employeeId, "_", sectionKey, "_", new Date().toISOString()].join("");
			storageDir = `EMPLOYEES/${employeeId}/${sectionKey.toUpperCase()}`;
		}

		if (fileName) {
			_fileName = fileName;
		} else {
			_fileName = file.name;
		}

		if (storageDir) {
			_filePath = [storageDir, "/", Date.now(), _fileName].join("");
		} else {
			_filePath = [Date.now(), _fileName].join("");
		}

		setSpin(true);
		return uploadToStorage(file, _filePath, _fileName, "file")
			.then((url) => {
				form.setFieldValue(field.name, url, true);
				setSpin(false);
				setURL(url);
			})
			.catch((err) => {
				console.log(err);
				errorMsg("Failed to upload " + label);
				form.setFieldValue(field.name, "", true);
				setSpin(false);
				setURL("");
			});
	};

	return (
		<div>
			<Form.Group className="mb-3">
				<Form.Label>{label}</Form.Label>
				<Form.Control
					type="file"
					onChange={handleFile}
					onBlur={() => form.setFieldTouched(field.name, true, true)}
					isInvalid={Boolean(errorText)}
				/>
				<Form.Control.Feedback type="invalid">
					{errorText}
				</Form.Control.Feedback>
			</Form.Group>
			{spin ? (
				<Button variant="primary" disabled>
					<Spinner
						as="span"
						animation="border"
						size="sm"
						role="status"
						aria-hidden="true"
					/>
					<span className="visually-hidden">Loading...</span>
				</Button>
			) : null}
			{fileURL ? (
				<a rel="noreferrer" target="_blank" href={fileURL}>
					{"Link"}
				</a>
			) : (
				""
			)}
		</div>
	);
};
export default FileUploadForm;
