import React, { useState, useReducer, useContext } from 'react';
import firebase from "../../../../config/fbConfig"
import make_API_call from "../../../../providers/REST_API"
import { CalendarFormatter } from '../../../../shared/CalendarFormatter';
import { uploadToStorage } from '../../../../shared/fileUploader';
import { successMsg, errorMsg } from "../../../../shared/SnackBars/index"
import Accounts from '../../../../utils/Accounts';

const BulkPaymentsContext = React.createContext();
const useBulkPaymentsContext = () => useContext(BulkPaymentsContext);


const bulkPaymentsInitialState = {
  showLoader: false,
  clients: [],
  invoicesList: [],
  selectedClientId: '',
  paymentAmount: 0,
  openBalance: 0,
  amountLinked: 0,
  unlinkedAmount: 0,
  payDivisions: {},
  paymentDate: CalendarFormatter.standardDateFormat(new Date()),
  paymentMethod: "",
  referenceNumber: "",
  attachment: {
    sourcePath: "",
    publicURL: "",
  },
  isUploading: false,
  noReference: false,
  discountDetails: [
    {
      name: "",
      value: 0,
      type: "",
    },
  ],
  discountDivisions: {},
  allPayments: [],
  paymentsMade: [],
  totalPaymentsMadeList: [],
  totalBulkPayments: [],
  bulkPaymentHistory: []
}

const BulkPaymentsContextProvider = (props) => {

  const [clients, setClients] = useState(bulkPaymentsInitialState.clients);
  const [invoicesList, setInvoicesList] = useState(bulkPaymentsInitialState.invoicesList);
  const [showLoader, setShowLoader] = useState(bulkPaymentsInitialState.showLoader)
  const [selectedClientId, setSelectedClientId] = useState(bulkPaymentsInitialState.selectedClientId)
  const [openBalance, setOpenBalance] = useState(bulkPaymentsInitialState.openBalance)
  const [amountLinked, setAmountLinked] = useState(bulkPaymentsInitialState.amountLinked)
  const [unlinkedAmount, setUnlinkedAmount] = useState(bulkPaymentsInitialState.unlinkedAmount)
  const [payDivisions, setPayDivisions] = useState(bulkPaymentsInitialState.payDivisions)
  const [totalPaymentsMadeList, setTotalPaymentsMadeList] = useState(bulkPaymentsInitialState.totalPaymentsMadeList)
  const [totalBulkPayments, setTotalBulkPayments] = useState(bulkPaymentsInitialState.totalBulkPayments)
  const [bulkPaymentHistory, setBulkPaymentHistory] = useState(bulkPaymentsInitialState.bulkPaymentHistory)

  // payment component
  const [paymentAmount, setPaymentAmount] = useState(bulkPaymentsInitialState.paymentAmount)
  const [paymentDate, setPaymentDate] = useState(bulkPaymentsInitialState.paymentDate)
  const [paymentMethod, setPaymentMethod] = useState(bulkPaymentsInitialState.paymentMethod)
  const [referenceNumber, setReferenceNumber] = useState(bulkPaymentsInitialState.referenceNumber)
  const [attachment, setAttachment] = useState(bulkPaymentsInitialState.attachment)
  const [isUploading, setIsUploading] = useState(bulkPaymentsInitialState.isUploading)
  const [noReference, setNoReference] = useState(bulkPaymentsInitialState.noReference)
  const [discountDetails, setDiscountDetails] = useState(bulkPaymentsInitialState.discountDetails)
  const [discountDivisions, setDiscountDivisions] = useState(bulkPaymentsInitialState.discountDivisions)
  const [allPayments, setAllPayments] = useState(bulkPaymentsInitialState.allPayments)

  // edit bulk payment
  const [paymentsMade, setPaymentsMade] = useState(bulkPaymentsInitialState.paymentsMade)


  const state = {
    clients,
    invoicesList,
    showLoader,
    selectedClientId,
    openBalance,
    amountLinked,
    unlinkedAmount,
    payDivisions,
    paymentAmount,
    paymentDate,
    paymentMethod,
    referenceNumber,
    attachment,
    isUploading,
    noReference,
    discountDetails,
    discountDivisions,
    allPayments,
    paymentsMade,
    totalPaymentsMadeList,
    totalBulkPayments,
    bulkPaymentHistory
  }

  const stateSetters = {
    setClients,
    setInvoicesList,
    setSelectedClientId,
    setOpenBalance,
    setAmountLinked,
    setUnlinkedAmount,
    setPayDivisions,
    setPaymentAmount,
    setPaymentDate,
    setPaymentMethod,
    setReferenceNumber,
    setAttachment,
    setIsUploading,
    setNoReference,
    setDiscountDetails,
    setDiscountDivisions,
    setAllPayments,
    setPaymentsMade,
    setTotalPaymentsMadeList,
    setTotalBulkPayments,
    setBulkPaymentHistory
  }

  const clearState = () => {
    Object.entries(stateSetters).forEach(([stateSetKey, stateSetFunc]) => {
      let theKey = stateSetKey.replace("set", "")
      theKey = theKey.charAt(0).toLowerCase() + theKey.slice(1)
      stateSetFunc(bulkPaymentsInitialState[theKey])
    })
  }

  const getClients = async () => {
    try {
      setShowLoader(true);
      const data = (await firebase.firestore().collection("CLIENTS").get()).docs.map((d) => d.data());
      setClients(data);
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load clients');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Bulk Payments',
        functionName: 'getClients',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getInvoicesByClient = async () => {
    try {
      setShowLoader(true);
      const data = (await firebase.firestore().collection("INVOICES")
        .where("clientID", "==", selectedClientId)
        .where("isVoid", "==", false)
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isExist", "==", true)
        .get())
        .docs.map(d => d.data())
      setInvoicesList(data);
      const amount = data.reduce((init, item) => init + (item.grandTotal - (item?.receivedAmount || 0) - (item?.paymentDiscountAmount || 0)), 0)
      const finalAmount = Accounts.roundOffAmount(amount)
      setOpenBalance(Number(finalAmount))
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg('Failed to load clients invoices');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Bulk Payments',
        functionName: 'getinvoicesList',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setIsUploading(true)
      const fileName = `${referenceNumber}`
      const filePath = `Payments/Bulk/${fileName}`
      uploadToStorage(file, filePath, fileName, "file")
        .then((url) => {
          setAttachment({
            publicURL: url,
            sourcePath: `${filePath}.${file.name.split(".")[1]}`,
          })
          setIsUploading(false)
        })
        .catch((err) => {
          console.error(err)
          setIsUploading(false)
        })
    }
  }

  const calculateDiscount = (subTotal, discountArr) => {
    return Number(discountArr.reduce((initial, item) => {
      if (item.type === "byValue") {
        return initial - Number(item.value);
      } else if (item.type === "byPercentage" && Number(item.value) > 0) {
        return initial - Number((subTotal * Number(item.value)) / 100);
      } else {
        return initial - 0;
      }
    }, 0).toFixed(2))
  }

  const handleBulkPayment = async () => {
    try {
      setShowLoader(true);
      const payload = {
        paymentPayload: {
          paymentType: state.paymentMethod,
          referenceNumber: state.referenceNumber,
          attachmentDetails: {
            sourcePath: state.attachment.sourcePath,
            publicURL: state.attachment.publicURL,
          },
          paymentDate: state.paymentDate,
          noReference: state.noReference,
          totalPaymentAmount: state.paymentAmount
        },
        payDivisions: Object.entries(payDivisions).reduce((init, [key, val]) => ({ ...init, [key]: Number(val) }), {}),
        discountDivisions: state.discountDivisions,
        clientID: state.selectedClientId
      }

      await make_API_call("post", `/invoices/bulk-payment`, payload)
      successMsg(`Bulk payment made successfully`)
      clearState()
      setShowLoader(false);
    } catch (error) {
      errorMsg(error?.message || `Failed to make bulk Payment`)
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Invoices - Bulk payment',
        functionName: 'handleBulkPayment'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const loadAllPayments = async () => {
    try {
      setShowLoader(true);
      const data = (await firebase.firestore().collectionGroup('PAYMENTS_HISTORY').orderBy('createdAt', 'desc').get()).docs.map(d => d.data())
      setAllPayments(data)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      setShowLoader(false);
      errorMsg(error?.message || `Failed to load payments`)
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Invoices - Load all payments',
        functionName: 'loadAllPayments'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const editBulkPayment = async (bulkPaymentId) => {
    try {
      setShowLoader(true);
      // bulk payment info
      const bulkPaymentInfo = (await firebase.firestore().collection('BULK_PAYMENTS').doc(bulkPaymentId).get()).data()
      let invoices = (await firebase.firestore().collection("INVOICES")
        .where('clientID', '==', bulkPaymentInfo.clientID)
        .where("isVoid", "==", false)
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isExist", "==", true)
        .get()).docs.map((d) => d.data());

      const paymentDoneInvoices = (await firebase.firestore().collection("INVOICES")
        .where('clientID', '==', bulkPaymentInfo.clientID)
        .where("isPaymentDone", "==", true)
        .where("isExist", "==", true)
        .get()).docs.map((d) => d.data());
      // get payments
      const paymentsMadeList = (await firebase.firestore().collectionGroup("PAYMENTS_HISTORY").where("bulkPaymentId", "==", bulkPaymentId).get()).docs.map(d => d.data())
      setPaymentsMade(paymentsMadeList)
      const thePayDivisions = paymentsMadeList.reduce((init, item) => {
        return {
          ...init,
          [item.invoiceID]: item.paymentAmount
        }
      }, {})

      const paymentDoneInvoicesRelatedToClient = paymentDoneInvoices.filter(item => Object.keys(thePayDivisions).includes(item.id))
      const theDiscountDivisions = paymentsMadeList.reduce((init, item) => ({ ...init, [item.invoiceID]: item.discountDetails }), {})
      invoices = [...invoices, ...paymentDoneInvoicesRelatedToClient]

      let modifiedInvoicesList;
      Object.entries(thePayDivisions).forEach(([invoiceID, payAmount]) => {
        modifiedInvoicesList = invoices.map((item) => {
          if (item.id === invoiceID) {
            // item.grandTotal += Number(payAmount)
            item.receivedAmount -= Number(payAmount)
            item.payAmount = Number(payAmount)
            return item
          }
          return item
        })
      })

      Object.entries(theDiscountDivisions).forEach(([invoiceID, discount]) => {
        modifiedInvoicesList = modifiedInvoicesList.map((item) => {
          if (item.id === invoiceID) {
            // item.grandTotal += Number(payAmount)
            item.paymentDiscountAmount += calculateDiscount(item.grandTotal, discount)
            return item
          }
          return item
        })
      })

      // sort modified invoices list by payAmount
      modifiedInvoicesList = modifiedInvoicesList.sort((a, b) => (b?.payAmount ?? 0) - (a?.payAmount ?? 0))
      setInvoicesList(modifiedInvoicesList)
      setPayDivisions(thePayDivisions)
      setSelectedClientId(bulkPaymentInfo.clientID)
      setPaymentAmount(bulkPaymentInfo.paymentAmount)
      setReferenceNumber(bulkPaymentInfo.referenceNumber)
      setNoReference(bulkPaymentInfo.noReference ?? !!!bulkPaymentInfo.referenceNumber)
      setAttachment(bulkPaymentInfo.attachmentDetails)
      setPaymentMethod(bulkPaymentInfo.paymentType)
      setPaymentAmount(bulkPaymentInfo.paymentAmount)
      setPaymentDate(bulkPaymentInfo.paymentDate)
      setDiscountDivisions(theDiscountDivisions)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg('Failed to load bulk payment details')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Invoices - Edit',
        functionName: 'editBulkPayment'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const handleUpdateBulkPayment = async (bulkPaymentId) => {
    try {
      setShowLoader(true);
      // in paymentIds object, if there is no payment id related to an invoice then it implies that it is a new payment
      const payload = {
        paymentPayload: {
          paymentType: state.paymentMethod,
          referenceNumber: state.referenceNumber,
          attachmentDetails: {
            sourcePath: state.attachment.sourcePath,
            publicURL: state.attachment.publicURL,
          },
          paymentDate: state.paymentDate,
          noReference: state.noReference,
          totalPaymentAmount: state.paymentAmount
        },
        payDivisions: Object.entries(payDivisions).reduce((init, [key, val]) => ({ ...init, [key]: Number(val) }), {}),
        discountDivisions: state.discountDivisions,
        bulkPaymentId,
        paymentIds: Object.keys(payDivisions).reduce((init, invoiceID) => {
          return {
            ...init,
            [invoiceID]: paymentsMade.filter((p) => p.invoiceID === invoiceID)?.[0]?.id || ''
          }
        }, {})
      }

      await make_API_call("post", `/invoices/update-bulk-payment`, payload)
      successMsg(`Bulk payment updated successfully`)
      clearState()
      setShowLoader(false);
    } catch (error) {
      errorMsg(error?.message || `Failed to update bulk Payment`)
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Invoices - Update Bulk payment',
        functionName: 'handleUpdateBulkPayment'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const checkUnlinkedAmount = async () => {
    try {
      setShowLoader(true);
      const theTotalBulkPayments = (await firebase.firestore().collection('BULK_PAYMENTS').where('clientID', '==', selectedClientId).get()).docs.map(d => d.data())
      const totalPaymentsMadeList = (await firebase.firestore().collectionGroup("PAYMENTS_HISTORY").where("clientID", "==", selectedClientId).get()).docs.map(d => d.data())
      setTotalPaymentsMadeList(totalPaymentsMadeList)
      setTotalBulkPayments(theTotalBulkPayments)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg('Failed to check unlinked amount')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Bulkpayments - New',
        functionName: 'checkUnlinkedAmount'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const loadBulkPaymentHistory = async (bulkPaymentId) => {
    try {
      setShowLoader(true);
      const data = (await firebase.firestore().collection(`BULK_PAYMENTS/${bulkPaymentId}/BULK_PAYMENT_HISTORY`).get()).docs.map(d => d.data())
      setBulkPaymentHistory(data)
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      errorMsg('Failed to get bulk payment history')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Bulkpayments - History',
        functionName: 'loadBulkPaymentHistory'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }


  const services = {
    getClients,
    getInvoicesByClient,
    calculateDiscount,
    handleFile,
    handleBulkPayment,
    loadAllPayments,
    editBulkPayment,
    handleUpdateBulkPayment,
    clearState,
    checkUnlinkedAmount,
    loadBulkPaymentHistory
  }

  return (
    <BulkPaymentsContext.Provider value={{ state, stateSetters, services }}>
      {props.children}
    </BulkPaymentsContext.Provider>
  );
};

const BulkPaymentsContextContextProvider = (props) => <div key={Date.now()} ><BulkPaymentsContextProvider>{props.children}</BulkPaymentsContextProvider></div>

export { BulkPaymentsContextContextProvider, useBulkPaymentsContext }