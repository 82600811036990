import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

function FlairTable({
  data, 
  columns, 
  isLoading, 
  customizedGridOptions,
  getRowHeight,
  isRowSelectable,
  onSelectionChange,
  onCellClicked,
  rowClassRules,
  actions = [], 
  otherAction = {}, 
  tableRef = null,
  onSearchChange = () => { }, 
  onFilterChange = () => { },
  filtering = true, 
  pivotMode = false,
  hideSearch = false,
}) {
  const [searchText, setSearchText] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();

  const getColumnDefs = () => convertMaterialToAgGridColumns(columns)

  const [columnDefs, setColumnDefs] = useState(getColumnDefs());

  const onGridReady = useCallback(params => {
    gridRef.current = params.api;
    if (tableRef) {
      tableRef.current = params.api;
    }
    setGridApi(params.api);
  }, []);

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  const onFilterChanged = useCallback(event => {
    onFilterChange(event.api.getFilterModel());
  }, [onFilterChange]);

  const onSearchTextChanged = useCallback(
    (event) => {
      setSearchText(event.target.value);
    },
    []
  );

  const filteredData = useMemo(() => {
    if (!searchText) {
      return data;
    }

    return data.filter((row) =>
      Object.values(row).some(
        (cellValue) =>
          String(cellValue)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
      )
    );
  }, [data, searchText]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      pivotMode: pivotMode,
    };
  }, [pivotMode]);

  const sidebarOptions = useMemo(() => {
    return [
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      }
    ]
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
    ],
    };
  }, []);

  const gridOptions = {
    autoSizeColumns: true
  };

  const renderTableActions = () => {
    let actionsTobeRendered = []
    if (typeof actions === "object" && Object.keys(actions).length > 0) {
      actionsTobeRendered.push(actions)
    }
    if (typeof otherAction === "object" && Object.keys(otherAction).length > 0) {
      actionsTobeRendered.push(otherAction)
    }
    if (Array.isArray(actions) && actions.length > 0) {
      actionsTobeRendered = [...actionsTobeRendered, ...actions]
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='mb-1'>
        {actionsTobeRendered.map(({ icon, onClick = () => { } }, index) => (
          <span onClick={onClick} key={index} style={{ margin: '0 8px' }}>
            {icon()}
          </span>
        ))}
      </div>
    )
  }

  return (
    <div style={{ height: window.innerHeight * 0.75 + "px", width: '100%' }}>
      <div className='d-flex justify-content-between'>
        {
          !hideSearch && (
            <div>
            <input
              type="text"
              placeholder="Search"
              className="form-control mr-2"
              style={{ width: "400px" }}
              onInput={onSearchTextChanged}
            />
          </div>
          )
        }
        {renderTableActions()}
      </div>
      <div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          ref={tableRef || gridRef}
          rowData={filteredData || []}
          rowHeight={33}
          getRowHeight={getRowHeight}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          animateRows={true}
          sideBar={{
            toolPanels: sidebarOptions.filter(option => pivotMode || option.id !== 'columns'),
            defaultToolPanel: 'filters',
          }}
          enableRangeSelection={true}
          statusBar={statusBar}
          rowSelection='multiple'
          gridOptions={customizedGridOptions ? customizedGridOptions : gridOptions}
          onSelectionChanged={onSelectionChange}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          onCellClicked={onCellClicked}
          rowClassRules={rowClassRules}
        />
      </div>
    </div>
  )
}

export default FlairTable;

export function convertMaterialToAgGridColumns(materialColumns) {
  return materialColumns.map((column) => { 
    const agColumn = {
      headerName: column.title,
      field: column.field,
      children: column.children,
      columnGroupShow: column.columnGroupShow,
      cellStyle: column?.cellStyle,
      headerClass: column?.headerClass,
      checkboxSelection: column?.checkboxSelection,
      headerCheckboxSelection: column?.headerCheckboxSelection,
      headerCheckboxSelectionFilteredOnly: column?.headerCheckboxSelectionFilteredOnly,
      showDisabledCheckboxes: column?.showDisabledCheckboxes,
      hide: column?.hide,
    };
    
    if (column.dataType === "Number") {
      agColumn.type = "numericColumn";
    } else if (column.dataType === "Date") {
      agColumn.type = "dateColumn";
    } else {
      agColumn.type = "textColumn";
    }

    if (column.width) {
      agColumn.width = column.width;
    }

    if (column.render && column.render instanceof Function) {
      agColumn.cellRendererFramework = ({ data }) => {
        return (
          <div>
            {column.render(data)}
          </div>
        );
      };
    } else {
      agColumn.cellRenderer = ({ data }) => {
        return (
          <div>
            {data?.[column?.field]}
          </div>
        );
      };
    }

    if (column.lookup && column.lookup instanceof Object) {
      agColumn.cellEditor = "agSelectCellEditor";
      agColumn.cellEditorParams = {
        values: column.lookup,
      };
      agColumn.valueGetter = (params) => {
        const rowData = params.data;
        const lookupValue = column?.lookup?.[rowData?.[column.field]];
        return lookupValue ?? "";
      };
      agColumn.cellRenderer = ({ data, value }) => {
        if (column?.render)
          return (
            <div>
              {column.render(data)}
            </div>
          )
        return (
          <div>
            {column?.lookup?.[value] || value}
          </div>
        );
      };
    }

    return agColumn;
  });
}
