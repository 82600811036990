import React,{useEffect} from "react";
import { AnnouncementsContextProvider,useAnnouncementContext } from "./context";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import AddAnnouncement from "./AddAnnouncement";
import validate from "../../shared/validation"
import { Link } from "react-router-dom";
import FlairTable from "../../shared/FlairTable"

function AnnouncementsComponent(props) {
  const { state,services } = useAnnouncementContext();

  useEffect(() => {
    services.getAllAnnouncements()
  }, [])

  const columns = [
    {
      title: "Subject",
      field: "subject",
      dataType: "",
      filtering:false,
      width:500,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <Link to={"/console/Announcements/" + rowData.id + "?subject=" + rowData.subject }>{rowData.subject}</Link>
              <Typography className="d-flex" variant="caption" color="textSecondary">
              CreatedOn:{" "}
              <em className="mr-1">
                {validate.dateAndTimeFormatter(rowData.createdAt)}
              </em></Typography>
            </div>
        )
      },
    },
    {
      title: "Announced to",
      field: "announceTo",
      dataType: "",
      filtering:false,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div className="text-center">Announced to</div>
              <div className="text-center">{[...new Set([...(rowData?.announceTo) || [],...(rowData?.acknowledged) || [],...(rowData?.declined) || []])].length}</div>
            </div>
        )
      },
    },
    {
      title: "Acknowledged By",
      field: "acknowledged",
      dataType: "",
      filtering:false,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div className="text-center" style={{display:"flex",flexDirection:"column"}}>Acknowledged By</div>
              <div className="text-center">{rowData.acknowledged.length}</div>
            </div>
        )
      },
    },
    {
      title: "Declined By",
      field: "declined",
      dataType: "",
      filtering:false,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div className="text-center" style={{display:"flex",flexDirection:"column"}}>Declined By</div>
              <div className="text-center">{rowData.declined.length}</div>
            </div>
        )
      },
    }
  ]

  const rows = state?.allAnnouncements?.map((announcement) => {
    return {
      id:announcement.id,
      subject:announcement.subject,
      createdAt:announcement.createdAt,
      announceTo:announcement.announceTo,
      acknowledged:announcement.acknowledged,
      declined:announcement.declined
    }
  })

  const AddAnnouncementAction = {
    icon:()=> <AddAnnouncement />,
    tooltip: "Add Annoucement",
    isFreeAction: true,
    position: "toolbar",
  }

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <FlairTable
        title=""
        data={rows}
        columns={columns}
        actions={AddAnnouncementAction}
      />
    </>
  );
}

const AllAnnouncements = (props) => <AnnouncementsContextProvider><AnnouncementsComponent {...props} /> </AnnouncementsContextProvider>
export default AllAnnouncements