import React from "react"
import {
  IconButton,
  AppBar,
  Dialog,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core"
import { Close as CloseIcon, History as VisibilityIcon } from "@material-ui/icons"
import validate from "../../../../../shared/validation"
import CustomTable from "../../../../../shared/customTable"
import useStyles from "../../../../../shared/styles/dialogStyles"
import MetaInfo from "../../../../../shared/getMetaInfo"
import Popover from "../../../../../shared/components/Popover"
import { AiOutlineNumber } from "react-icons/ai"
import { RiPercentFill } from "react-icons/ri"

function InvoiceTimeline(props) {
  const { invoiceID } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const metaObj = new MetaInfo()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }


  let columns = [
    { title: "Date/ Time", field: "paymentDate" },
    {
      title: "Description", field: "description",
      render: (row) => {
        const renderContent = () => {
          return (
            <div >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      row.discountDetails.map((discount, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <span>
                                {discount.name}
                              </span>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {
                                <span className="custom-chip chip-success" >
                                  {
                                    discount.type === "byValue" ?
                                      <AiOutlineNumber size={18} />
                                      :
                                      <RiPercentFill size={18} />
                                  }

                                </span>
                              }

                            </TableCell>
                            <TableCell align="right">{discount.value}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          )
        }
        return (
          <div style={{ display: 'flex' }}>
            <div>

              {
                row.actionType === "madePayment" && row.discountDetails.length ?
                  <div>

                    <Popover
                      isInfo={true}
                      RenderContent={renderContent}
                    /></div>
                  :
                  null
              }</div>

            &nbsp;
            <div style={{ marginTop: '3px' }}> {row.description}</div>
          </div>


        )
      }
    },
    // { title: "Received Amount", field: "receivedAmount" },
    // { title: "Discount Name", field: "name" },
    // { title: "Discount Type", field: "type" },
    // { title: "Discount Value", field: "value" },
    {
      title: "Attachment",
      field: "values",
      render: (row) =>
        row.attachedFile !== "" ? (
          <a
            className="custom-chip text-underline c-pointer"
            target="_blank"
            href={row.attachedFile.publicURL}
          >{`${row.attachedFile.sourcePath}`}</a>
        ) : (
            ""
          ),
    },
    { title: "Action By", field: "actionBy" },
  ]
  let data = []
  // isLoaded &&
  //   history &&
  //   history.forEach((doc) => {
  //     let { paymentDate, receivedAmount, discountDetails, otherInfo, actionBy } = doc
  //     let discountedValue = 0
  //     if (!Array.isArray(discountDetails)) {
  //       discountDetails = [discountDetails]
  //     }
  //     discountDetails.forEach(item => {
  //       const paymentAmount = Number(receivedAmount)
  //       if (item.type === "byPercentage")
  //         discountedValue += Number((paymentAmount * item.value) / 100)
  //       else discountedValue += item.value
  //     })

  //     data.push({
  //       paymentDate: validate.dateAndTimeFormatter(paymentDate),
  //       receivedAmount: validate.currencyFormatterUs(receivedAmount),
  //       name: discountDetails.name ? discountDetails.name : "--",
  //       value:
  //         discountDetails.type === "byValue"
  //           ? validate.currencyFormatterUs(discountedValue)
  //           : discountDetails.type === "byPercentage"
  //             ? `${validate.currencyFormatterUs(discountedValue)}(${discountDetails.value
  //             })`
  //             : "--",
  //       type: discountDetails.type
  //         ? validate.nameFormatterToUpperCase(discountDetails.type)
  //         : "--",
  //       actionType: "madePayment",
  //       attachedFile: otherInfo.attachmentDetails,
  //       description: getInformationBasedOnDataChange({ ...doc, type: "madePayment", discountedValue }),
  //       actionBy: metaObj.emailToName(actionBy),
  //       discountDetails
  //     })
  //   })

  // if ((!state.invoiceHistory.isLoading && state.invoiceHistory.data.length)) {
  //   state.invoiceHistory.data.forEach(item => {
  //     const { createdAt, actionBy, discountDetails, otherInfo, type } = item
  //     data.push({
  //       paymentDate: validate.dateAndTimeFormatter(createdAt),
  //       // value:
  //       //   discountDetails.type === "byValue"
  //       //     ? validate.currencyFormatterUs(discountedValue)
  //       //     : discountDetails.type === "byPercentage"
  //       //       ? `${validate.currencyFormatterUs(discountedValue)}(${discountDetails.value
  //       //       })`
  //       //       : "--",
  //       // type: discountDetails.type
  //       //   ? validate.nameFormatterToUpperCase(discountDetails.type)
  //       //   : "--",
  //       attachedFile: "",
  //       description: getInformationBasedOnDataChange(item),
  //       actionBy: metaObj.emailToName(actionBy),
  //       actionType: type
  //     })
  //   })
  // }

  data = data.sort((a, b) => new Date(b) - new Date(a))
  console.log(data)
  return (
    <div>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          handleClickOpen()
          // onClickHistory()
          // _get_invoice_history()
        }}
      >
        <VisibilityIcon />
      </IconButton>
      <Dialog disableEscapeKeyDown disableBackdropClick open={open} fullWidth maxWidth="lg">
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component="h1" variant="h4" align="center">
                Invoice Timeline
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <main className={classes.layout}>
          <div>
            <CustomTable data={data} columns={columns} 
            // isLoading={!isLoaded} 
            isToolBar={false} />
          </div>
        </main>
      </Dialog>
    </div>
  )
}

export default InvoiceTimeline
