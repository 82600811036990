import {
  errorMsg,
  waitingMsg,
  stopWaitMsg,
  successMsg,
} from "../../../shared/SnackBars/index";
import make_API_call from "../../../providers/REST_API";
import firebase from "../../../config/fbConfig";
import {
  loadPlacementSuccess,
  loadTimesheetSettingsSuccess,
  loadExistingTimesheetData,
  notifyDefaulterReq,
  notifyDefaulterSuccess,
  notifyDefaulterFailure,
} from "../actions/actionCreators";
import { addDays, eachDayOfInterval } from "date-fns";

export const submitTimesheet =
  (payload, employeeID, placementID) => {
    waitingMsg("Submitting timesheet");
    return make_API_call(
      "post",
      `/employees/${employeeID}/placements/${placementID}/timesheets/new`,
      payload
    )
      .then((res) => {
        console.log(res);
        stopWaitMsg();
        return successMsg(res.message);
      })
      .catch((err) => {
        console.log(err);
        stopWaitMsg();
        return errorMsg(err.message);
      });
  };

export const updateTimesheet =
  (payload, employeeID, placementID, timesheetID, history, isTimesheetPage) =>
    (dispatch) => {
      waitingMsg("Processing...");
      return make_API_call(
        "put",
        `/employees/${employeeID}/placements/${placementID}/timesheets/${timesheetID}/update`,
        payload
      )
        .then((res) => {
          console.log(res);
          stopWaitMsg();
          if (isTimesheetPage) history.push("/console/timesheets");
          return successMsg(res.message);
        })
        .catch((err) => {
          console.log(err);
          stopWaitMsg();
          return errorMsg(err.message);
        });
    };

export const approveTimesheet =
  (
    payload,
    employeeID,
    placementID,
    timesheetID,
    condition,
    setState,
    state,
    history
  ) =>
    (dispatch) => {
      waitingMsg("Approving timesheet");
      return make_API_call(
        "put",
        `/employees/${employeeID}/placements/${placementID}/timesheets/${timesheetID}/approve`,
        payload
      )
        .then((res) => {
          console.log(res);
          stopWaitMsg();
          if (condition === "viewing") {
            history.push("/console/timesheets");
          } else {
            setState({
              [condition]: state[condition].filter(
                (item) => item.id !== timesheetID
              ),
            });
          }
          return successMsg(res.message);
        })
        .catch((err) => {
          console.log(err);
          stopWaitMsg();
          return errorMsg(err.message);
        });
    };

export const rejectTimesheet =
  (
    payload,
    employeeID,
    placementID,
    timesheetID,
    condition,
    setState,
    state,
    history
  ) =>
    (dispatch) => {
      waitingMsg("Rejecting timesheet...");
      return make_API_call(
        "put",
        `/employees/${employeeID}/placements/${placementID}/timesheets/${timesheetID}/reject`,
        payload
      )
        .then((res) => {
          console.log(res);
          stopWaitMsg();
          if (condition === "viewing") {
            history.push("/console/timesheets");
          } else {
            setState({
              [condition]: state[condition].filter(
                (item) => item.id !== timesheetID
              ),
            });
          }
          return successMsg(res.message);
        })
        .catch((err) => {
          console.log(err);
          stopWaitMsg();
          return errorMsg(err.message);
        });
    };

export const loadEmployeePlacements = (employeeID) => {
  return firebase
    .firestore()
    .collection(`EMPLOYEES/${employeeID}/PLACEMENTS`)
    .where("draft", "==", false)
    .where("isExist", "==", true)
    .orderBy("createdAt", "desc")
    .get()
    .then((snap) => {
      const placements = snap.docs.map((doc) => doc.data());
      console.log(placements);
      return placements;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadPlacement = (employeeID, placementID) => {
  return firebase
    .firestore()
    .collection(`EMPLOYEES/${employeeID}/PLACEMENTS`)
    .doc(placementID)
    .get()
    .then((doc) => {
      return doc.data();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadSettings = (employeeID, placementID) => {
  return firebase
    .firestore()
    .collection(`EMPLOYEES/${employeeID}/PLACEMENTS/${placementID}/SETTINGS`)
    .doc("timesheets")
    .get()
    .then((doc) => {
      return doc.data();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadTimesheet = (employeeID, timesheetID) => {
  return firebase
    .firestore()
    .collection(`EMPLOYEES/${employeeID}/TIMESHEETS`)
    .doc(timesheetID)
    .get()
    .then((doc) => {
      return doc.data();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadTimesheetSettings = (placementIDs) => (dispatch) => {
  return firebase
    .firestore()
    .collectionGroup("SETTINGS")
    .where("placementID", "in", placementIDs)
    .where("id", "==", "timesheets")
    .get()
    .then((snap) => {
      const data = snap.docs
        .map((doc) => doc.data())
        .filter((placement) => new Date(placement.projectEndDate) > new Date());
      const formatted = data.reduce((init, item) => {
        return {
          ...init,
          [item.placementID]: {
            settings: {
              timesheets: item,
            },
          },
        };
      }, {});
      console.log(formatted);
      dispatch(loadTimesheetSettingsSuccess(formatted));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadForEditOrView =
  (employeeID, placementID, timesheetID, setState) => (dispatch) => {
    return firebase
      .firestore()
      .collection(`EMPLOYEES/${employeeID}/PLACEMENTS`)
      .doc(placementID)
      .get()
      .then((doc) => {
        dispatch(loadExistingTimesheetData({ placement: doc.data() }));
        return firebase
          .firestore()
          .collection(`EMPLOYEES/${employeeID}/TIMESHEETS`)
          .doc(timesheetID)
          .get();
      })
      .then((doc) => {
        const sheet = doc.data();
        const rangeDates = eachDayOfInterval({
          start: sheet.startDate,
          end: sheet.endDate,
        });
        setState({
          standardTime: sheet.workdetails.standardTime,
          OTtime: sheet.workdetails.OTtime,
          sourcePath: sheet.attachmentDetails.sourcePath,
          publicURL: sheet.attachmentDetails.publicURL,
          timesheetStartDate: sheet.startDate,
          timesheetEndDate: sheet.endDate,
          loadEntryTable: true,
          selectedRange: rangeDates,
        });
        dispatch(loadExistingTimesheetData({ timesheet: doc.data() }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const notifyDefaulter =
  (inputs, employeeID, timesheetID) => (dispatch, getState) => {
    dispatch(notifyDefaulterReq(timesheetID));
    const auth = getState().firebase.auth;
    return make_API_call("post", "emails/open/send", inputs)
      .then((data) => {
        successMsg(data.message);
        dispatch(notifyDefaulterSuccess(timesheetID));
        const log = {
          subject: {
            uid: employeeID,
          },
          actionBy: auth.uid,
          createdAt: new Date().toISOString(),
          type: "notifyDefaulter",
          eventDetails: {
            before: {},
            after: {},
          },
        };
        return make_API_call("post", "/logger/history", log);
      })
      .catch((err) => {
        console.error(err);
        errorMsg(err.message);
        dispatch(notifyDefaulterFailure(timesheetID));
      });
  };

export const loadAlreadySubmittedTimesheets = async (placementID) => {
  const response = await make_API_call("get", `employees/placements/${placementID}/getsubmissions`);
  return response.data
};

export const getTimesheetsSkeleton = (placementID) => {
  return make_API_call("get", `employees/placements/${placementID}/timesheetskeleton`)
}

export const checkDateRangeIsValidOrNot = async (placementID, startDate, endDate) => {
  try {
    const dateRangesResponse = await make_API_call("get", `employees/placements/${placementID}/timesheetskeleton`)
    const dateRanges = dateRangesResponse.data
    if (!dateRanges.some(range => range.startDate === startDate && range.endDate === endDate))
      throw new Error('date range not found')
  } catch (error) {
    throw error
  }
}