import React, { useState, useEffect, useContext } from 'react'
import Presentation from "./Presentation"
import { approveTimesheet } from "../../../../middleware/index"
import { connect } from "react-redux"
import { Context } from "../../component_state/context"
import { useHistory } from "react-router-dom"

function Container(props) {
  console.log(props)
  const history = useHistory()
  const [state, handle] = useContext(Context)
  const { employeeID, placementID, id, isApproved, isRejected, isInvoiced, isDefaulter, timesheetManager } = state.viewingTimesheet
  const { _approve_timesheet, loggedInEmployee, isList } = props
  const [open, setOpen] = useState(false);
  const [hideBtn, setHideBtn] = useState(true)

  useEffect(() => {
    // button hide conditions
    /* 
      1) if it is not his/her own timesheet
      2) if it is not approved
      3) if it is not defaulter
    */
    if (loggedInEmployee !== employeeID && !isApproved && !isDefaulter && !isInvoiced) {
      setHideBtn(false)
    } else {
      setHideBtn(true)
    }
  }, [isApproved, isDefaulter, isInvoiced, employeeID])

  const onApprove = () => {
    // sending payload as empty for now, in future there may be possibility of sending non-empty
    handleClose()
    _approve_timesheet({}, employeeID, placementID, id, "viewing", "", "", history)
  }


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Presentation
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        onApprove={onApprove}
        hideBtn={hideBtn}
        employeeID={employeeID}
      />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _approve_timesheet: (payload, employeeID, placementID, timesheetID, condition, setState, state, history) => dispatch(approveTimesheet(payload, employeeID, placementID, timesheetID, condition, setState, state, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
