import { isWithinInterval } from 'date-fns';
import React, { useState, useReducer, useContext } from 'react';
import firebase from "../../../../config/fbConfig"
import make_API_call from "../../../../providers/REST_API"
import { CalendarFormatter } from '../../../../shared/CalendarFormatter';
import { successMsg, errorMsg } from "../../../../shared/SnackBars/index"
import validate from "../../../../shared/validation"

const InvoiceContext = React.createContext();
const useNewInvoiceContext = () => useContext(InvoiceContext);


const invoiceInitialState = {
  invoiceID: '',
  invoiceBy: '',
  clients: [],
  employees: [],
  placements: [],
  clientID: '',
  placementID: '',
  employeeID: '',
  companyDetails: {},
  toClient: true,
  netTerms: '',
  poNumber: '',
  invoiceLocation :{},
  invoiceDate: CalendarFormatter.standardDateFormat(new Date()),
  dueDate: '',
  invoiceName: '',
  externalAmount: 0,
  clientDetails: {
    name: "",
    address: "",
  },
  discount: [
    {
      name: "",
      value: 0,
      type: ""
    }
  ],
  notifiers: {
    to: [],
    cc: [],
    bcc: []
  },
  notes: '',
  statementMemo: '',
  includeInvoicePDF: false,
  invoiceCategory: "",
  payableTo: '',
  additionalInfo: '',
  showLoader: false,
  timesheetsAccordingToInvoiceFrequency: [],
  expenses: [],
  selectedTimesheets: {},
  selectedExpenses: [],
  invoiceSettings: {},
  subTotal: 0,
  grandTotal: 0,
  isNewInvoiceCreating: false,
  isNewInvoiceUpdating: false,
  isDisableButton: true,
  isInvoiceEdit: false,
  invoicePreviewLoader: false,
  clientcontacts: [],
  placementNotifiers: {
    to: [],
    cc: [],
    bcc: []
  },
  isVoid: false,
  isPaymentDone: false,
  isInternal:false,
  employeeCategory:'',
  paidWhenPaid:false,
  tag:""

}

const InvoiceContextProvider = (props) => {
  const [invoiceID, setInvoiceID] = useState(invoiceInitialState.invoiceID);
  const [invoiceBy, setInvoiceBy] = useState(invoiceInitialState.invoiceBy);
  const [clients, setClients] = useState(invoiceInitialState.clients);
  const [employees, setEmployees] = useState(invoiceInitialState.employees);
  const [placements, setPlacements] = useState(invoiceInitialState.placements);
  const [clientID, setClientId] = useState(invoiceInitialState.clientID);
  const [placementID, setPlacementID] = useState(invoiceInitialState.placementID);
  const [employeeID, setEmployeeID] = useState(invoiceInitialState.employeeID);
  const [companyDetails, setCompanyDetails] = useState(invoiceInitialState.companyDetails);
  const [toClient, setToClient] = useState(invoiceInitialState.toClient);
  const [netTerms, setNetTerms] = useState(invoiceInitialState.netTerms);
  const [poNumber, setPoNumber] = useState(invoiceInitialState.poNumber);
  const [invoiceDate, setInvoiceDate] = useState(invoiceInitialState.invoiceDate);
  const [dueDate, setInvoiceDueDate] = useState(invoiceInitialState.dueDate);
  const [invoiceName, setInvoiceName] = useState(invoiceInitialState.invoiceName);
  const [externalAmount, setExternalAmount] = useState(invoiceInitialState.externalAmount);
  const [clientDetails, setClientDetails] = useState(invoiceInitialState.clientDetails);
  const [discount, setDiscount] = useState(invoiceInitialState.discount);
  const [notifiers, setNotifiers] = useState(invoiceInitialState.notifiers);
  const [notes, setNotes] = useState(invoiceInitialState.notes);
  const [statementMemo, setStatementMemo] = useState(invoiceInitialState.statementMemo);
  const [includeInvoicePDF, setIncludeInvoicePDF] = useState(invoiceInitialState.includeInvoicePDF);
  const [invoiceCategory, setInvoiceCategory] = useState(invoiceInitialState.invoiceCategory);
  const [payableTo, setPayableTo] = useState(invoiceInitialState.payableTo);
  const [additionalInfo, setAdditionalInfo] = useState(invoiceInitialState.additionalInfo);
  const [showLoader, setShowLoader] = useState(invoiceInitialState.showLoader);
  const [timesheetsAccordingToInvoiceFrequency, setTimesheetsAccordingToInvoiceFrequency] = useState(invoiceInitialState.timesheetsAccordingToInvoiceFrequency);
  const [expenses, setExpenses] = useState(invoiceInitialState.expenses);
  const [selectedTimesheets, setSelectedTimesheets] = useState(invoiceInitialState.selectedTimesheets);
  const [selectedExpenses, setSelectedExpenses] = useState(invoiceInitialState.selectedExpenses);
  const [invoiceSettings, setInvoiceSettings] = useState(invoiceInitialState.invoiceSettings);
  const [subTotal, setSubTotal] = useState(invoiceInitialState.subTotal);
  const [grandTotal, setGrandTotal] = useState(invoiceInitialState.grandTotal);
  const [isNewInvoiceCreating, setIsNewInvoiceCreating] = useState(invoiceInitialState.isNewInvoiceCreating);
  const [isNewInvoiceUpdating, setIsNewInvoiceUpdating] = useState(invoiceInitialState.isNewInvoiceUpdating);
  const [isDisableButton, setIsDisableButton] = useState(invoiceInitialState.isDisableButton);
  const [isInvoiceEdit, setIsInvoiceEdit] = useState(invoiceInitialState.isInvoiceEdit);
  const [invoicePreviewLoader, setInvoicePreviewLoader] = useState(invoiceInitialState.invoicePreviewLoader);
  const [clientcontacts, setclientcontacts] = useState(invoiceInitialState.clientcontacts);
  const [placementNotifiers, setPlacementNotifiers] = useState(invoiceInitialState.placementNotifiers);
  const [isVoid, setIsVoid] = useState(invoiceInitialState.isVoid);
  const [isPaymentDone, setIsPaymentDone] = useState(invoiceInitialState.isPaymentDone);
  const [invoiceLocation, setInvoiceLocation] = useState(invoiceInitialState.invoiceLocation);
  const [isInternal, setIsInternal] = useState(invoiceInitialState.isInternal);
  const [employeeCategory, setEmployeeCategory] = useState(invoiceInitialState.employeeCategory);
  const [paidWhenPaid, setPaidWhenPaid] = useState(invoiceInitialState.paidWhenPaid);
  const [tag,setTag]=useState(invoiceInitialState.tag)



  const state = {
    invoiceID,
    invoiceBy,
    clients,
    employees,
    placements,
    clientID,
    placementID,
    employeeID,
    companyDetails,
    toClient,
    netTerms,
    poNumber,
    invoiceDate,
    dueDate,
    invoiceName,
    externalAmount,
    clientDetails,
    discount,
    notifiers,
    notes,
    statementMemo,
    includeInvoicePDF,
    invoiceCategory,
    payableTo,
    additionalInfo,
    showLoader,
    timesheetsAccordingToInvoiceFrequency,
    expenses,
    selectedTimesheets,
    selectedExpenses,
    invoiceSettings,
    subTotal,
    grandTotal,
    isNewInvoiceCreating,
    isNewInvoiceUpdating,
    isDisableButton,
    isInvoiceEdit,
    invoicePreviewLoader,
    clientcontacts,
    placementNotifiers,
    isVoid,
    isPaymentDone,
    invoiceLocation,
    isInternal,
    employeeCategory,
    paidWhenPaid,
    tag
  }

  const stateSetters = {
    setInvoiceID,
    setInvoiceBy,
    setClients,
    setEmployees,
    setPlacements,
    setClientId,
    setPlacementID,
    setEmployeeID,
    setCompanyDetails,
    setToClient,
    setNetTerms,
    setPoNumber,
    setInvoiceDate,
    setInvoiceDueDate,
    setInvoiceName,
    setExternalAmount,
    setClientDetails,
    setDiscount,
    setNotifiers,
    setNotes,
    setStatementMemo,
    setIncludeInvoicePDF,
    setInvoiceCategory,
    setPayableTo,
    setAdditionalInfo,
    setShowLoader,
    setTimesheetsAccordingToInvoiceFrequency,
    setExpenses,
    setSelectedTimesheets,
    setSelectedExpenses,
    setInvoiceSettings,
    setSubTotal,
    setGrandTotal,
    setIsNewInvoiceCreating,
    setIsNewInvoiceUpdating,
    setIsDisableButton,
    setIsInvoiceEdit,
    setInvoicePreviewLoader,
    setclientcontacts,
    setPlacementNotifiers,
    setIsVoid,
    setIsPaymentDone,
    setInvoiceLocation,
    setIsInternal,
    setEmployeeCategory,
    setPaidWhenPaid,
    setTag
  }

  const clearState = (includeTheseAlso = []) => {
    // removed payableTo, companyDetails from clearState()
    if (includeTheseAlso.includes("clientID")) {
      setClientId(invoiceInitialState.clientID);
    }
    if (includeTheseAlso.includes("placementID")) {
      setPlacementID(invoiceInitialState.placementID);
    }
    if (includeTheseAlso.includes("employeeID")) {
      setEmployeeID(invoiceInitialState.employeeID);
    }
    if (includeTheseAlso.includes("netTerms")) {
      setNetTerms(invoiceInitialState.netTerms);
    }
    if (includeTheseAlso.includes("poNumber")) {
      setPoNumber(invoiceInitialState.poNumber);
    }
    if (includeTheseAlso.includes("clientDetails")) {
      setClientDetails(invoiceInitialState.clientDetails);
    }
    if (includeTheseAlso.includes("invoiceLocation")) {
      setInvoiceLocation(invoiceInitialState.invoiceLocation);
    }

    setInvoiceID(invoiceInitialState.invoiceID);
    setInvoiceDate(invoiceInitialState.invoiceDate);
    setInvoiceDueDate(invoiceInitialState.dueDate);
    setInvoiceName(invoiceInitialState.invoiceName);
    setExternalAmount(invoiceInitialState.externalAmount);
    setDiscount(invoiceInitialState.discount);
    setNotifiers(invoiceInitialState.notifiers);
    setNotes(invoiceInitialState.notes);
    setStatementMemo(invoiceInitialState.statementMemo);
    setIncludeInvoicePDF(invoiceInitialState.includeInvoicePDF);
    setInvoiceCategory(invoiceInitialState.invoiceCategory);
    setAdditionalInfo(invoiceInitialState.additionalInfo);
    setShowLoader(invoiceInitialState.showLoader);
    setTimesheetsAccordingToInvoiceFrequency(invoiceInitialState.timesheetsAccordingToInvoiceFrequency);
    setExpenses(invoiceInitialState.expenses);
    setSelectedTimesheets(invoiceInitialState.selectedTimesheets);
    setSelectedExpenses(invoiceInitialState.selectedExpenses);
    setInvoiceSettings(invoiceInitialState.invoiceSettings);
    setSubTotal(invoiceInitialState.subTotal);
    setGrandTotal(invoiceInitialState.grandTotal);
    setIsNewInvoiceCreating(invoiceInitialState.isNewInvoiceCreating);
    setIsNewInvoiceUpdating(invoiceInitialState.isNewInvoiceUpdating);
    setIsDisableButton(invoiceInitialState.isDisableButton);
    setIsInvoiceEdit(invoiceInitialState.isInvoiceEdit);
    setInvoicePreviewLoader(invoiceInitialState.invoicePreviewLoader);
    setclientcontacts(invoiceInitialState.clientcontacts);
    setPlacementNotifiers(invoiceInitialState.placementNotifiers);
    setIsVoid(invoiceInitialState.isVoid);
    setIsPaymentDone(invoiceInitialState.isPaymentDone);
    setIsInternal(invoiceInitialState.isInternal);
    setEmployeeCategory(invoiceInitialState.employeeCategory);
    setPaidWhenPaid(invoiceInitialState.paidWhenPaid)
    setTag(invoiceInitialState.tag)
  }

  const getClients = async (queryClientID) => {
    try {
      setShowLoader(true);
      const data = !!queryClientID ?
        (await firebase.firestore().collection("CLIENTS").where("id", "==", queryClientID).get()).docs.map((d) => d.data()) :
        (await firebase.firestore().collection("CLIENTS").get()).docs.map((d) => d.data());
      setClients(data);
      const selectedClient = data.filter(e => e.id === queryClientID)?.[0]
      await getClientInvoiceLocationById(queryClientID, selectedClient?.invoiceLocationId)
      setShowLoader(false);
      console.log("cleeeeeeee",data)
      return data;
    } catch (error) {
      errorMsg('Failed to load clients');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getClients',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getActiveEmployees = async (queryEmployeeID) => {
    try {
      setShowLoader(true);
      const employees = !queryEmployeeID ?
        (await firebase.firestore().collection("EMPLOYEES").where("status", "==", "active").get()).docs.map((d) => d.data()) :
        (await firebase.firestore().collection("EMPLOYEES").where("uid", "==", queryEmployeeID).get()).docs.map((d) => d.data());
      setEmployees(employees);
      setShowLoader(false);
      return employees;
    } catch (error) {
      errorMsg('Failed to load employees');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getActiveEmployees',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getClientContacts = async () => {
    try {
      setShowLoader(true);
      const contacts = (await firebase.firestore().collection(`CLIENTS/${clientID}/CLIENTS_CONTACTS`).where("isExist", "==", true).get()).docs.map((d) => d.data());
      setNotifiers({
        ...notifiers,
        to: contacts.map((contact) => contact.email)
      });
      setclientcontacts({ contacts });
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Clients Contacts');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getClientContacts',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getPlacementsOfTheEmployee = async (employeeID) => {
    try {
      if (!employeeID) return;
      setShowLoader(true);
      const data = (await firebase.firestore().collection(`EMPLOYEES/${employeeID}/PLACEMENTS`).where("draft", "==", false).where("isExist","==",true).get()).docs.map((d) => d.data());
      setPlacements(data);
      setShowLoader(false);
      return data;
    } catch (error) {
      errorMsg('Failed to load placements');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getPlacementsOfTheEmployee',
      };
      make_API_call('post', '/errors/report', errorInfo)
    }
  };

  const getPlacementsOfTheClient = async (clientId) => {
    try {
      if (!clientId) return;
      setShowLoader(true);
      const data = (await firebase.firestore().collectionGroup(`PLACEMENTS`)
        .where("clientId", "==", clientId)
        .where("draft", "==", false)
        .where("isExist","==",true)
        .get()).docs.map((d) => d.data());
      setPlacements(data);
      setShowLoader(false);
      return data;
    } catch (error) {
      errorMsg('Failed to load placements');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getPlacementsOfTheClient',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getNameOfTheEmployeeById = (employeeID) => {
    const employee = employees.filter((e) => e.uid === employeeID)?.[0];
    const { firstname = '', middlename = '', lastname = '' } = employee?.personal || {}
    return firstname + ' ' + middlename + ' ' + lastname
  }

  const getEmployeeById = (employeeID) => {
    const employee = employees.filter((e) => e.uid === employeeID)?.[0];
    return employee
  }

  const getClientById = (clientId) => {
    const client = clients.filter(e => e.id === clientId)?.[0]
    return client;
  }

  const getClientInvoiceLocationById = async (clientId,invoiceLocationId) => {
    try {
      if(clientId  && invoiceLocationId) {
      const clientInvoiceLocation =  (await firebase.firestore().collection("CLIENTS").doc(clientId).collection("CLIENTS_LOCATIONS").doc(invoiceLocationId).get()).data()
      setInvoiceLocation(clientInvoiceLocation ?? {})
      return clientInvoiceLocation;
      }
      else{
        setInvoiceLocation({})
      }
    }
    catch (error) {
      errorMsg('Failed to fetch invoice location');
    }
  }
  const getCompanyDetails = async (viewType) => {
    try {
      setShowLoader(true);
      const details = (await firebase.firestore().collection("COMPANY_CONFIG").doc("details").get()).data()
      if (viewType === "preview") {
        setCompanyDetails(details)
        setShowLoader(false);
      } else {
        setCompanyDetails(details)
        setPayableTo(details.invoiceDetails.payableTo)
        setShowLoader(false);
      }
    } catch (error) {
      errorMsg('Failed to load company details')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getCompanyDetails',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getInvoicePreRequisiteData = async () => {
    try {
      setShowLoader(true);
      const data = await make_API_call("get", `/invoices/pre-requisite-date-for-new-invoice?employeeID=${employeeID}&placementID=${placementID}`);
      setTimesheetsAccordingToInvoiceFrequency(data?.timesheetsAccordingToInvoiceFrequency || []);
      setExpenses(data?.expenses || [])
      setInvoiceSettings(data?.invoiceSettings || {})
      setNotifiers(data?.notifiers || []);
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load invoice pre-requisite data')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getInvoicePreRequisiteData',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const updatePlacement = async (payload, sectionName, uid, placementID) => {
    try {
      setShowLoader(true);
      const data = await make_API_call(
        "put",
        `/employees/${uid}/placements/${placementID}/updateplacement?name=${sectionName}`,
        payload
      )
      successMsg('Updated Project End Date')
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to Update Project End Date')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'updatePlacement',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getMinAndMaxDates = (datesArr) => {
    const list = datesArr.sort((a, b) => Date.parse(new Date(a)) - Date.parse(new Date(b)))
    return {
      minDate: list[0],
      maxDate: list[list.length - 1]
    }
  }

  const getTotalTime = (arr) => {
    const hours = [],
      minutes = [];
    arr.forEach((item) => {
      const [h, m] = item.split(":");
      hours.push(parseInt(h));
      minutes.push(parseInt(m));
    });
    let totalHours = hours.reduce((a, b) => a + b, 0);
    let totalMinutes = minutes.reduce((a, b) => a + b, 0);
    totalHours = parseInt(totalHours + totalMinutes / 60);
    let remainingMinutes = totalMinutes % 60;
    return (
      totalHours +
      ":" +
      "0".repeat(2 - remainingMinutes.toString().length) +
      remainingMinutes.toString()
    );
  }

  const getTotalTimeInHours = (arr) => {
    return Number(arr.reduce((init, time) => {
      const [h, m] = time.split(":").map((t) => parseInt(t))
      init += h + Number(m / 60)
      return init
    }, 0).toFixed(2))
  }

  const getBillingRatesInvolvedInTheTimesheets = (timesheets) => {
    return [...new Set(timesheets.map(it => it.billingRate))]
  }

  const getOTBillingRatesInvolvedInTheTimesheets = (timesheets) => {
    return [...new Set(timesheets.map(it => it.OTbillingRate))]
  }

  const getTotalAmountForTheTimesheetsAccordingToBillingRate = (timesheets) => {
    const am = timesheets.reduce((init, item) => init + item.billingRate * getTotalTimeInHours([item.standardTime]), 0)
    return parseFloat(am.toFixed(2))
  }

  const getTotalAmountForTheTimesheetsAccordingToOTBillingRate = (timesheets) => {
    const am = timesheets.reduce((init, item) => init + item.OTbillingRate * getTotalTimeInHours([item.OTtime]), 0)
    return parseFloat(am.toFixed(2))
  }

  const calculateSubTotal = () => {
    let standardTimeAmount = 0, OTtimeAmount = 0, expensesAmount = 0
    if (state.invoiceBy === "external") {
      if (selectedExpenses?.length !== 0) {
        expensesAmount = selectedExpenses?.reduce((a, b) => a + Number(b.amount), 0)
      }
    } else {
      const timesheetsArr = Object.values(state.selectedTimesheets)
      if (timesheetsArr?.length !== 0) {
        standardTimeAmount = getTotalAmountForTheTimesheetsAccordingToBillingRate(timesheetsArr);
        if (typeof state.invoiceSettings === "object" && state.invoiceSettings?.OT) {
          OTtimeAmount = getTotalAmountForTheTimesheetsAccordingToOTBillingRate(timesheetsArr);
        }
      }
      if (selectedExpenses?.length !== 0) {
        expensesAmount = selectedExpenses?.reduce((a, b) => a + Number(b.amount), 0)
      }
    }
    const calculatedSubTotal = Number((standardTimeAmount + OTtimeAmount + expensesAmount + state.externalAmount).toFixed(2))
    setSubTotal(calculatedSubTotal)
  }

  const calculateDiscount = (discountArr) => {
    return Number(discountArr.reduce((initial, item) => {
      if (item.type === "byValue") {
        return initial - Number(item.value);
      } else if (item.type === "byPercentage" && Number(item.value) > 0) {
        return (initial - Number((state.subTotal * Number(item.value)) / 100))
      } else {
        return initial - 0;
      }
    }, 0).toFixed(2)
    )
  }

  const calculateGrandTotal = () => {
    // calculation between subtotal and discount
    const discountAmount = calculateDiscount(state.discount)
    const calculatedGrandTotal = Number((state.subTotal + discountAmount).toFixed(2))
    setGrandTotal(calculatedGrandTotal)
  }

  const getPlacementById = (placementId) => {
    const placement = placements.filter(e => e.id === placementId)?.[0]
    return placement;
  }

  const createNewInvoice = async (history) => {
    const payload = {
      invoiceBy,
      clientID,
      placementID,
      employeeID,
      companyDetails: {
        name: companyDetails.companyName,
        address: companyDetails.contactDetails?.address,
      },
      toClient,
      netTerms,
      poNumber,
      invoiceDate,
      dueDate,
      invoiceName,
      externalAmount,
      clientDetails,
      discount: discount.length < 2 && discount[0].type === "" ? [] : discount,
      notifiers,
      notes,
      statementMemo,
      includeInvoicePDF,
      invoiceCategory,
      payableTo,
      additionalInfo,
      selectedTimesheets,
      selectedExpenses,
      invoiceSettings,
      subTotal,
      grandTotal,
      tag
    }

    try {
      setShowLoader(true);
      setIsNewInvoiceCreating(true);
      const res = await make_API_call("post", `invoices/create-invoice`, payload)
      history.push("/console/invoiceslist?tab=1");
      successMsg(res.message);
      setShowLoader(false);
      setIsNewInvoiceCreating(false);
    } catch (error) {
      errorMsg(error.message)
      setShowLoader(false);
      setIsNewInvoiceCreating(false)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'createNewInvoice',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getInvoiceDetails = async (invoiceID, viewType) => {
    try {
      setShowLoader(true);
      setInvoicePreviewLoader(true);
      const encodedID = encodeURIComponent(invoiceID);
      const data = await make_API_call("get", `/invoices/get-invoice?invoiceID=${encodedID}`)
      const { invoiceData: invoiceDetails, expenses, timesheetsAccordingToInvoiceFrequency } = data
      if (viewType === "edit") {
        await getClients(invoiceDetails?.clientID)
        setTimesheetsAccordingToInvoiceFrequency(timesheetsAccordingToInvoiceFrequency || invoiceInitialState.timesheetsAccordingToInvoiceFrequency)
        setCompanyDetails(invoiceDetails?.companyDetails || invoiceInitialState.companyDetails)
        setExpenses(expenses || invoiceInitialState.expenses)
      }
      if (viewType === "edit" && !invoiceDetails?.externalDetails?.toClient){
        await getPlacementsOfTheEmployee(invoiceDetails?.employeeID)
      }
      await getActiveEmployees(invoiceDetails?.employeeID)
      setInvoiceBy(invoiceDetails?.invoiceBy || invoiceInitialState.invoiceBy)
      setClientId(invoiceDetails?.clientID || invoiceInitialState.clientID)
      setPlacementID(invoiceDetails?.placementID || invoiceInitialState.placementID)
      setEmployeeID(invoiceDetails?.employeeID || invoiceInitialState.employeeID)
      setToClient(invoiceDetails?.externalDetails?.toClient ?? invoiceInitialState.toClient)
      setNetTerms(invoiceDetails?.netTerms || invoiceInitialState.netTerms)
      setPoNumber(invoiceDetails?.poNumber || invoiceInitialState.poNumber)
      setInvoiceDate(invoiceDetails?.invoiceDate || invoiceInitialState.invoiceDate)
      setInvoiceDueDate(invoiceDetails?.dueDate || invoiceInitialState.dueDate)
      setInvoiceName(invoiceDetails?.invoiceName || invoiceInitialState.invoiceName)
      setExternalAmount(invoiceDetails?.externalDetails?.externalAmount || invoiceInitialState.externalAmount)
      setClientDetails(invoiceDetails?.clientDetails || invoiceInitialState.clientDetails)
      setDiscount(invoiceDetails?.discount.length === 0 ? invoiceInitialState.discount : invoiceDetails?.discount || invoiceInitialState.discount)
      setNotifiers(invoiceDetails?.notifiers || invoiceInitialState.notifiers)
      setNotes(invoiceDetails?.notes || invoiceInitialState.notes)
      setStatementMemo(invoiceDetails?.statementMemo || invoiceInitialState.statementMemo)
      setIncludeInvoicePDF(invoiceDetails?.includeInvoicePDF || invoiceInitialState.includeInvoicePDF)
      setInvoiceCategory(invoiceDetails?.invoiceCategory || invoiceInitialState.invoiceCategory)
      setPayableTo(invoiceDetails?.payableTo || invoiceInitialState.payableTo)
      setAdditionalInfo(invoiceDetails?.additionalInfo || invoiceInitialState.additionalInfo)
      setSelectedTimesheets(invoiceDetails?.selectedTimesheets || invoiceInitialState.selectedTimesheets)
      setSelectedExpenses(invoiceDetails?.selectedExpenses || invoiceInitialState.selectedExpenses)
      setInvoiceSettings(invoiceDetails?.invoiceSettings || invoiceInitialState.invoiceSettings)
      setSubTotal(invoiceDetails?.subTotal || invoiceInitialState.subTotal)
      setGrandTotal(invoiceDetails?.grandTotal || invoiceInitialState.grandTotal)
      setIsVoid(invoiceDetails?.isVoid || invoiceInitialState.isVoid);
      setIsPaymentDone(invoiceDetails?.isPaymentDone || invoiceInitialState.isPaymentDone);
      if(viewType !== "preview" && (invoiceDetails?.employeeID && invoiceDetails?.placementID)){
        await getNotifiersFromPlacement(invoiceDetails?.employeeID, invoiceDetails?.placementID)
      }
      setShowLoader(false);
      setInvoicePreviewLoader(false);
    } catch (error) {
      errorMsg("Failed to load invoice details");
      setShowLoader(false);
      setInvoicePreviewLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getInvoiceDetails',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const updateInvoice = async (history, isUpdatingNotifiers) => {
    const payload = {
      invoiceBy,
      clientID,
      placementID,
      employeeID,
      companyDetails,
      toClient,
      netTerms,
      poNumber,
      invoiceDate,
      dueDate,
      invoiceName,
      externalAmount,
      clientDetails,
      discount: discount.length < 2 && discount[0].value === "" ? [] : discount,
      notifiers: isUpdatingNotifiers ? placementNotifiers : notifiers,
      notes,
      statementMemo,
      includeInvoicePDF,
      invoiceCategory,
      payableTo,
      additionalInfo,
      selectedTimesheets,
      selectedExpenses,
      invoiceSettings,
      subTotal,
      grandTotal,
      tag
    }

    try {
      let viewType = "edit"
      setShowLoader(true);
      setIsNewInvoiceUpdating(true)
      const encodedID = encodeURIComponent(invoiceID);
      const res = await make_API_call("put", `/invoices/update-invoice?invoiceID=${encodedID}`, payload);
      successMsg(res.message);
      isUpdatingNotifiers ? await getInvoiceDetails(invoiceID, viewType) : history.push("/console/invoiceslist?tab=1");
      setShowLoader(false);
      setIsNewInvoiceUpdating(false);
    } catch (error) {
      isUpdatingNotifiers ? errorMsg("Unable to Update Notifiers") : errorMsg(error.message)
      setShowLoader(false);
      setIsNewInvoiceUpdating(false)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'updateInvoice',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  // const updateNotifiersInPlacement = async () => {
  //   const payload = {
  //     invoiceBy,
  //     clientID,
  //     placementID,
  //     employeeID,
  //     companyDetails,
  //     toClient,
  //     netTerms,
  //     poNumber,
  //     invoiceDate,
  //     dueDate,
  //     invoiceName,
  //     externalAmount,
  //     clientDetails,
  //     discount: discount.length < 2 && discount[0].value === "" ? [] : discount,
  //     notifiers: placementNotifiers,
  //     notes,
  //     statementMemo,
  //     includeInvoicePDF,
  //     invoiceCategory,
  //     payableTo,
  //     additionalInfo,
  //     selectedTimesheets,
  //     selectedExpenses,
  //     invoiceSettings,
  //     subTotal,
  //     grandTotal
  //   }

  //   try {
  //     setShowLoader(true);
  //     const encodedID = encodeURIComponent(invoiceID);
  //     await make_API_call("put", `/invoices/update-invoice?invoiceID=${encodedID}`, payload);
  //     successMsg("Updated Notifiers");
  //     setShowLoader(false);
  //   } catch (error) {
  //     errorMsg("Failed to Update Notifiers")
  //     setShowLoader(false);
  //     const errorInfo = {
  //       stringifiedError: error?.message,
  //       service: 'Invoices - Create Invoice',
  //       functionName: 'updateNotifiersInPlacement',
  //     }
  //     make_API_call('post', '/errors/report', errorInfo)
  //   }
  // }

  const getTimeInHours = (time) => {
    let timeInHours = 0
    const [h, m] = time.split(":").map((t) => parseInt(t))
    timeInHours = h + Number(m / 60)
    return timeInHours
  }

  const getformatTime = (time) => {
    const { formattedValue, value } = time;
    const check = (val, max) => {
      if (
        parseInt(value) > 2400 ||
        (val.length === max.length && parseInt(val) > parseInt(max))
      ) {
        return false;
      }
      return true;
    };
    const hours = check(formattedValue.substring(0, 2), "24");
    const minutes = check(formattedValue.substring(3, 5), "59");
    return hours && minutes;
  }

  const updateTimesheet = async (payload, timesheetID, updateTimesheetLocally) => {
    console.log(payload, timesheetID, "true")
    const { date, workedHours, workType } = payload
    try {
      await make_API_call("put", `/employees/${employeeID}/placements/${placementID}/timesheets/${timesheetID}/update-working-hours`, payload)
      successMsg(`Successfully Updated Timesheet for ${workType} on ${date} for ${workedHours}`)
      updateTimesheetLocally()
    } catch (error) {
      errorMsg('Failed to Update Timesheet')
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'updateTimesheet',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getNotifiersFromPlacement = async (empID, pcID) => {
    try {
      setShowLoader(true);
      const placementRef = await firebase.firestore().collection(`EMPLOYEES/${empID}/PLACEMENTS`).doc(pcID);
      const placementDetails = (await placementRef.get()).data();
      const invoiceSettings = (await placementRef.collection(`SETTINGS`).doc('invoices').get()).data();
      const contacts = (await firebase.firestore().collection(`CLIENTS/${placementDetails.clientId}/CLIENTS_CONTACTS`).where("isExist", "==", true).get()).docs.map((d) => d.data());
      const contactsIdsWithEmail = contacts.reduce((init, contact) => {
        return {
          ...init,
          [contact.id]: contact.email
        }
      }, {})

      const to = invoiceSettings?.to.map((eachTo) => {
        if (eachTo in contactsIdsWithEmail)
          return contactsIdsWithEmail[eachTo]
        else if (validate.checkEmail(eachTo))
          return eachTo
        return undefined;
      }).filter((e) => e !== undefined);

      const cc = invoiceSettings?.cc.map((eachCc) => {
        if (eachCc in contactsIdsWithEmail)
          return contactsIdsWithEmail[eachCc]
        else if (validate.checkEmail(eachCc))
          return eachCc
        return undefined;
      }).filter((e) => e !== undefined);

      let notifiers = {
        to: to || [],
        cc: cc || [],
        bcc: invoiceSettings?.bcc || []
      }

      setPlacementNotifiers(notifiers || []);
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Notifiers from placement');
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Create Invoice',
        functionName: 'getNotifiersFromPlacement',
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }


  const services = {
    getClients,
    getActiveEmployees,
    getClientContacts,
    getPlacementsOfTheEmployee,
    getPlacementsOfTheClient,
    getNameOfTheEmployeeById,
    getCompanyDetails,
    getInvoicePreRequisiteData,
    getClientById,
    getMinAndMaxDates,
    updatePlacement,
    getTotalTime,
    getTotalTimeInHours,
    getBillingRatesInvolvedInTheTimesheets,
    getOTBillingRatesInvolvedInTheTimesheets,
    getTotalAmountForTheTimesheetsAccordingToBillingRate,
    getTotalAmountForTheTimesheetsAccordingToOTBillingRate,
    calculateDiscount,
    calculateSubTotal,
    calculateGrandTotal,
    getPlacementById,
    createNewInvoice,
    getInvoiceDetails,
    updateInvoice,
    clearState,
    getTimeInHours,
    getformatTime,
    updateTimesheet,
    getNotifiersFromPlacement,
    getClientInvoiceLocationById,
    getEmployeeById
  }

  return (
    <InvoiceContext.Provider value={{ state, stateSetters, services }}>
      {props.children}
    </InvoiceContext.Provider>
  );
};

const NewInvoiceContextProvider = (props) => <div key={Date.now()} ><InvoiceContextProvider>{props.children}</InvoiceContextProvider></div>

export { NewInvoiceContextProvider, useNewInvoiceContext }