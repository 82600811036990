import React, { useContext, useEffect, useState } from 'react'
import { Chip } from "@material-ui/core";
import { loadDefaulters, loadAllDefaulters } from '../../../middleware/timesheetsList';
import { Link } from "react-router-dom";
import EmployeeHoverCard from '../../../../../shared/components/EmployeeHoverCard';
import MetaInfo from '../../../../../shared/getMetaInfo';
import FlairTable from '../../../../../shared/FlairTable';
import { Context } from "../component_state/context";
import { connect } from 'react-redux';

function Defaulters(props) {
  const [defaulters, setDefaulters] = useState([])
  const [loading, setLoading] = useState(true)
  const metaInfo = new MetaInfo();
  const [state, handle] = useContext(Context);
  const { isManager,branchList,names } = props
  const lookupBranchList = Object.assign({}, branchList);

  const getDefaulters = async () => {
    setLoading(true)
    const data = await loadDefaulters(props.loggedInEmployee)
    setDefaulters(data)
    setLoading(false)
  }

  const getAllDefaulters = async (employeeID) => {
    // here employeeID might be null when we need to load all defaulters
    setLoading(true)
    const data = await loadAllDefaulters(employeeID)
    setDefaulters(data)
    setLoading(false)
  }

  useEffect(() => {
    if (isManager && state.listAll)
      getAllDefaulters()
    else if (isManager)
      getAllDefaulters(state.employeeID)
    else
      getDefaulters()
  }, [])

  const managerViewRows = () => {
    return defaulters.map((defaulter) => {
      const branchOfEmployee = metaInfo.getEmployeeKey(defaulter.employeeID, "branch");
      const employeeDetails = names[defaulter.employeeID]
      return {
        ...defaulter,
        clientName: metaInfo.clientIdToName(defaulter.clientId),
        duration: defaulter.ranges.map(r => `${r.startDate}-${r.endDate}`).join(' '),
        totalPending: defaulter.ranges.length,
        employeeName: metaInfo.emailToName(defaulter.employeeID),
        branch : branchOfEmployee === undefined ? -1 : branchList.indexOf(branchOfEmployee),
        email: employeeDetails?.email,
        phonenumber: employeeDetails?.phonenumber
      }
    }).filter((defaulter) => defaulter.totalPending !== 0)
  }

  const employeeViewRows = () => {
    return defaulters.map((defaulter) => {
      const branchOfEmployee = metaInfo.getEmployeeKey(defaulter.employeeID, "branch");
      const employeeDetails = names[defaulter.employeeID]
      return {
        ...defaulter,
        clientName: metaInfo.clientIdToName(defaulter.clientId),
        duration: defaulter.startDate + "-" + defaulter.endDate,
        employeeName: metaInfo.emailToName(defaulter.employeeID),
        branch : branchOfEmployee === undefined ? -1 : branchList.indexOf(branchOfEmployee),
        email: employeeDetails?.email,
        phonenumber: employeeDetails?.phonenumber
      }
    })
  }

  const rows = isManager ? managerViewRows : employeeViewRows

  const columns = [
    {
      title: "Employee",
      field: "employeeName",
      width: 250,
      dataType: "String",
      render: (row) => {
        return <EmployeeHoverCard employeeID={row.employeeID} />;
      }
    },
    {
      title: "Email ID",
      field: "email",
      width: 200,
      dataType: "String",
    },
    {
      title: "Phone Number",
      field: "phonenumber",
      width: 130,
      dataType: "String",
    },
    {
      title: "Billable Client",
      field: "clientName",
      width: 200,
      render: (rowData) => <div style={{width:200, paddingLeft:5 }}>{rowData.clientName}</div>,
      dataType: "String",
    },
    {
      title: "Placement ID",
      field: "placementID",
      width: 150,
      render: (rowData) => {
        if(rowData?.draftIndexforLookup === 0){
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID.toString()} >
              <Chip
                className="kk-chip bg-warning text-light c-pointer"
                label={rowData.placementID.toString()}
              />
            </Link>
          );
        } else {
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID.toString()} >
              <Chip
                className="kk-chip bg-success text-light c-pointer"
                label={rowData.placementID.toString()}
              />
            </Link>
          );
        }
      },
      dataType: "String",
    },
    {
      title: "Branch",
      field: "branch",
      width: 100,
      dataType: "String",
      lookup: lookupBranchList
    },
    {
      title: "Total Pending",
      field: "totalPending",
      dataType: "Number",
      width: 130,
    },
    {
      title: "Duration",
      field: "duration",
      width: 200,
      dataType: "String",
      render: (row) => {
        if (isManager)
          return (
            <div>
              {
                row.ranges.map(range => {
                  return (
                    <div
                      style={{
                          backgroundColor: "#f8e6bf",
                          color: "#e68c18",
                          display: "inline-block",
                          alignItems: "center",
                          padding: "0 12px",
                          borderRadius: "25px",
                          height: "24px", // Set the same height as Material-UI Chip
                          fontSize: "0.875rem", // Set the same font size as Material-UI Chip
                          lineHeight: "24px", // Adjust the line height if needed
                      }}
                    >
                      {range.startDate} - {range.endDate}
                    </div>
                  )
                })
              }
            </div>
          )
        return (
          <Link
            to={`/console/timesheets/defaulter?employeeID=${row.employeeID}&placementID=${row.placementID}&startDate=${row.startDate}&endDate=${row.endDate}`}
          >
            <span className="custom-chip chip-warning text-dark c-pointer">
              {row.duration}
            </span>
          </Link>
        )
      }
    },
    {
      title: "Actions",
      field: "",
      width: 150,
      render: (row) => (
        <div className="d-flex">
        </div>
      )
    }
  ];

  return (
    <div>
      <FlairTable
        title="Defaulters Report"
        columns={columns}
        data={rows()}
        isLoading={loading}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    isManager: ['timesheets-manager', 'console-customization'].some(module => state.employee.employeeModules.accessModules.includes(module)),
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [],
    names: state.employee.employeeList.metaInfoofEmployees.data,
  }
}

export default connect(mapStateToProps)(Defaulters)
