import React, { useEffect, useState } from "react"
import {
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@material-ui/core"

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { GoFileSymlinkFile } from "react-icons/go"
import {
  CurrencyFormatter,
} from "../../../../shared/customNumberFormats"
import validate from "../../../../shared/validation"
import { CalendarFormatter } from "../../../../shared/CalendarFormatter"
import { uploadToStorage } from "../../../../shared/fileUploader"
import { useBulkPaymentsContext } from "./context"


function PaymentDivision(props) {
  const { invoiceID,
  } = props
  const { state, services, stateSetters } = useBulkPaymentsContext()

  return (
    <div>
      <form>
        <Paper elevation={2} style={{ padding: '10px', marginTop: '10px' }} >
          <div>
            <h3>
              <u>Payment:</u>
            </h3>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  required
                  select
                  style={{ minWidth: "100%", marginTop: "-1px" }}
                  label="Payment method"
                  value={state.paymentMethod}
                  name="paymentMethod"
                  onChange={(e) => stateSetters.setPaymentMethod(e.target.value)}
                >
                  <MenuItem value="cheque">By Cheque</MenuItem>
                  <MenuItem value="online">By Online</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  onChange={(e) => {
                    if (!isNaN(e.target.value))
                      stateSetters.setPaymentAmount(Number(e.target.value))
                  }}
                  value={state.paymentAmount}
                  fullWidth
                  required
                  label="Payment Amount"
                  name="paymentAmount"
                  InputProps={{
                    inputComponent: CurrencyFormatter,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    required
                    fullWidth
                    value={state.paymentDate ? state.paymentDate : null}
                    label="Payment Date"
                    name="paymentDate"
                    format="MM/dd/yyyy"
                    onChange={(date) => {
                      if (!isNaN(Date.parse(date)))
                        stateSetters.setPaymentDate(CalendarFormatter.standardDateFormat(date))
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.noReference}
                      onChange={(e) => {
                        stateSetters.setNoReference(e.target.checked)
                      }}
                      disabled={state.isInvoiceEdit}
                    />
                  }
                  label="No Reference Number"
                />
              </Grid>
              {
                !state.noReference ?
                  <>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        required={!state.noReference}
                        fullWidth
                        label="Reference Number"
                        name="refereceNumber"
                        value={state.referenceNumber}
                        onChange={(e) => e.target.value.match(/^[a-z0-9]+$/i) || e.target.value.length === 0 ? stateSetters.setReferenceNumber(e.target.value) : () => { }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        // style={{ display: "none" }}
                        id="contained-button-file-wafile"
                        type="file"
                        onChange={services.handleFile}
                        disabled={!state.referenceNumber}
                        required={!state.noReference}
                        variant="outlined"
                        size="small"
                        label="Reference Document"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <div className="d-flex justify-content-between">
                        <p>
                          {state.attachment.publicURL.length ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={state.attachment.publicURL}
                            >
                              <GoFileSymlinkFile size={22} />
                            </a>
                          ) : null}{" "}
                        </p>
                      </div>
                      {state.isUploading ? <p>Uploading please wait...</p> : ""}
                    </Grid>
                  </>
                  :
                  null
              }

            </Grid>
          </div>
        </Paper>
      </form>
    </div>
  )
}

export default PaymentDivision
