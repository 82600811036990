import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { RegexExp } from "../../../../../../shared/regex";

const schema = yup.object().shape({
  verifiedby: yup.string().required().matches(RegexExp.name),
  initiatedby: yup.string().required().matches(RegexExp.name),
  status: yup.string().required(),
  initiatedon: yup.date().required("Initiated date is a required field"),
  ['i-9document']: yup.string().required(),

});

export default function I9FormEditModal(props) {
  const { data, employeeID, idx, onHide } = props;
  const formStatus = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.['i-9form']?.fields?.filter((field) => field.name === "status")?.[0]?.values || [])
  const optionMapper = (i) => ({ value: i, label: i });
  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return data[idx];
    } else {
      return {};
    }
  };

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit I-9 Form";
    } else {
      return "Create I-9 Form";
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const callback = () => {
      actions.setSubmitting(false);
      onHide();
    };

    const formData = { ...values };

    let i9form = data?.map((item) => item) || [];
    
    if (formData?.status === formStatus?.[0]) { // Active
      i9form = i9form?.map((item) => {
        return {
          ...item,
          status: formStatus?.[1] // Inactive
        }
      })
    }

    if (!isNaN(idx)) {
      i9form[idx] = formData;
    } else {
      i9form.push(formData);
    }

    const payload = {
     'i-9form':i9form,
    };

    dispatch(updateProfile(payload, employeeID, payload, callback));
  };

  const dispatch = useDispatch();
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        initialValues={loadInitialValues()}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title={getTitleText()} />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="verifiedby"
                      type="text"
                      label={"Verified By *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="initiatedby"
                      type="text"
                      label={"Initiated By *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="status"
                      type="select"
                      options={formStatus?.map(optionMapper)}
                      label={"Status *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="initiatedon"
                      type="text"
                      label={"Initiated On *"}
                      component={DateFormField}
                    />
                  </Col>
                  <Col xl={4}>
                     <Field
											name="i-9document"
											component={FileUploadForm}
											label="i9 Document *"
											type="text"
											employeeId={employeeID}
											sectionKey="i-9form"
											/>
                  </Col>
                </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText={getSaveButtonText()}
                saveButtonDisabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty            
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
