import React from "react";
import { IconButton, Tooltip, Chip } from "@material-ui/core";
import { Link } from "react-router-dom";
import ApproveTimesheet from "../ApproveTimesheet";
import RejectTimesheet from "../RejectTimesheet";
import EditIcon from "@material-ui/icons/Edit";
import NotifyDefaulter from "../NotifyDefaulter";
import validation from "../../../../../shared/validation";
import { TimesheetsUTILS } from "../../../../../shared/JSutils";
import parser from "html-react-parser";
import MetaInfo from "../../../../../shared/getMetaInfo";
import { ImFileText2 } from "react-icons/im";
import WarningIcon from "@material-ui/icons/Warning";
import EmployeeHoverCard from "../../../../../shared/components/EmployeeHoverCard";
import FlairTable from "../../../../../shared/FlairTable";
import StatusReportHoverCard from "../StatusReportHoverCard";
import Button from "react-bootstrap/Button"
import { FaCentercode } from "react-icons/fa";
import ActiveChip from "../../../../../shared/lib/Chips/ActiveChip"; 
import InactiveChip from "../../../../../shared/lib/Chips/InactiveChip"; 
import InfoChip from "../../../../../shared/lib/Chips/InfoChip"; 
import GreyChip from "../../../../../shared/lib/Chips/GreyChip"; 
import RedChip from "../../../../../shared/lib/Chips/RedChip"; 
import { MdDone } from "react-icons/md";

function Presentation(props) {
  const {
    loggedInEmployee,
    state,
    condition,
    timesheets_data,
    previousPage,
    nextPage,
    currentPage,
    modules,
    count,
    branchList,
    employees
  } = props;
  const metaInfo = new MetaInfo();
  const lookupBranchList = Object.assign({}, branchList);

  const EditTimesheet = (editProps) => {
    const { employeeID, placementID, timesheetID, isApproved } = editProps;
    console.log(editProps);
    if (
      (loggedInEmployee === employeeID && !isApproved) ||
      modules.includes("timesheets-manager")
    )
      return (
      <Tooltip title="Edit">
        <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} to={`/console/timesheets/edit?employeeID=${employeeID}&placementID=${placementID}&timesheetID=${timesheetID}`}>
          <EditIcon size={20} /></Link>
      </Tooltip>
      );
    return null;
  };
  const columns = [
    {
      title: "Employee",
      field: "empName",
      width: 200,
      dataType: "String",
      render: (row) => {
        return <EmployeeHoverCard employeeID={row.employeeID} />;
      }
    },
    {
      title: "Employee ID",
      field: "employeeID",
      width: 120,
      dataType: "String",
    },
    {
      title: "Email Id",
      width: 200,
      field: "empEmailId",
      dataType: "String",
    },
    {
      title: "Phone Number",
      field: "empPhoneNumber",
      width: 130,
      dataType: "String",
    },
    {
      title: "Billable Client",
      field: "clientName",
      width: 200,
      dataType: "String"
    },
    {
      title: "Placement ID",
      field: "placementID",
      width: 150,
      render: (rowData) => {
        if(rowData?.draftIndexforLookup === 0){
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID.toString()} >
              <Chip
                className="kk-chip bg-warning text-light w-75 c-pointer"
                label={rowData.placementID.toString()}
              />
            </Link>
          );
        } else {
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID.toString()} >
              <ActiveChip message={rowData.placementID.toString()} />
            </Link>
          );
        }
      },
      dataType: "String"
    },
    {
      title: "Branch",
      field: "branch",
      width: 100,
      dataType: "String",
      lookup: lookupBranchList
    },
    {
      title: "Duration",
      field: "duration",
      width: 200,
      dataType: "Date",
      render: ({ employeeID, placementID, timesheetID, duration }) => (
        <Link
          to={`/console/timesheets/view?employeeID=${employeeID}&placementID=${placementID}&timesheetID=${timesheetID}`}
        >
          <b><InactiveChip message={duration} /></b>
        </Link>
      )
    },
    {
      title: "Status",
      field: "status",
      width: 150,
      render: (row) => {
        const classPair = {
          approved: "chip-success",
          pending: "chip-warning",
          rejected: "chip-danger",
          "--": ""
        };
        if (row.status === "rejected")
          return (
            <RedChip message={"REJECTED"} leftIcon={<WarningIcon style={{ color: "white"}} />} tooltipContent={row.comment} />            
          );
        else  if (row.status === "approved")
          return (
            <ActiveChip message={"APPROVED"} rightIcon={<MdDone />}/>            
          );
        else  if (row.status === "pending")
        return (
          <InactiveChip message={"PENDING"} />            
        );
        return (
          // <Chip style={{
          //   backgroundColor: "#FFE1DB",
          //   color: "#db2828",
          // }}
          // label={message}
          // size="small" />
          <span className={`custom-chip ${classPair[row.status]}`}>
            {row.status}
          </span>
        );
      }
    },
    {
      title: "Submitted on",
      field: "submittedOn",
      width: 200,
      render: (row) => (
        <InfoChip message={row.submittedOn} />
      )
    },
    {
      title: "Approved At",
      field: "approvedAt",
      width: 200,
      render: (row) => (
        row.approvedAt ? <ActiveChip message={row.approvedAt} />
        :
        ""
      )
    },
    {
      title: "Timesheet reviewer",
      field: "actionBy",
      width: 175,
      dataType: "Numeric",
      render: (row) => {
        return (
          row.actionBy ? <GreyChip message={row.actionBy} />
          :
          ""
        );
      }
    },
    {
      title: "Total Billable hrs",
      field: "totalBillableHours",
      width: 150,
      dataType: "Numeric"
    },
    { title: "Total Submitted hrs",
      field: "hours",
      width: 170,
      dataType: "Numeric"
    },
    {
      title: "Attached file",
      field: "",
      width: 120,
      dataType: "string",
      render: (row) =>
        row.attachedFile !== "" ? (
          <div align="center">
            <a
              className="custom-chip text-underline c-pointer"
              target="_blank"
              href={row.attachedFile}
            >
              <ImFileText2 size={18} />
            </a>
          </div>
        ) : (
          ""
        )
    },
    {
      title: "Status Report",
      field: "statusReport",
      dataType: "String",
      width: 130,
      render: (row) => {
        return <StatusReportHoverCard statusReport={row.statusReport} />;
      }
    },
    // {
    //   title: "Comment",
    //   field: "comment",
    //   dataType: "string",
    // },
    {
      title: "Actions",
      field: "",
      width: 200,
      render: (row) => (
        <div className="d-flex">
          <ApproveTimesheet row={row} />
          <RejectTimesheet row={row} />
          <EditTimesheet {...row} />
          <NotifyDefaulter row={row} />
        </div>
      )
    }
  ];

  const approvedfiltercolumns = columns.filter((e) => e.title !== "Approved At");
  if (condition === "defaulterTimesheets") {
    const getRange = (range) => {
      if (range === 0) return "daily";
      else if (range === 1) return "Weekly";
      else if (range === 2) return "Bi-Weekly";
      else if (range === 3) return "Semi-Monthly";
      else if (range === 4) return "Monthly";
      return "";
    };
    columns.splice(6, 0, {
      title: "Timesheet Cycle",
      field: "timesheetCycle",
      render: ({ timesheetCycle = [{ range: "" }] }) => (
        <span>{getRange(timesheetCycle[0].range)}</span>
      )
    });
    columns.splice(1, 0, {
      title: "Phone Number",
      field: "employeePhone",
      dataType: Number,
      render: ({ employeePhone = [{ range: "" }] }) => (
        <span>{getRange(employeePhone[0].range)}</span>
      )
    });
  }
  const data =
    !timesheets_data.isLoading && !state.isSettingsLoading
      ? timesheets_data.data.reduce((init, item) => {
        console.log(item)
        const {
          employeeID,
          clientId,
          placementID,
          id,
          isApproved,
          isDefaulter,
          isRejected,
          startDate,
          endDate,
          createdAt,
          workdetails,
          attachmentDetails,
          rejectedDetails = {},
          invoiceDetails,
          approvedDetails = {},
          statusReport
        } = item;
        const duration =
          validation.dateFormatter(startDate) +
          "-" +
          validation.dateFormatter(endDate);
        const name = metaInfo.getEmployeeKey(employeeID, "name");
        const branch = metaInfo.getEmployeeKey(employeeID, "branch");
        init.push({
          employeeID: employeeID,
          empName: metaInfo.emailToName(employeeID),
          id: id,
          empPhoneNumber : employees[employeeID]?.phonenumber ?? "",
          empEmailId : employees[employeeID]?.email ?? "",
          clientName: metaInfo.clientIdToName(clientId),
          clientId: clientId,
          placementID: placementID,
          timesheetID: id,
          isApproved: isApproved,
          isRejected: isRejected,
          isDefaulter: isDefaulter,
          isInvoiced: invoiceDetails.isInvoiced,
          duration,
          submittedOn: validation.dateAndTimeFormatter(createdAt),
          approvedAt: isApproved? validation.dateAndTimeFormatter(approvedDetails.approvedAt):'',
          hours: TimesheetsUTILS.calc_hours([
            ...workdetails.OTtime,
            ...workdetails.standardTime
          ]),
          totalBillableHours: TimesheetsUTILS.getBillableHours(
            startDate,
            endDate
          ),
          attachedFile: attachmentDetails.publicURL,
          comment:
            "reason" in rejectedDetails
              ? parser(rejectedDetails.reason)
              : "",
          name,
          email: metaInfo.getEmployeeKey(employeeID, "email"),
          customFileName: `${name.replace(
            /\s/g,
            ""
          )}_${placementID}_${duration}`,
          branch: branch === undefined ? -1 : branchList.indexOf(branch),
          timesheetManager: state?.timesheetSettings[placementID]?.approvalBy || '',
          timesheetCycles: state?.timesheetSettings[placementID]?.cycle || '',
          status: isDefaulter
            ? "--"
            : !isApproved && !isRejected
              ? "pending"
              : isApproved && !isRejected
                ? "approved"
                : "rejected",
          actionBy: isApproved
            ? metaInfo.getEmployeeKey(approvedDetails.approvedBy, "name")
            : isRejected
              ? metaInfo.getEmployeeKey(rejectedDetails.rejectedBy, "name")
              : "",
          statusReport:statusReport
        });
        return init;
      }, [])
      : [];
  const [newColumns, setNewColumns] = React.useState([]);
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false
  });
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol);
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered
    });

    setNewColumns(newCol);
  };
       
  const NewExpenseAction = {
    icon:()=> <Link to="/console/expenseslist/expense/new"><Button className="btn btn-success px-4 btn-md text-high">New Expense</Button></Link>,
    tooltip: "New Expense",
    isFreeAction: true,
    position: "toolbar",
    }


  const NewTimesheetAction = {
      icon:()=> <Link to="/console/timesheets/new"><Button className="btn btn-success px-4 btn-md text-high">New Timesheet</Button></Link>,
      tooltip: "New Timesheet",
      isFreeAction: true,
      position: "toolbar",
    }


  return (
    <div>
      <FlairTable
        title="Timesheets Report"
        data={!filterBy.isFiltered ? data : filterBy.data}
        columns={condition === "submittedTimesheets" || condition === "rejectedTimesheets" ? approvedfiltercolumns : columns}
        isLoading={timesheets_data.isLoading || state.isSettingsLoading}
        actions={NewTimesheetAction}
        otherAction={NewExpenseAction}
      />
    </div>
  );
}

export default Presentation;