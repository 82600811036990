import React, { useState, useEffect } from "react";
import {
    Dialog,
    Typography,
    DialogContent,
    Button,
    DialogActions,
    IconButton,
} from "@material-ui/core";
import styles from "../../../../styles/dialogStyles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import SunEditor from "suneditor-react";
import { CgNotes } from "react-icons/cg";
import { connect } from "react-redux";
import { updatePlacement } from "../../middleware/index";
import { Link } from "react-router-dom";

function PlacementNotes(props) {
    const { rowData, _update_placement } = props;
    const { employeeid: employeeID, id: placementID } = rowData
    const [open, setOpen] = React.useState(false);
    const [placementNotes, setPlacementNotes] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
        setPlacementNotes(rowData?.placementNotes)
    };
    const handleClose = () => {
        setOpen(false);
        setPlacementNotes("");
    };

    const callback = () => { }

    const closeTab = (tab) => { }

    const handleNotes = async () => {
        let payload = {
            placementNotes
        }
        _update_placement(
            payload,
            "general",
            employeeID,
            placementID,
            callback,
            closeTab
        )
        handleClose();
    };

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html ?? "<p></p>";
        return tmp.textContent || tmp.innerText || "";
    }

    const placementNotesInText = stripHtml(rowData?.placementNotes)

    return (
        <div>
            {placementNotesInText.length > 1 ? (
                <Link style={{ color: "red" }} onClick={handleClickOpen}><CgNotes size={20} /></Link>
            ) : (
                <Link style={{ color: "grey" }} onClick={handleClickOpen}><CgNotes size={20} /></Link>
            )}
            <Dialog
                onClose={handleClose}
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    You are about to{" "}
                    {placementNotesInText.length > 1 ? (
                        <span>Update Notes for {rowData?.name}</span>
                    ) : (
                        <span>Add Notes for {rowData?.name}</span>
                    )}
                    ?
                </DialogTitle>
                <DialogContent dividers>
                    <SunEditor
                        placeholder="Please Enter Notes here.*"
                        onChange={(content) => setPlacementNotes(content)}
                        setContents={placementNotes}
                        height={200}
                        setOptions={{
                            buttonList: [
                                [
                                    "undo",
                                    "redo",
                                    "blockquote",
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                    "outdent",
                                    "indent",
                                    "list",
                                    "table",
                                    "link",
                                    "image",
                                    "fullScreen",
                                    "showBlocks",
                                    "print",
                                    "save",
                                ],
                            ],
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleNotes}
                        variant="contained"
                        color="primary"
                        disabled={placementNotes?.length < 1}
                    >
                        {placementNotesInText.length > 1 ? (
                            <span>Update Notes</span>
                        ) : (
                            <span>Add Notes</span>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        _update_placement: (
            payload,
            sectionName,
            uid,
            placementID,
            callback,
            closeTab
        ) => {
            dispatch(updatePlacement(
                payload,
                sectionName,
                uid,
                placementID,
                callback,
                closeTab
            ))
        }
    }
}

export default connect(null, mapDispatchToProps)(PlacementNotes)