import axios from "axios"
import {
  loadAllEmployeesRequest,
  loadAllEmployeesFailure,
  loadAllEmployeesSuccess,
  loadActiveEmployeesFailure,
  loadActiveEmployeesRequest,
  loadActiveEmployeesSuccess,
  loadInActiveEmployeesFailure,
  loadInActiveEmployeesRequest,
  loadInActiveEmployeesSuccess,
  loadSuspendedEmployeesRequest,
  loadSuspendedEmployeesFailure,
  loadSuspendedEmployeesSuccess,
  setListener,
  setStateAction,
  unSubscribeListenerAction,
} from "../actions/actionCreators"
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index"
import make_API_call from "../../../providers/REST_API"
import { configuration } from "../../../config/companyConfig"
import firebase from "../../../config/fbConfig"

export const setState = (obj) => (dispatch) => {
  return dispatch(setStateAction(obj))
}

export const unSubscribeListener = (type) => (dispatch, getState) => {
  const employeeListState = getState().employee.employeeList
  const unsubscribe = employeeListState[type].listener
  unsubscribe()
  return dispatch(unSubscribeListenerAction({ type }))
}
export const loadAllEmployees = () => (dispatch, getState, { getFirebase }) => {
  dispatch(loadAllEmployeesRequest())
  const subscribe = getFirebase()
    .firestore()
    .collection("EMPLOYEES")
    .where("isExist", "==", true)
    .onSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => doc.data())
        return dispatch(loadAllEmployeesSuccess(data))
      },
      (err) => {
        console.error(err)
        const msg = "Failed to load All Employees"
        errorMsg(msg)
        return dispatch(loadAllEmployeesFailure(msg))
      }
    )

  dispatch(setListener(subscribe, "allEmployees"))
}

export const loadActiveEmployees = () => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(loadActiveEmployeesRequest())
  const subscribe = getFirebase()
    .firestore()
    .collection("EMPLOYEES")
    .where("status", "==", "active")
    .where("isExist", "==", true)
    .onSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => doc.data())
        return dispatch(loadActiveEmployeesSuccess(data))
      },
      (err) => {
        console.error(err)
        const msg = "Failed to load Active Employees"
        errorMsg(msg)
        return dispatch(loadActiveEmployeesFailure(msg))
      }
    )

  dispatch(setListener(subscribe, "activeEmployees"))
}

export const loadInActiveEmployees = () => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(loadInActiveEmployeesRequest())
  const subscribe = getFirebase()
    .firestore()
    .collection("EMPLOYEES")
    .where("status", "==", "inactive")
    .where("isExist", "==", true)
    .onSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => doc.data())
        return dispatch(loadInActiveEmployeesSuccess(data))
      },
      (err) => {
        console.error(err)
        const msg = "Failed to load InActive Employees"
        errorMsg(msg)
        return dispatch(loadInActiveEmployeesFailure(msg))
      }
    )

  dispatch(setListener(subscribe, "inActiveEmployees"))
}

export const loadSuspendedEmployees = () => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(loadSuspendedEmployeesRequest())
  const subscribe = getFirebase()
    .firestore()
    .collection("EMPLOYEES")
    .where("status", "==", "suspended")
    .where("isExist", "==", true)
    .onSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => doc.data())
        return dispatch(loadSuspendedEmployeesSuccess(data))
      },
      (err) => {
        console.error(err)
        const msg = "Failed to load Suspended Employees"
        errorMsg(msg)
        return dispatch(loadSuspendedEmployeesFailure(msg))
      }
    )

  dispatch(setListener(subscribe, "suspendedEmployees"))
}

export const loadEmployeeSuspendedListData = async (employeeID) => {
  try {
    const snap = await firebase.firestore().collection(`EMPLOYEES/${employeeID}/WORK_QUEUE`)
      .doc("suspended_list_data")
      .get()
    return snap.data()
  } catch (err) {
    console.error(err)
    return []
  }
}

export const generateID = (email) => {
  waitingMsg("Generating the employee ID...")
  return make_API_call("put", `/employee/${email}/generateid`)
    .then((res) => {
      console.log(res)
      stopWaitMsg()
      successMsg(res.message)
    })
    .catch((err) => {
      console.error(err)
      stopWaitMsg()
      errorMsg(err.message)
    })
}

export const updateUserEmail = async ({ oldEmail, newEmail }) => {
	try {
    waitingMsg("Updating the employee email...");
		if (!configuration.isSelfHosted) {
      const tokenResponse = await make_API_call("get", "/auth/customSsoToken");
      const token = tokenResponse.token;
      await axios.put(
        `${configuration.sso.api}/auth/updateEmail`,
        { oldEmail, newEmail },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      await make_API_call("put", `/employee/updateEmail`, { oldEmail, newEmail });
    }
    stopWaitMsg();
    successMsg("Email updated successfully");
	} catch (error) {
    stopWaitMsg();
		console.error(error);
    if (error.response?.data?.message) {
      errorMsg(error.response.data.message);
    } else if (error.message) {
      errorMsg(error.message);
    } else {
      errorMsg("Failed to update email");
    }
	}
};

export const resetUserPassword = async ({ email, password }) => {
  try {
    waitingMsg("Resetting the employee password...");
    if (!configuration.isSelfHosted) {
      const tokenResponse = await make_API_call("get", "/auth/customSsoToken");
      const token = tokenResponse.token;
      await axios.put(
        `${configuration.sso.api}/auth/resetpassword?email=${email}`,
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      await make_API_call("put", `/auth/resetPassword`, { email, password });
    }
    stopWaitMsg();
    successMsg("Password reset successfully");
  } catch (error) {
    stopWaitMsg();
    console.error(error);
    if (error.response?.data?.message) {
      errorMsg(error.response.data.message);
    } else if (error.message) {
      errorMsg(error.message);
    } else {
      errorMsg("Failed to reset password");
    }
  }
};

export const resetUserTempPassword = async ({ password }) => {
  try {
    waitingMsg("Resetting your password...");
    const email = firebase.auth().currentUser.email;
    await make_API_call("put", `/auth/resetTempPassword`, { email, password });
    stopWaitMsg();
    successMsg("Password reset successfully");
    window.location.reload();
  } catch (error) {
    stopWaitMsg();
    console.error(error);
    if (error.message) {
      errorMsg(error.message);
    } else {
      errorMsg("Failed to reset password");
    }
  }
};