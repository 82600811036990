import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Presentation from "./Presentation";
import { initiateNewPlacement, updatePlacement } from "../../../../middleware";
import { useHistory } from "react-router-dom";
import { CalendarFormatter } from "../../../../../../shared/CalendarFormatter";

function Container(props) {
  const { placement, initiateNewPlacement, clients, updatePlacement } = props;
  const initState = {
    comments: "",
    billableClient: "",
    jobTitle: "",
    clientDomain : "",
    startDate: "",
    endDate: "",
    projectEndDate: "",
    netTerms: "",
    id: "",
    clients: [],
    paidWhenPaid: false,
    disableBtn: false
  };
  const [state, setState] = useState(initState);
  const history = useHistory();

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      comments: placement.description,
      billableClient: placement.clientId,
      jobTitle: placement.jobTitle,
      clientDomain: placement?.clientDomain,
      startDate: placement.startDate,
      endDate: placement.endDate,
      projectEndDate: placement.projectEndDate,
      netTerms: placement.netTerms.toString(),
      id: placement.id,
      paidWhenPaid: placement.hasOwnProperty("paidWhenPaid")
        ? placement.paidWhenPaid
        : false,
      clients
    }));
    // return () => setState(() => ({ ...initState }))
  }, [placement, clients.length]);

  const handleChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const callback = () => { };

  const handleSubmit = (e) => {
    e.preventDefault();
    //props.handleChange("panel0")
    const { closeTab } = props;
    console.log(state);
    const payload = {
      description: state.comments,
      clientId: state.billableClient,
      jobTitle: state.jobTitle,
      clientDomain: state?.clientDomain,
      startDate: CalendarFormatter.standardDateFormat(state.startDate),
      endDate: CalendarFormatter.standardDateFormat(state.endDate),
      projectEndDate: CalendarFormatter.standardDateFormat(
        state.projectEndDate
      ),
      netTerms: state.netTerms,
      employeeID: props.profile.uid,
      paidWhenPaid: state.paidWhenPaid
    };
    if (state.id) {
      console.log("update", payload);
      updatePlacement(
        payload,
        "general",
        props.profile.uid,
        state.id,
        callback,
        closeTab
      );
    } else {
      console.log("create");
      initiateNewPlacement({ ...payload }, history, closeTab);
    }
  };

  const handleKeyValuePair = (key, value) => {
    if (key !== "billableClient") {
      if (!isNaN(Date.parse(value))) {
        const date = new Date(value);
        date.setHours(0, 0, 0, 0);
        if (key === "endDate" && value) {
          setState((prevState) => ({
            ...prevState,
            projectEndDate: date.toISOString()
          }));
        }
        setState((prevState) => ({
          ...prevState,
          [key]: date.toISOString(),
          disableBtn: false
        }));
      } else {
        setState((prevState) => ({ ...prevState, disableBtn: true }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
        netTerms: clients
          .filter((client) => client.id === value)[0]
          .netTerms.toString()
      }));
    }
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    if (name === "paidWhenPaid")
      setState({ ...state, netTerms: "0", paidWhenPaid: checked });
    else
      setState({
        ...state,
        [name]: checked
      });
  };
  console.log("--------------GENERAL---------------");
  return (
    <Presentation
      {...state}
      setState={setState}
      handleChange={handleChange}
      handleKeyValuePair={handleKeyValuePair}
      handleSubmit={handleSubmit}
      handleCheck={handleCheck}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  const { data } = state.firestore;
  return {
		clients: data.clients_meta_info
			? Object.values(data.clients_meta_info)
			: [],
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initiateNewPlacement: (payload, history, closeTab) => {
      dispatch(initiateNewPlacement(payload, history, closeTab));
    },
    updatePlacement: (
      payload,
      sectionName,
      uid,
      placementID,
      callback,
      closeTab
    ) => {
      dispatch(
        updatePlacement(
          payload,
          sectionName,
          uid,
          placementID,
          callback,
          closeTab
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
