import React, { useContext, useState, useEffect } from 'react'
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { updateTimesheet } from "../../../../middleware"
import { Context } from "../../component_state/context"
import { useHistory } from "react-router-dom"

function Container(props) {
  const { _update_timesheet, loggedInEmployee, isInvoicePage } = props
  const [open, setOpen] = useState(false);
  const [state] = useContext(Context)
  const [disableBtn, setDisableBtn] = useState(true)
  const history = useHistory();

  // button disable conditions
  const standardTimeLength = state.standardTime.length
  const isDocRequired = state.timesheetSettings.attachMandatory
  const isDocUploading = state.isDocumentUploading
  const isDocUploaded = isDocRequired ? state.timesheetInfo.attachmentDetails?.publicURL.length > 0 : true
  const isStatusReport = state?.statusReport?.replace(/<[^>]+>/g, '').length;

  useEffect(() => {
    setDisableBtn(true)
    if ((standardTimeLength > 0) && isDocUploaded && !isDocUploading && isStatusReport>99)
      setDisableBtn(false)
  }, [standardTimeLength, isDocUploading, isDocUploaded,isStatusReport])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onUpdate = () => {
    handleClose();
    const { timesheetStartDate, timesheetEndDate, standardTime, OTtime, placementID, timesheetID, publicURL, fileExtension, sourcePath, timesheetInfo,statusReport } = state
    const timesheet = {
      ...timesheetInfo,
      workdetails: {
        standardTime: standardTime,
        OTtime: OTtime
      },
      statusReport
    }

    _update_timesheet(timesheet, state.employeeID, placementID, timesheetID, history, isInvoicePage ? false : true)
  }

  return (
    <div>
      <Presentation
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        state={state}
        disableBtn={disableBtn}
        onUpdate={onUpdate}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _update_timesheet: (payload, employeeID, placementID, timesheetID, history, isTimesheetPage) => dispatch(updateTimesheet(payload, employeeID, placementID, timesheetID, history, isTimesheetPage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)