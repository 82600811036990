import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import { Button } from "react-bootstrap";
import InviteEmployee from "../InviteEmployee/InviteDialog";
import { FaAddressCard, FaPhoneAlt } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { GoMailRead } from "react-icons/go";
import { MdDelete, MdLocationCity } from "react-icons/md";
import ResetPasswordDialog from "../ResetPassword";
import MetaInfo from "../../../../shared/getMetaInfo";
import { isBefore } from "date-fns";
import { Badge, Tooltip } from "@material-ui/core";
import EmployeeNotes from "../../../../shared/components/EmployeeNotes";
import GenerateID from "../GenerateID";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import FlairTable from "../../../../shared/FlairTable";
import EmployeeHoverCard from '../../../../shared/components/EmployeeHoverCard';
import {ImMoveUp} from "react-icons/im"

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const useStylesForList = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function Presentation(props) {
  const { branchList,onInviteAgain,onDelete,placements,staff_list,onMovingEmployee } = props
  console.log(staff_list,"staff_list")
  const metaInfo = new MetaInfo();

  const lookupBranchList = Object.assign({}, branchList);

  const classesList = useStylesForList();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(["Name", "Email", "Phone", "Branch", "Employee ID", "Date of Joining", "Reporting Manager", "Total Placements",]);
  const [right, setRight] = React.useState(["Active Placements", "Employe status", "Job Title", "Department", "Employee Home Address", "Category", "Payroll ID", "Employee Notes", "Status",]);
  const [openInviteEmployee, setOpenInviteEmployee] = React.useState(false)

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => {
        if (rowData.employeeid)
          return <EmployeeHoverCard employeeID={rowData.employeeid} />;
        else
          return ("");
      },
      dataType: "String",
    },
    {
      title: "Email", field: "email", dataType: "String",
      render: (rowData) => <div style={{ width: 250, paddingLeft: 5 }}>{rowData.email}</div>,
    },
    {
      title: "Phone", field: "phone", dataType: "String",
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}><FaPhoneAlt /> {rowData.phone}</div>,
    },
    {
      title: "Branch",
      field: "branch",
      dataType: "String",
      cellStyle: {
        width: 100,
        minWidth: 100
      },
      lookup: lookupBranchList,
    },
    {
      title: "Employee ID",
      field: "employeeid",
      defaultSort: "dsc",
      render: (rowData) => {
        if (rowData.employeeid)
          return (
            <div style={{ width: 130, paddingLeft: 5 }}>
              <Link style={{ color: "#3f51b5" }} to={"/console/employees/" + rowData.employeeid}><FaAddressCard size={24} /> <b>{rowData.employeeid}</b> </Link>
            </div>
          );
        else
          return ("");
      },
      dataType: "String",
    },
    {
      title: "Date of Joining",
      field: "joiningDate",
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.joiningDate}</div>,
      dataType: "Date",
    },
    {
      title: "Reporting manager",
      field: "reportingmanager",
      render: (rowData) => {
        if (rowData.reportingmanager)
          return (<div style={{ width: 200, paddingLeft: 5 }}>
            <table>
              <tr>
                <td><FcManager size={36} /></td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td><b>{rowData.reportingmanager}</b></td>
              </tr>
            </table>
          </div>);
        else
          return ("");
      }
      ,
      dataType: "String",
    },
    {
      title: "Total Placements",
      field: "totalPlacements",
      dataType: "String",
      render: (rowData) => {
        return (
          <Badge
            color="primary"
            showZero={false}
            badgeContent={rowData.totalPlacements}
          />
        );
      },
    },
    {
      title: "Active Placements",
      field: "activePlacements",
      dataType: "String",
      render: (rowData) => {
        return (
          <Badge
            color="primary"
            showZero={false}
            badgeContent={rowData.activePlacements}
          />
        );
      },
    },
    {
      title: "Employee status",
      field: "employeestatus",
      dataType: "String",
      cellStyle: {
        width: 150,
        minWidth: 150
      },
      lookup: {
        0: "Bench",
        1: "Working",
        2: "Training",
      },
    },
    {
      title: "Job title", field: "jobtitle",
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.jobtitle}</div>,
      dataType: "String",
    },
    {
      title: "Department",
      field: "department",
      dataType: "String",
      cellStyle: {
        width: 100,
        minWidth: 100
      },
      lookup: {
        0: "Java",
        1: "DevOps/Cloud",
        2: "Networking/Security",
        3: "Python",
        4: "QA",
        5: ".Net",
        6: "Data Science",
        7: "Big Data",
        8: "CRM",
        9: "Legal",
        10: "HR",
        11: "Accounts",
        12: "Bench sales",
      },
    },
    {
      title: "Employee Home Address",
      field: "employeeHomeAddress",
      dataType: "String",
      render: (rowData) => {
        if (rowData.employeeHomeAddress)
          return (
            <div style={{ width: 400, paddingLeft: 5 }}>
              <table>
                <tr>
                  <td><MdLocationCity size={36} /></td>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>{rowData.employeeHomeAddress}</td>
                </tr>
              </table>
            </div>
          )
        else
          return ("")
      },
    },
    {
      title: "Category",
      field: "category",
      dataType: "String",
      align: "center",
    },
    {
      title: "Payroll ID",
      field: "payrollid",
      dataType: "String",
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.payrollid}</div>,
    },
    {
      title: "Employee Notes",
      field: "empnotesIndexforLookup",
      dataType: "String",
      align: "center",
      lookup: {
        0: "Notes Available",
        1: "Notes Not Available",
      },
      render: (rowData) => {
        return <EmployeeNotes rowData={rowData} fetchActiveEmployees={() => { }} />
      },
    },
    {
      title: "Status",
      field: "status",
      dataType: "String",
      lookup: {
        0: "Inactive",
        1: "Active",
        2: "Suspended",
      },
      render: (rowData) => {
        if (rowData.status === 1)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "#21ba45",
                color: "white",
              }}
              label="Active"
            />
          );
        else if (rowData.status === 2)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "#db2828",
                color: "white",
              }}
              label="Suspended"
            />
          );
        return (
          <Chip
            style={{ width: "100%", backgroundColor: "orange", color: "white" }}
            label="Inactive"
          />
        );
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true;
        return value.includes(rowData.status.toString());
      },
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      render: (rowData) => {
        return (
          <span className="d-flex">
            {rowData.status === 0 ? (
              <span
                key={rowData.email}
                className="d-flex"
                style={{ marginTop: "8px" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onInviteAgain([rowData])}
                >
                  <GoMailRead className="c-pointer" size={18} />
                </Button>
                {/* If Id generated for inactive employee means EmployeeID will be passed or else emailid  */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to remove " + rowData.email + "?"
                      )
                    )
                      onDelete(rowData.employeeid ? rowData.employeeid : rowData.email);
                  }}
                >
                  <MdDelete className="c-pointer" size={18} />
                </Button>
                <GenerateID email={rowData.email} employeeid={rowData.employeeid}/>
              </span>
            ) : rowData.status === 1 ? (
              <span className="d-flex" style={{ marginTop: "8px" }}>
                <ResetPasswordDialog email={rowData.email} />
                <Tooltip title="Move to Employee">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onMovingEmployee(rowData.employeeid,{type:"employee"})}
                  >
                    <ImMoveUp className="c-pointer" size={18} />
                  </Button>
                </Tooltip>
              </span>
            ) : null }
          </span>
        );
      },

    },
  ];

  let data = [];
  staff_list.length &&
  staff_list.forEach((employee) => {
      if (employee.status !== "inactive") {
        const getTotalPlacements = () => placements.filter(p => p.employeeID === employee.uid && p.draft === false).length || 0
        const getActivePlacements = () => placements.filter(p => p.employeeID === employee.uid && p.draft === false && isBefore(new Date(), new Date(p.endDate)) && !(p?.closingReason?.length > 0)).length || 0
        const totalPlacements = getTotalPlacements()
        const activePlacements = getActivePlacements()
        const getEmployeeStatus = () => {
          if (totalPlacements === 0) {
            return employeeStatusList.indexOf("Training")
          } else if (totalPlacements > 0 && activePlacements > 0) {
            return employeeStatusList.indexOf("Working")
          } else {
            return employeeStatusList.indexOf("Bench")
          }
        }

        const getEmployeeAddress = (employeeAddress) => {
          return employeeAddress?.line1 + ", " +
                employeeAddress?.line2 + ", "  +
                employeeAddress?.city +", " +
                employeeAddress?.state +", " +
                employeeAddress?.country +" - " +
                employeeAddress?.zip
        }

        data.push({
          name: employee?.personal?.firstname + " " + employee?.personal?.lastname,
          email: employee?.email,
          phone: employee?.personal?.phonenumber,
          branch: branchList.indexOf(employee?.personal?.branch),
          employeeid: employee?.uid,
          reportingmanager: metaInfo.emailToName(
            employee?.personal?.reportingmanager
          ),
          employeestatus: getEmployeeStatus(),
          usertype: employee?.Role,
          jobtitle: employee?.personal?.jobtitle,
          department: departmentList.indexOf(employee?.personal?.department),
          status: statusList.indexOf(employee?.status),
          activePlacements: activePlacements,
          totalPlacements: totalPlacements,
          employeeHomeAddress: getEmployeeAddress(employee?.mailingaddress),
          joiningDate: employee?.dateofjoining,
          category: employee?.personal?.category,
          payrollid: employee?.personal?.payrollid,
          empnotesIndexforLookup: employee?.personal?.employeenotes ? 0 : 1,
          employeenotes: employee?.personal?.employeenotes
        });
      }
      else
        data.push({
          firstname: employee.personal.firstname,
          lastname: employee.personal.lastname,
          name: employee.personal.firstname + " " + employee.personal.lastname,
          department: departmentList.indexOf(employee.personal.department),
          email: employee.email,
          phone: employee.personal.phonenumber,
          branch: branchList.indexOf(employee.personal.branch),
          status: statusList.indexOf(employee.status),
          employeeBranch: employee.personal.branch,
          employeeid: employee?.uid,
        });
    });
  console.log(data);
  const [newColumns, setNewColumns] = React.useState([]);
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false,
  });
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol);
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered,
    });

    setNewColumns(newCol);
  };
  const filterColumns = columns.filter((e) => e.field !== "Checkbox");
  console.log(filterColumns);

  const suspendedFilterColumns = columns.filter((e) => e.title !== "Actions");

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [age, setAge] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenList = () => {
    setOpenList(true);
  };

  const handleCloseList = () => {
    setOpenList(false);
  };

  const actionIconStyles = makeStyles({
    button: {
      backgroundColor: '#3c52b2',
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#3c52b2',
        color: '#FFF',
      },
    }
  })
  const actionIconClasses = actionIconStyles()

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classesList.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classesList.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const tableActions = {
    icon: () => <Button className="btn btn-success px-4 btn-md text-high">Invite Employee</Button>,
    tooltip: "Invite employee",
    position: "toolbar",
    onClick: () => setOpenInviteEmployee(true)
  }

  return (
    <div style={{ overflowX: "auto" }}>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Filters for the Table</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Age</InputLabel>
              <Select
                native
                value={age}
                input={<Input id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">Age</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={age}
                input={<Input />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openList} onClose={handleCloseList}>
        <DialogContent>
          <form className={classesList.container}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classesList.root}
            >
              <Grid item>{customList('Hide Columns', left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="success"
                    size="large"
                    className={classesList.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="secondary"
                    size="large"
                    className={classesList.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList('Show Columns', right)}</Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseList} variant="success" >
            Cancel
          </Button>
          <Button onClick={handleCloseList} variant="success">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <FlairTable
        title="Employees Report"
        columns={columns}
        data={!filterBy.isFiltered ? data : filterBy.data}
        actions={tableActions}
      />

      <InviteEmployee
        template={false}
        heading="Invite new employee"
        text="Enter the email address of the new employee"
        hideBtn={true}
        open={openInviteEmployee}
        hideInviteEmployeeDialog={() => setOpenInviteEmployee(false)}
      />
    </div>
  );
}


export const departmentList = [
  "Java",
  "DevOps/Cloud",
  "Networking/Security",
  "Python",
  "QA",
  ".Net",
  "Data Science",
  "Big Data",
  "CRM",
  "Legal",
  "HR",
  "Accounts",
  "Bench sales",
];

export const employeeStatusList = ["Bench", "Working", "Training"];

export const statusList = ["inactive", "active", "suspended"];