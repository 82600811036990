import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialTable, { MTableToolbar } from "material-table";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
// import Alert from '@mui/material/Alert';

import Button from "@material-ui/core/Button";
import InviteEmployee from "../InviteEmployee/InviteDialog";
import { IoMdPersonAdd } from "react-icons/io";
import { FaAddressCard, FaColumns, FaFilter, FaPhoneAlt } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { GoMailRead } from "react-icons/go";
import { MdDelete, MdLocationCity } from "react-icons/md";

// import FilterBy from "../../generalcomponents/filterBy"
import ResetPasswordDialog from "../ResetPassword";
//import validate from "../../generalcomponents/validation"
import MetaInfo from "../../../../shared/getMetaInfo";
import CustomFilters from "../../../../shared/components/CustomFilter";
import { differenceInDays, isBefore } from "date-fns";
import { Avatar, Badge, IconButton, Tooltip, Typography } from "@material-ui/core";
import EmployeeNotes from "../../../../shared/components/EmployeeNotes";
import GenerateID from "../GenerateID";
import InactiveChip from "../../../../shared/lib/Chips/InactiveChip"; 
import ActiveChip from "../../../../shared/lib/Chips/ActiveChip"; 
import RedChip from "../../../../shared/lib/Chips/RedChip"; 
import WarningChip from "../../../../shared/lib/Chips/WarningChip"; 

import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Checkbox from '@material-ui/core/Checkbox';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import FlairTable from "../../../../shared/FlairTable";
import EmployeeHoverCard from '../../../../shared/components/EmployeeHoverCard';
import { ImMoveUp } from "react-icons/im"
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";
import { useSelector } from "react-redux";
import { Constants } from "../../../../shared/constants";
import ResetEmail from "../ResetEmail";
import ResetPassword from "../ResetPassword";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const useStylesForList = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function htmlToText(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const textContent = doc.body.textContent || '';
  return textContent;
}

export default function Presentation(props) {
  const {
    employeelist,
    condition,
    handleSelect,
    selected,
    handleSelectAll,
    onInviteAgain,
    onDelete,
    branchList,
    isLoading,
    placements,
    onMovingEmployee,
    getEndClientsForEmployee,
    employee_suspended_list_data    
  } = props;
  const metaInfo = new MetaInfo();

  console.log('new props',props)
  // } = props;

  // const [newColumns, setNewColumns] = useState([])
  // const [filterBy, setFilterBy] = useState({ data: [], isFiltered: false })
  // const handleChange = (filteredData, isFiltered, newCol) => {
  // 	setFilterBy({
  // 		data: filteredData,
  // 		isFiltered: isFiltered,
  // 	})

  // 	setNewColumns(newCol)
  // }

  //

  const lookupBranchList = Object.assign({}, branchList);
  const formStatus = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.['i-9form']?.fields?.filter((field) => field.name === "status")?.[0]?.values || [])
  const classesList = useStylesForList();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(["Name", "Email", "Phone", "Branch", "Employee ID", "End Client", "Date of Joining", "Reporting Manager", "Total Placements",]);
  const [right, setRight] = React.useState(["Active Placements", "Employe status", "Job Title", "Department", "Employee Home Address", "Category", "Payroll ID", "Employee Notes", "Status",]);
  const [openInviteEmployee, setOpenInviteEmployee] = React.useState(false)

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right)
  const columns = [
    {
      title: "Name",
      field: "name",
      width: 250,
      render: (rowData) => {
        if (rowData.employeeid)
          return <EmployeeHoverCard employeeID={rowData.employeeid} />;
        else
          return (
            <Typography>
              <table style={{ width: 250 }}>
                <tr>
                  <td>
                    <Avatar
                      style={{ backgroundColor: "red" }}
                      src={rowData?.imageURL}
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    {rowData?.name}
                  </td>
                </tr>
              </table>
            </Typography>
          );
      },
      dataType: "String",
    },
    {
      title: "Email", field: "email", dataType: "String",
      width: 150,
      render: (rowData) => <div style={{ width: 250, paddingLeft: 5 }}>{rowData.email}</div>,
    },
    {
      title: "Phone", field: "phone", dataType: "String",
      width: 150,
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}><FaPhoneAlt /> {rowData.phone}</div>,
    },
    {
      title: "Branch",
      field: "branch",
      dataType: "String",
      width: 100,
      lookup: lookupBranchList,

    },
    {
      title: "Employee ID",
      field: "employeeid",
      defaultSort: "dsc",
      width: 150,
      render: (rowData) => {
        if (rowData.employeeid)
          return (
            <div style={{ width: 130, paddingLeft: 5 }}>
              <Link style={{ color: "#3f51b5" }} to={"/console/employees/" + rowData.employeeid}><FaAddressCard size={24} /> <b>{rowData.employeeid}</b> </Link>
            </div>
          );
        return (
          <div style={{ width: 130, paddingLeft: 5 }}>
            
          </div>
        );
      },
      dataType: "String",

    },
    {
      title: "End Client",
      field: "getEndClientsForEmployee",
      render: (rowData) => {
        const { placements, clients, getEndClientsForEmployee } = props;
        const endClients = getEndClientsForEmployee(rowData.employeeid, placements, clients);
        return (
          <div>
            {endClients.map((client, index) => (
              <div key={index}>{client}</div>
            ))}
          </div>
        );
      },      
      
      dataType: "String",
      filtering: false,

    },
    {
      title: "Date of Joining",
      field: "joiningDate",
      width: 140,
      render: (rowData) => <div style={{ paddingLeft: 5 }}>{rowData.joiningDate}</div>,
      dataType: "Date",

    },
    { title: "Work Authorization", width: 170, field: "workauth" },
    { title: "Work authorization start date", field: 'workAuthStartDate', width: 240 },
    { title: "Work authorization end date", field: 'workAuthEndDate', width: 240 },
    {
      title: "Work Authorization Expiry",
      field: "workauthExp",
      width: 200,
      dataType: "String",
      render: (rowData) => {
        const remainingDaysString = rowData.workauthExp;
        
        return (
          <div align="center" style={{ paddingLeft: 5 }}>
            {remainingDaysString ? ( 
              <WarningChip message={remainingDaysString}/>
            ) : (
              <span></span>
            )}
          </div>
        );
      },
      
    },    
    { title: "I9", width: 100, field: "i9form" },
    { title: "E-Verify", width: 100, field: "everify" },
    {
      title: "Reporting manager",
      field: "reportingmanager",
      width: 200,
      render: (rowData) => {
        if (rowData.reportingmanager)
          return (<div style={{ paddingLeft: 5 }}>
            <table>
              <tr>
                <td><FcManager size={24} /></td>
                <td>&nbsp;</td>
                <td><b>{rowData.reportingmanager}</b></td>
              </tr>
            </table>
          </div>);
       return (
        <div style={{ paddingLeft: 5 }}>
          
        </div>
      );
      }
      ,
      dataType: "String",
    },
    {
      title: "Total Placements",
      field: "totalPlacements",
      dataType: "String",
      width: 130,
      render: (rowData) => {
        return (
          <div align="center">
            <Badge
            color="primary"
            showZero={false}
            badgeContent={rowData.totalPlacements}
          />
          </div>
        );
      },

    },
    {
      title: "Active Placements",
      field: "activePlacements",
      dataType: "String",
      width: 130,
      // hidden: activePlacementsState,
      render: (rowData) => {
        return (
          <div align="center">
          <Badge
            color="primary"
            showZero={false}
            badgeContent={rowData.activePlacements}
          />
          </div>
        );
      },

    },
    {
      title: "Employee status",
      field: "employeeWorkStatus",
      dataType: "String",
      width: 150,
      lookup: {
        0: "Bench",
        1: "Working",
        2: "Training",
        3: "Prospect"
      },

    },
    {
      title: "Job title", field: "jobtitle",
      width: 150,
      render: (rowData) => <div style={{ paddingLeft: 5 }}>{rowData.jobtitle}</div>,
      dataType: "String",
    },
    {
      title: "Department",
      field: "department",
      dataType: "String",
      width: 150,
      lookup: {
        0: "Java",
        1: "DevOps/Cloud",
        2: "Networking/Security",
        3: "Python",
        4: "QA",
        5: ".Net",
        6: "Data Science",
        7: "Big Data",
        8: "CRM",
        9: "Legal",
        10: "HR",
        11: "Accounts",
        12: "Bench sales",
      },

    },
    {
      title: "Employee Home Address",
      field: "employeeHomeAddress",
      dataType: "String",
      width: 200,
      // hidden: true,
      // hiddenByColumnsButton: true,
      render: (rowData) => {
        if (rowData.employeeHomeAddress) {
          if (rowData.employeeHomeAddress !== "") {
            return (
              <div style={{ width: 400, paddingLeft: 5 }}>
                <table>
                  <tr>
                    <td><MdLocationCity size={24} /></td>
                    <td>&nbsp;</td>
                    <td>{rowData.employeeHomeAddress}</td>
                  </tr>
                </table>
              </div>
            );
          }
        } else {
          return (
            <div align="center" style={{ width: 130, paddingLeft: 5 }}>
              --
            </div>
          );
        }
      },      
    },
    {
      title: "Category",
      field: "category",
      width: 100,
      dataType: "String",
      align: "center",
    },
    {
      title: "Payroll ID",
      field: "payrollid",
      width: 100,
      dataType: "String",
      // hidded: payrollState,
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.payrollid}</div>,
    },
    {
      title: "Notes",
      field: "empnotesIndexforLookup",
      dataType: "String",
      width: 80,
      align: "center",
      lookup: {
        0: "Notes Available",
        1: "Notes Not Available",
      },
      render: (rowData) => {
        return <div align="center"><EmployeeNotes rowData={rowData} fetchActiveEmployees={() => { }} /></div>
      },

    },
    {
      title: "Exit Date",
      field: "exitDate",
      dataType: "String",
      width: 100,
      render: (row) => {
				const { suspendedReasonsData } = row;
				return (
					<table className="table table-bordered table-hover table-sm">
						<tbody>
							{suspendedReasonsData?.map((item) => {
								return (
									<tr className="p-0" key={item?.id}>
										<td className="border-0">
											{item?.updatedAt}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				);
			},
    },
    {
			title: "Action Type - Action By",
			field: "suspendedReasons",
      dataType: "String",
      hide:true,
			render: (row) => {
				const { suspendedReasonsData } = row;
				return (
					<table className="table table-bordered table-hover table-sm">
						<tbody>
							{suspendedReasonsData?.map((item) => {
								return (
									<tr className="p-0" key={item?.id}>
										<td className="border-0">
											{item?.title}
										</td>
										<td className="border-0">{metaInfo.emailToName(item?.updatedBy)}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				);
			},
		},
    {
			title: "Action Reasons",
			field: "suspendedActionReasons",
      dataType: "String",
      hide:true,
			render: (row) => {
				const { suspendedReasonsData } = row;
				return (
					<table className="table table-bordered table-hover table-sm">
						<tbody>
							{suspendedReasonsData?.map((item) => {
								return (
									<tr className="p-0" key={item?.id}>
										<td className="border-0">{htmlToText(item?.reason)}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				);
			},
		},
    {
      title: "Status",
      field: "status",
      dataType: "String",
      width: 100,
      lookup: {
        0: "Inactive",
        1: "Active",
        2: "Suspended",
        3: "OnHold",
      },
      render: (rowData) => {
        if (rowData.status === 1)
          return (
            <ActiveChip message="Active" />
          );
        else if (rowData.status === 2)
          return (
            <RedChip message="Exit" />
          );
        else if (rowData.status === 3)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "#fcb532",
                color: "white",
              }}
              label="OnHold"
              size="small"
            />
          );
        return (
          <InactiveChip message="Inactive" />
        );
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true;
        return value.includes(rowData.status.toString());
      },

    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      width: 220,
      render: (rowData) => {
        return (
          <span className="d-flex">
            {rowData.status === 0 && (
              <span
                key={rowData.email}
                className="d-flex"
              >
                {/* email send */}
                <Tooltip title="send Invite">
                  <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} onClick={() => onInviteAgain([rowData])}><GoMailRead size={20} /></Link>
                </Tooltip>

                {/* delete icon */}
                <Tooltip title="Delete Employee">
                  <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to remove " + rowData.email + "?"
                        )
                      )
                        onDelete(rowData.employeeid ? rowData.employeeid : rowData.email);
                    }}><MdDelete size={20} /></Link>
                  </Tooltip>

                {/* If Id generated for inactive employee means EmployeeID will be passed or else emailid  */}
                <GenerateID email={rowData.email} employeeid={rowData.employeeid} />
              </span>
            )}
            {rowData.status === 1 && (
              <span className="d-flex" style={{ marginTop: "8px" }}>
                <ResetPassword email={rowData.email} />
                <Tooltip title="Move to Staff">
                  <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} onClick={() => onMovingEmployee(rowData.employeeid, { type: "staff" })}><ImMoveUp size={20} /></Link>
                </Tooltip>
                <ResetEmail oldEmail={rowData.email} />
              </span>
            )}
            {//In All tab Suspended Employee Actions Removed

              // : rowData.status === 2 ? (
              //   <span className="d-flex" style={{ marginTop: "8px" }}>
              //     <Button
              //       variant="outlined"
              //       color="primary"
              //       onClick={() => {
              //         if (
              //           window.confirm(
              //             "Are you sure you want to remove " + rowData.email + "?"
              //           )
              //         )
              //           onDelete(rowData.email);
              //       }}
              //     >
              //       <MdDelete className="c-pointer" size={18} />
              //     </Button>
              //   </span>
              // )
              //null
            }
          </span>
        );
      },

    },
  ];

  let data = [];
  let _employee_list = employeelist.length && employeelist.filter(e => e.type === undefined || e.type !== "staff")
  _employee_list.length &&
    _employee_list.forEach((employee) => {

      const requiredWorkAuth = (() => {
        const workauthArray = employee.workauth;
        if (!workauthArray) {
            return {};
        }
        const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
        if (activeWorkAuth) {
            return activeWorkAuth;
        }  
        if (!employee.workauth || !employee.inactiveWorkAuthData) {
            return {};
        }
        const matchingWorkAuth = workauthArray.find((auth) => auth.id === employee.inactiveWorkAuthData.id);
        
        return matchingWorkAuth;
      })()

      if (employee.status === "active" || employee.status === "suspended") {
        const getTotalPlacements = () => placements.filter(p => p.employeeID === employee.uid && p.draft === false).length || 0
        const getActivePlacements = () => placements.filter(p => p.employeeID === employee.uid && p.draft === false && isBefore(new Date(), new Date(p.endDate)) && !(p?.closingReason?.length > 0)).length || 0
        const totalPlacements = getTotalPlacements()
        const activePlacements = getActivePlacements()
        // const getEmployeeStatus = () => {
        //   if (totalPlacements === 0) {
        //     return employeeStatusList.indexOf("Training")
        //   } else if (totalPlacements > 0 && activePlacements > 0) {
        //     return employeeStatusList.indexOf("Working")
        //   } else {
        //     return employeeStatusList.indexOf("Bench")
        //   }
        // }

        const getEmployeeAddress = (employeeAddress) => {
          return [employeeAddress?.line1,
            employeeAddress?.line2,
            employeeAddress?.city,
            employeeAddress?.state,
            employeeAddress?.country,
            employeeAddress?.zip].filter((i) => i).join(', ')
        }

        const suspendedEmployeeData = employee_suspended_list_data.find((e) => e.employeeID === employee.uid)

        data.push({
          //name: employee?.personal?.firstname + employee?.personal?.lastname,
          name: [
              employee?.personal?.firstname?.trim(),
              employee?.personal?.middlename?.trim(),
              employee?.personal?.lastname?.trim(),
            ]
              .filter((x) => x)
              .join(" "),
            email: employee?.email,
            phone: employee?.personal?.phonenumber,
            branch: branchList.indexOf(employee?.personal?.branch),
            employeeid: employee?.uid,
            reportingmanager: metaInfo.emailToName(
              employee?.personal?.reportingmanager
            ),
          // employeestatus: getEmployeeStatus(),
            usertype: employee?.Role,
            employeeWorkStatus: employeeStatusList.indexOf(
              employee.employeeStatus
            ),
            jobtitle: employee?.personal?.jobtitle,
            department: departmentList.indexOf(employee?.personal?.department),
            status: statusList.indexOf(employee?.status),
            exitDate: suspendedEmployeeData?.reasons?.map(
							(i) => `${CalendarFormatter.standardDateFormat(i?.updatedAt)}`
						)
						.join(", "),
            suspendedReasons: suspendedEmployeeData?.reasons?.map(
							(i) => `${i?.title} - ${metaInfo.emailToName(i?.updatedBy)}`
						)
						.join(", "),
            suspendedActionReasons:suspendedEmployeeData?.reasons?.map(
							(i) => `${htmlToText(i?.reason)}`
						)
						.join(", "),
            suspendedReasonsData : suspendedEmployeeData?.reasons,
            activePlacements: activePlacements,
            totalPlacements: totalPlacements,
            employeeHomeAddress: getEmployeeAddress(employee?.mailingaddress),
            joiningDate: CalendarFormatter.standardDateFormat(
              employee?.personal?.dateofjoining
            ),
            workauth: (() => {
              const workauthArray = employee.workauth;
              if (!workauthArray) {
                  return '';
              }
              const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
              if (activeWorkAuth) {
                  return activeWorkAuth.work_type;
              }  
              if (!employee.workauth || !employee.inactiveWorkAuthData) {
                  return '';
              }
              const matchingWorkAuth = workauthArray.find((auth) => auth.id === employee.inactiveWorkAuthData.id);
              if (matchingWorkAuth) {
                  return matchingWorkAuth.work_type;
              }
              return '';
          })(),        
    
            workauthExp: (() => {
              if (!employee.workauth || !employee.inactiveWorkAuthData) {
                return ''; 
              }
              const workauthArray = employee.workauth;
              const inactiveWorkAuthData = employee.inactiveWorkAuthData;
              const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
              const matchingWorkAuth = workauthArray.find(
                (auth) => auth.id === inactiveWorkAuthData.id
              );
              if (activeWorkAuth){
                return '';
              }
             else if (matchingWorkAuth) {
                const presentDate = new Date();
                const expiryDate = new Date(matchingWorkAuth.work_exp);
                const daysDiff = differenceInDays(expiryDate, presentDate);
                const remainingDaysString =` ${Math.abs(daysDiff).toString()} days`;
                return remainingDaysString;
              } 
            })(), 
            workAuthStartDate: requiredWorkAuth?.work_issue ?? '',
            workAuthEndDate: requiredWorkAuth?.work_exp ?? '',   
            i9form : employee?.['i-9form']?.find((item) => {
              return item?.status === formStatus?.[0] // active
            })?.initiatedon,
            everify : employee?.['e-verification']?.[0]?.['e-verifeddate'],
            category: employee?.personal?.category,
            payrollid: employee?.personal?.payrollid,
            empnotesIndexforLookup: employee?.personal?.employeenotes ? 0 : 1,
            employeenotes: employee?.personal?.employeenotes,
            type: employee?.type,
          });
      }
      else {
        data.push({
          firstname: employee.personal.firstname,
          lastname: employee.personal.lastname,
          name: [
            employee?.personal?.firstname?.trim(),
            employee?.personal?.middlename?.trim(),
            employee?.personal?.lastname?.trim(),
          ]
            .filter((x) => x)
            .join(" "),
          department: departmentList.indexOf(employee.personal.department),
          email: employee.email,
          employeeWorkStatus: employeeStatusList.indexOf(
            employee.employeeStatus
          ),
          reportingmanager: metaInfo.emailToName(
            employee?.personal?.reportingmanager
          ),
          joiningDate: CalendarFormatter.standardDateFormat(
            employee?.personal?.dateofjoining
          ),
          category: employee?.personal?.category,
          payrollid: employee?.personal?.payrollid,
          workauth: (() => {
            const workauthArray = employee.workauth;
            if (!workauthArray) {
                return '';
            }
            const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
            if (activeWorkAuth) {
                return activeWorkAuth.work_type;
            }  
            if (!employee.workauth || !employee.inactiveWorkAuthData) {
                return '';
            }
            const matchingWorkAuth = workauthArray.find((auth) => auth.id === employee.inactiveWorkAuthData.id);
            if (matchingWorkAuth) {
                return matchingWorkAuth.work_type;
            }
            return '';
        })(),        
        requiredWorkAuth: (() => {
          const workauthArray = employee.workauth;
          if (!workauthArray) {
              return {};
          }
          const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
          if (activeWorkAuth) {
              return activeWorkAuth;
          }  
          if (!employee.workauth || !employee.inactiveWorkAuthData) {
              return {};
          }
          const matchingWorkAuth = workauthArray.find((auth) => auth.id === employee.inactiveWorkAuthData.id);
          
          return matchingWorkAuth;
        })(),
          workauthExp: (() => {
            if (!employee.workauth || !employee.inactiveWorkAuthData) {
              return ''; 
            }
            const workauthArray = employee.workauth;
            const inactiveWorkAuthData = employee.inactiveWorkAuthData;
            const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
            const matchingWorkAuth = workauthArray.find(
              (auth) => auth.id === inactiveWorkAuthData.id
            );
            if (activeWorkAuth){
              return '';
            }
           else if (matchingWorkAuth) {
              const presentDate = new Date();
              const expiryDate = new Date(matchingWorkAuth.work_exp);
              const daysDiff = differenceInDays(expiryDate, presentDate);
              const remainingDaysString =` ${Math.abs(daysDiff).toString()} days`;
              return remainingDaysString;
            } 
          })(), 
          i9form : employee?.['i-9form']?.find((item) => {
            return item?.status === formStatus?.[0] // active
          })?.initiatedon,
          everify : employee?.['e-verification']?.[0]?.['e-verifeddate'],
          jobtitle: employee?.personal?.jobtitle,
          phone: employee.personal.phonenumber,
          branch: branchList.indexOf(employee.personal.branch),
          status: statusList.indexOf(employee.status),
          employeeBranch: employee.personal.branch,
          employeeid: employee?.uid,
          type: employee?.type,
        });
      }
    });
  console.log(data);
  const [newColumns, setNewColumns] = React.useState([]);
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false,
  });
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol);
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered,
    });

    setNewColumns(newCol);
  };
  const filterColumns = columns.filter((e) => e.field !== "Checkbox");
  console.log(filterColumns);

  const suspendedFilterColumns = columns.filter((e) => e.title !== "Actions");

  console.log("condition😅🚀", condition);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [age, setAge] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenList = () => {
    setOpenList(true);
  };

  const handleCloseList = () => {
    setOpenList(false);
  };

  const actionIconStyles = makeStyles({
    button: {
      backgroundColor: '#3c52b2',
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#3c52b2',
        color: '#FFF',
      },
    }
  })
  const actionIconClasses = actionIconStyles()

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classesList.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classesList.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const tableActions = {
    icon: () => <button className="btn btn-success px-6 btn-md text-high ">Invite Employee</button>,
    tooltip: "Invite employee",
    isFreeAction: true,
    position: "toolbar",
    onClick: () => setOpenInviteEmployee(true)
  }

  const getRowHeight = React.useCallback((params) => {
		const { data } = params;
		let maxHeight = 43;
		const { suspendedReasonsData } = data;
		const suspendedReasonsDataLength = suspendedReasonsData?.length ?? 0;

		if (suspendedReasonsDataLength > 1) {
			const height = 43 * suspendedReasonsDataLength;
			maxHeight = height > maxHeight ? height : maxHeight;
		}

		return maxHeight;
	}, []);

  return (
    <div>
      <FlairTable
        title="Employees Report"
        columns={condition === 3 ? suspendedFilterColumns : columns}
        data={!filterBy.isFiltered ? data : filterBy.data}
        isLoading={isLoading}
        actions={tableActions}
        getRowHeight={getRowHeight}
      />

      <InviteEmployee
        template={false}
        heading="Invite new employee"
        text="Enter the email address of the new employee"
        hideBtn={true}
        open={openInviteEmployee}
        hideInviteEmployeeDialog={() => setOpenInviteEmployee(false)}
      />
    </div>
  );
}


export const departmentList = [
  "Java",
  "DevOps/Cloud",
  "Networking/Security",
  "Python",
  "QA",
  ".Net",
  "Data Science",
  "Big Data",
  "CRM",
  "Legal",
  "HR",
  "Accounts",
  "Bench sales",
];

export const employeeStatusList = ["Bench", "Working", "Training", "Prospect"];

export const statusList = ["inactive", "active", "suspended", "onHold"];