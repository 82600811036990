import React from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import DeleteModal from "../DeleteModal/delete";
import SkillsEditModal from "./skills";

const Skills = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowDel, delModalShow] = React.useState(false);
  const { data, employeeID , isPersonal} = props;
  const [index, setIndex] = React.useState(undefined);

  const handleEdit = (idx) => {
    setModalShow(true);
    setIndex(idx);
  };

  const handleAdd = () => {
    setModalShow(true);
    setIndex(undefined);
  };

  const dispatch = useDispatch();

  const handleDelete = (idx) => {
    delModalShow(true);
    setIndex(idx);
  };

  const handleDeleteData = (index) => {
    if (index > -1) {
      data.splice(index, 1);
    }
    delModalShow(false);
    let skills = data?.map((item) => item);
    const payload = {
      skills,
    };
    setModalShow(false);
    dispatch(updateProfile(payload, employeeID, payload));
  };
  return (
    <>
      <SkillsEditModal
        show={modalShow}
        employeeid={employeeID}
        skillsdata={data}
        idx={index}
        onHide={() => setModalShow(false)}
      />
      <DeleteModal
        show={modalShowDel}
        index={index}
        handleDelete={() => handleDeleteData(index)}
        onHide={() => delModalShow(false)}
      />
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <h5 className="card-title mb-3 header-title">Skills</h5>
            {
              isPersonal?
              <></>
              :
              <Link
              to="#"
              className="linkbtn me-1 mt-0"
              onClick={() => handleAdd()}
            >
              <i className="fa-solid fa-square-plus fs-17"></i>
            </Link>
            }
           
          </div>
          <div className="emplbg">
            <div className="d-block d-lg-flex justify-content-between my-1">
              <div className="listskills">
                {data?.map((res, idx) => {
                  return (
                    <div>
                      <div className="skills">
                        <span>{res?.skill} </span>
                        {
                          isPersonal?
                          <></>
                          :
                          <>
                        <Link to="#" onClick={() => handleEdit(idx)} className="px-2">
                          <i className="fa-solid fa-pen fs-17"></i>
                        </Link>
                        <Link
                          to="#"
                          style={{ color: "#F05C50" }}
                          className="mr-4"
                          onClick={() => handleDelete(idx)}
                        >
                          <i className="fa-regular fa-trash-can fs-17"></i>
                        </Link>
                          </>
                        }  
                        </div>
                      <div className="d-flex mt-2 mt-lg-0"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default Skills;
