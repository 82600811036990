import React, { useState } from 'react'
import BackspaceIcon from '@material-ui/icons/Backspace';
import { Dialog, Typography, DialogContent, DialogActions, IconButton, Tooltip, CircularProgress, FormHelperText, TextField } from "@material-ui/core"
import styles from "../../../styles/dialogStyles"
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { HiOutlineBan } from "react-icons/hi"
import { useInvoiceListContext } from "../context";
import SunEditor from "suneditor-react"
import { Link } from "react-router-dom" 
import { Button } from 'react-bootstrap';


function VoidInvoice(props) {
  const { invoiceID, isVoid, isPaymentDone, isPreviewVoid, invoicePreviewClose } = props
  const { services } = useInvoiceListContext()
  const [open, setOpen] = React.useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [voidReason, setVoidReason] = useState('')

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = async () => {
    try {
      setShowSpinner(true)
      handleClose()
      await services.makeInvoiceVoid(invoiceID, voidReason)
      setShowSpinner(false)
      if (isPreviewVoid) {
        invoicePreviewClose()
      }
    } catch (error) {
      setShowSpinner(false)
    }
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      {
        showSpinner ? (
          <CircularProgress
            style={{ marginTop: "12px", marginLeft: "11px" }}
            size={18}
          />
        ) :
          !isPreviewVoid ? (
            <Tooltip title="VOID">
              <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} hidden={isVoid || isPaymentDone} onClick={handleClickOpen}>
                <HiOutlineBan size={20} /></Link>
            </Tooltip>   
          ) : (
            <Button
              onClick={handleClickOpen}
              hidden={isVoid || isPaymentDone}
              className='btn-info btn-md px-6 text-high'
            >
              <span>
                <HiOutlineBan style={{ color: "white" }} /> Void
              </span>
            </Button>
          )}

      <Dialog
        onClose={handleClose}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          You are about to void{" "}
          <span className="custom-chip chip-danger">{`${invoiceID}`}</span> ?
        </DialogTitle>
        <DialogContent dividers>
          <SunEditor
            placeholder="Remarks*"
            onChange={(content) => setVoidReason(content)}
            setContents={voidReason}
            height={200}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "outdent",
                  "indent",
                  "list",
                  "table",
                  "link",
                  "image",
                  "fullScreen",
                  "showBlocks",
                  "print",
                  "save",
                ],
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleYes}
            hidden={isVoid || isPaymentDone}
            // disabled={isVoiding}
            variant="contained"
            color="primary"
            disabled={voidReason.length < 1}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default VoidInvoice
