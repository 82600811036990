import React from "react";
import { Chip, IconButton, Avatar, Tooltip } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import validate from "../../../../../shared/validation";
import MetaInfo from "../../../../../shared/getMetaInfo";
import CustomFilters from "../../../../../shared/components/CustomFilter";
import MaterialTable, { MTableToolbar } from "material-table";
import { TABLE_ICONS } from "../../../../../shared/TableIcons";
import { differenceInDays } from 'date-fns'
import EmployeeNotes from "../../../../../shared/components/EmployeeNotes";
import { MdLocationCity } from "react-icons/md";
import { FaEdit, FaAddressCard} from "react-icons/fa";

import FlairTable from "../../../../../shared/FlairTable";
import EmployeeHoverCard from "../../../../../shared/components/EmployeeHoverCard";
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter";
import InactiveChip from "../../../../../shared/lib/Chips/InactiveChip"; 
import ActiveChip from "../../../../../shared/lib/Chips/ActiveChip"; 
import RedChip from "../../../../../shared/lib/Chips/RedChip"; 
import WarningChip from "../../../../../shared/lib/Chips/WarningChip"; 
import { List } from "semantic-ui-react";
import PlacementNotes from "../PlacementNotes";
import PlacementAudit from "../PlacementAudit";
import parser from "html-react-parser"

function Presentation(props) {
  const {
    clientView,
    listAll,
    employeeId,
    clientId,
    placementsList = [],
    deletePlacement,
    projects_meta_info = {},
    branchList,
    employeeList,
    fetchActiveEmployees,
    getEndClientNames,
    NewPlacementAction,
    payment_settings_list,
    worklocation_settings_list,
    clients_contacts_list,
    recruitment_details_settings_list
  } = props;

  const metaInfo = new MetaInfo();
  const lookupBranchList = Object.assign({}, branchList);

  const getAboutToExpire = (projectEndDate, closingReason) => {
    let days = differenceInDays(new Date(projectEndDate), new Date())
    if (closingReason)
      return ""
    else if (days <= 30)
      return days
    else
      return ""
  }

  const columns = [
    {
      title: "Employee Name",
      field: "name",
      render: (rowData) => {
        if (rowData.employeeid)
          return <EmployeeHoverCard employeeID={rowData.employeeid} />;
        else
          return ("");
      },
      dataType: "String",
      width: 300,
    },{
      title: "Employee ID",
      field: "employeeid",
      dataType: "String",
      render: (rowData) => {
        if (rowData.employeeid)
          return (
            <div style={{ width: 130, paddingLeft: 5 }}>
              <Link style={{ color: "#3f51b5" }} to={"/console/employees/" + rowData.employeeid}><FaAddressCard size={24} /> <b>{rowData.employeeid}</b> </Link>
            </div>
          );
        return (
          <div style={{ width: 130, paddingLeft: 5 }}>
            
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Branch",
      field: "branch",
      dataType: "",
      lookup: lookupBranchList,
      width: 100,
    },
    {
      title: "Consultant type",
      field: "category",
      lookup: { 0: "--", 1: "Employee", 2: "Contracts" },
      dataType: "",
      hidden: true,
      width: 150,
    },
    {
      title: "Job Title",
      field: "jobTitle",
      dataType: "String",
      width: 150,
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.jobTitle}</div>,
    },
    {
      title: "Date of Joining",
      field: "joiningDate",
      dataType: "Date",
      width: 150,
      render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.joiningDate}</div>,
    },
    {
      title: "Placement code",
      field: "placementID",
      width: 150,
      render: (rowData) => {
        if (rowData?.draftIndexforLookup === 0) {
          return (
            <Link
              to={"/console/placements/" + rowData.empCode + "/" + rowData.id}
            >
              <InactiveChip message={rowData.placementID.toString()} />
            </Link>
          );
        } else {
          return (
            <Link
              to={"/console/placements/" + rowData.empCode + "/" + rowData.id}
            >
              <ActiveChip message={rowData.placementID.toString()} />
            </Link>
          );
        }
      },
    },
    {
      title: "Billable Client",
      field: "billableClientName",
      render: (rowData) => {
        return (
          <Link to={"/console/clientslist/" + rowData.clientId}>
            {rowData.billableClientName}
          </Link>
        );
      },
      cellStyle: {
        width: 200,
        minWidth: 200
      },
      dataType: "String"
    },
    {
      title: "End Client",
      field: "EndClientName",
      render: (rowData) => {
        return (
          <div>
            <Link to={"/console/clientslist/" + rowData.clientId}>
            {rowData.EndClientName.map((name) => (
                <p key={name} style={{ margin: "8px 0", display: "flex" }}>{name}</p>
              ))}
            </Link>
            
          </div>
        );
      },
      width: 150,
      dataType: "String",
    },
    {
      title: "Start Date",
      field: "startDate",
      type: "date",
      dataType: "Date",
      width: 120,
    },
    {
      title: "Project End Date",
      field: "projectEndDate",
      type: "date",
      dataType: "Date",
      width: 120,
    },
    {
      title: "Expired",
      field: "abouttoexpire",
      type: "string",
      width: 150,
      render: (row) => {
        if (row.abouttoexpire > 0)
          return <ActiveChip message={"Remaining " + row.abouttoexpire + " days"} />
        else if (row.abouttoexpire < 0)
          return <WarningChip message={-row.abouttoexpire + " days"} />
        else
          return ""
      },
    },
    {
      title: "Employee Home Address",
      field: "employeeHomeAddress",
      dataType: "String",
      width: 200,
      render: (rowData) => {
        if (rowData.employeeHomeAddress)
          return (
            <div style={{ width: 200, paddingLeft: 5 }}>
              <table>
                <tr>
                  <td><MdLocationCity size={24} /></td>
                  <td>&nbsp;</td>
                  <td>{rowData.employeeHomeAddress}</td>
                </tr>
              </table>
            </div>
          )
        else
          return ("")
      },
    },
    {
      title: "Category",
      field: "employeeCategory",
      dataType: "String",
      width: 100,
    },
    {
      title: "Payroll ID",
      field: "payrollid",
      dataType: "String",
      width: 100,
    },
    {
      title: "Project",
      field: "linkToProject",
      render: ({ linkToProject }) => {
        return linkToProject !== "" ? (
          <Link to={`/console/projects/${linkToProject}`}>
            {projects_meta_info.hasOwnProperty(linkToProject)
              ? projects_meta_info[linkToProject].title
              : "Project"}
          </Link>
        ) : (
          <p>--</p>
        );
      },
      width: 100,
    },
    {
      title: "Notes",
      field: "placementNotes",
      dataType: "String",
      lookup: {
        0: "Notes Available",
        1: "Notes Not Available",
      },
      render: (rowData) => {
        return <div align="center"><PlacementNotes rowData={rowData} /></div>
      },
      width: 100,
    },
    {
      title: "Employee Pay Rate",
      field: "employeePayRate",
      dataType: "String",
      cellStyle: {
        width: 150,
        minWidth: 150
      },
    },
    {
      title: "Work Location Manager",
      field: "workLocationManager",
      dataType: "String",
      cellStyle: {
        width: 150,
        minWidth: 150
      },
      render: (rowData) => {
        return rowData.workLocationManager.map((manager) => {
          if(manager === "--") return (<div>{manager}</div>)
          const managerDetailsArray = manager.split(" - ")
          return parser(`<div>
            <span>${managerDetailsArray[0]}</span><br/>
            <span>${managerDetailsArray[1]}</span><br/>
            <span>${managerDetailsArray[2]}</span>
          </div>`)
        })
      }
    },{
      title: "Placement Recruiter",
      field: "placementRecruiter",
      dataType: "String",
      cellStyle: {
        width: 150,
        minWidth: 150
      },
      render: (rowData) => {
        return rowData.placementRecruiter.map((recruiter) => {
          if(recruiter === "--") return (<div>{recruiter}</div>)
          const recruiterDetailsArray = recruiter.split(" - ")
          return parser(`<div>
            <span>${recruiterDetailsArray[0]}</span><br/>
            <span>${recruiterDetailsArray[1]}</span><br/>
            <span>${recruiterDetailsArray[2]}</span>
          </div>`)
        })
      }
    },
    {
      title: "Status",
      field: "status",
      lookup: { 0: "Active", 1: "Inactive", 2: "Closed" },
      dataType: "",
      render: (rowData) => {
        if (rowData.status === 0) {
          return (
            <ActiveChip message="Active" />
          );
        } else if (rowData.status === 1) {
          return (
            <InactiveChip message="Inactive" />
          );
        } else {
          return (
            <RedChip message="Closed" />
          );
        }
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true;
        return value.includes(rowData.status.toString());
      },
      width: 100,
    },
    {
      title: "PO Number",
      field: "poNumber",
      dataType: "String",
      hidden: true,
      width: 120,
    },
    {
      title: "PO Document",
      field: "poDoc",
      dataType: "",
      hidden: true,
      render: (rowData) => {
        if (!rowData.poDoc.length) return <p>--</p>;
        return rowData.poDoc.map((obj) => {
          return (
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={obj.work_doc.url}
              >
                {obj.work_doc.name.substr(
                  0,
                  obj.work_doc.name.lastIndexOf("_")
                )}
              </a>
              <br />
            </span>
          );
        });
      },
      width: 150,
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      render: (rowData) => {
        return (
          <div align="center">
            {/* email send */}
            <Tooltip title="Edit Placement">
              <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} to={"/console/placements/" + rowData.empCode + "/" + rowData.id}><FaEdit size={20} /></Link>
            </Tooltip>
                
            {/* <IconButton
              onClick={() =>
                deletePlacement(rowData.placementID, rowData.empCode)
              }
              color="inherit"
            >
              <DeleteIcon />
            </IconButton> */}
          </div>
        );
      },
      width: 100,
    },
  ];

  let sortedData = [];
  if (listAll) {
    sortedData = placementsList;
  } else if (clientView) {
    sortedData = placementsList.filter((item) => item.clientId === clientId);
  } else {
    sortedData = placementsList.filter(
      (item) => item.employeeID === employeeId
    );
  }
  const statusList = ["Active", "Inactive", "Closed"];
  const categoryList = ["--", "W2", "C2C"];
  let data = sortedData.map((placement) => {
    const branch = new MetaInfo().getEmployeeKey(
      placement.employeeID,
      "branch"
    );

    const getEmployee = () => employeeList?.length && employeeList?.filter(e => e?.uid === placement?.employeeID)
    const employee = getEmployee()

    const getEmployeeAddress = (employeeAddress) => {
      if (employeeAddress) {
        const {
          line1 = "",
          line2 = "",
          city = "",
          state = "",
          country = "",
          zip = ""
        } = employeeAddress;
    
        return `${line1}, ${line2}, ${city}, ${state}, ${country} - ${zip}`;
      } else {
        return "--";
      }
    };

    const getActivePayRate = (payment_settings_list,placementID) => {
      const payments_list_for_placement = payment_settings_list?.filter(payment => payment?.placementID === placementID)

      if (payments_list_for_placement?.length && payments_list_for_placement[0]?.data?.length) {
        if(payments_list_for_placement[0]?.data?.length === 1) {
          return payments_list_for_placement[0]?.data[0]?.employeePayRate
        }
        const activePayRate = payments_list_for_placement[0]?.data?.filter(payRate => (new Date() >= new Date(payRate.effectiveDate)) && (new Date() <= new Date(payRate.effectiveUntil)))
        const placementPayments = [...payments_list_for_placement[0]?.data]
        return activePayRate?.length ? activePayRate[0]?.employeePayRate : placementPayments?.sort((a,b) => new Date(b.effectiveUntil) - new Date(a.effectiveUntil))[0]?.employeePayRate
      } else {
        return "--"
      }
    }

    const getWorkLocationManager = (worklocation_settings_list,placementID) => {
      const worklocation_list_for_placement = worklocation_settings_list?.filter(worklocation => worklocation?.placementID === placementID)
      let managerDetails = []

      if(worklocation_list_for_placement?.length && worklocation_list_for_placement[0]?.managers?.length) {
        if(worklocation_list_for_placement[0]?.managers?.length === 1) {
          const manager = clients_contacts_list?.filter(contact => contact?.email === worklocation_list_for_placement[0]?.managers[0])
          const details = manager?.length ? `${manager[0]?.representativeName} - ${manager[0]?.email} - ${manager[0]?.workPhone}` : `${"--"}`
          managerDetails.push(details)
          return managerDetails
        }
      }

      worklocation_list_for_placement[0]?.managers?.map(managerEmail => {
        const manager = clients_contacts_list?.filter(contact => contact?.email === managerEmail)
        const details = manager?.length ? `${manager[0]?.representativeName} - ${manager[0]?.email} - ${manager[0]?.workPhone}` : `${"--"}`
        managerDetails.push(details)
      })
      return managerDetails.length? managerDetails : ["--"]
    }

    const getPlacementRecruiter = (recruitment_details_settings_list,placementID) => {
      const recruitment_details_list_for_placement = recruitment_details_settings_list?.filter(recruitment_details => recruitment_details?.placementID === placementID)
      let recruiterDetails = []

      if(recruitment_details_list_for_placement?.length && recruitment_details_list_for_placement[0]?.recruitersList?.length) {
        if(recruitment_details_list_for_placement[0]?.recruitersList?.length === 1) {
          const recruiter = clients_contacts_list?.filter(contact => contact?.email === recruitment_details_list_for_placement[0]?.recruitersList[0])
          const details = recruiter?.length ? `${recruiter[0]?.representativeName} - ${recruiter[0]?.email} - ${recruiter[0]?.workPhone}` : `${"--"}`
          recruiterDetails.push(details)
          return recruiterDetails
        }
      }

      recruitment_details_list_for_placement[0]?.recruitersList?.map(recruiterEmail => {
        const recruiter = clients_contacts_list?.filter(contact => contact?.email === recruiterEmail)
        const details = recruiter?.length ? `${recruiter[0]?.representativeName} - ${recruiter[0]?.email} - ${recruiter[0]?.workPhone}` : `${"--"}`
        recruiterDetails.push(details)
      })
      return recruiterDetails.length? recruiterDetails : ["--"]
    }

    return {
      empCode: placement.employeeID,
      employeeid: placement.employeeID,
      imageURL: metaInfo.getImage(placement.employeeID),
      placementID: placement.placementID,
      clientId: placement.clientId,
      billableClientName: metaInfo.clientIdToName(placement.clientId),
      startDate: validate.dateFormatter(placement.startDate),
      endDate: validate.dateFormatter(placement.endDate),
      projectEndDate: validate.dateFormatter(placement.projectEndDate),
      createdAt: validate.dateFormatter(placement.createdAt),
      createdBy: metaInfo.emailToName(placement.createdBy),
      createdByEmail: placement.createdBy,
      status: placement?.closingReason
        ? statusList.indexOf("Closed")
        : new Date(placement.projectEndDate) < new Date()
          ? statusList.indexOf("Inactive")
          : statusList.indexOf("Active"),
      draft: placement.draft,
      draftIndexforLookup: placement?.draft ? 0 : 1,
      id: placement.id,
      description: placement.description,
      fillableSections: placement.hasOwnProperty("fillableSections")
        ? placement.fillableSections
        : [],
      name: metaInfo.emailToName(placement.employeeID),
      poDoc:
        placement.metaInfo && placement.metaInfo.hasOwnProperty("poDocs")
          ? placement.metaInfo.poDocs
          : [],
      branch: branch === undefined ? -1 : branchList.indexOf(branch),
      jobTitle: placement.jobTitle,
      poNumber:
        placement.metaInfo &&
          placement.metaInfo.hasOwnProperty("poNumber") &&
          placement.metaInfo.poNumber
          ? placement.metaInfo.poNumber
          : "--",
      linkToProject:
        placement.metaInfo && placement.metaInfo.hasOwnProperty("poNumber")
          ? placement.metaInfo.linkToProject
          : "",
      category:
        categoryList.indexOf(metaInfo.getCategory(placement.employeeID)) !== -1
          ? categoryList.indexOf(metaInfo.getCategory(placement.employeeID))
          : 0,
      abouttoexpire: getAboutToExpire(placement?.projectEndDate, placement?.closingReason),
      employeeHomeAddress: getEmployeeAddress(employee[0]?.mailingaddress),
      joiningDate:  CalendarFormatter.standardDateFormat(employee[0]?.personal?.dateofjoining),
      employeeCategory: employee[0]?.personal?.category,
      payrollid: employee[0]?.personal?.payrollid,
      empnotesIndexforLookup: employee[0]?.personal?.employeenotes ? 0 : 1,
      employeenotes: employee[0]?.personal?.employeenotes,
      placementNotes: placement?.placementNotes ?? '',
      employeePayRate: getActivePayRate(payment_settings_list,placement.placementID),
      workLocationManager:getWorkLocationManager(worklocation_settings_list,placement.placementID),
      placementRecruiter:getPlacementRecruiter(recruitment_details_settings_list,placement.placementID),
      EndClientName: getEndClientNames(placement.placementID) || [],
    };
  });

  let clientViewColumns = columns.filter((col) => col.field !== "clientId");

  const [newColumns, setNewColumns] = React.useState([]);
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false,
  });
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol);
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered,
    });

    setNewColumns(newCol);
  };

  const PlacementAuditAction = {
    icon:()=> <PlacementAudit/>,
    tooltip: "Placement Audit",
    isFreeAction: true,
    position: "toolbar",
  }

  const getRowHeight = React.useCallback((params) => {
		const { data } = params;
		let maxHeightWorkLocationManager = 50,maxHeightPlacementRecruiter = 50;
		const { workLocationManager,placementRecruiter } = data;
		const workLocationManagerLength = workLocationManager?.length ?? 0;
		const placementRecruiterLength = placementRecruiter?.length ?? 0;

		if (workLocationManagerLength > 1) {
			const height = 80 * workLocationManagerLength;
			maxHeightWorkLocationManager = height;
		}else if (workLocationManagerLength === 1 && workLocationManager[0] === "--") {
      maxHeightWorkLocationManager = 50;
    }else if (workLocationManagerLength === 1 && workLocationManager[0] !== "--") {
      maxHeightWorkLocationManager = 80;
    }

    if (placementRecruiterLength > 1) {
			const height = 80 * placementRecruiterLength;
			maxHeightPlacementRecruiter = height;
		}else if (placementRecruiterLength === 1 && placementRecruiter[0] === "--") {
      maxHeightPlacementRecruiter = 50;
    }else if (placementRecruiterLength === 1 && placementRecruiter[0] !== "--") {
      maxHeightPlacementRecruiter = 80;
    }

		return maxHeightWorkLocationManager >= maxHeightPlacementRecruiter ? maxHeightWorkLocationManager : maxHeightPlacementRecruiter;
	}, []);

  return (
    <div>
      <FlairTable
        title="Placements Report"
        columns={clientView ? clientViewColumns : columns}
        data={!filterBy.isFiltered ? data : filterBy.data}
        actions={NewPlacementAction}
        otherAction={PlacementAuditAction}
        getRowHeight={getRowHeight}
      // isLoading={isLoading}
      />
      {/* <div className="placementList" >
        <MaterialTable
          title=""
          icons={TABLE_ICONS}
          columns={clientView ? clientViewColumns : columns}
          data={!filterBy.isFiltered ? data : filterBy.data}
          style={{ width: "100%" }}
          options={{
            pageSize: 10,
            filtering: true,
            columnsButton: true,
            paginationPosition: "top",
            paginationType: "normal",
            selection: true,
          }}
          onChangeColumnHidden={(column, hidden) => (console.log("column", column, hidden))}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0 10px" }}>
                  <CustomFilters
                    columns={
                      !filterBy.isFiltered
                        ? clientView
                          ? clientViewColumns
                          : columns
                        : newColumns
                    }
                    data={data}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            ),
          }}
        />
      </div> */}
    </div>
  );
}

export default Presentation;
