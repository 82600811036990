import React from "react"
import VisibilityIcon from "@material-ui/icons/Visibility"
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { Modal,Button } from "react-bootstrap"
import VoidInvoice from "./VoidInvoice"
import InvoicePreview from "../../InvoicePreview/index"
import FollowupInvoice from "./FollowupInvoice"
import EditFollowup from "./EditFollowup"
import { useDialog } from "../../../../../hooks/useDialog"
import AppModalHeader from "../../../../../shared/components/Modals/AppModalHeader"

function InvoicePreviewAction(props) {
  const { open, handleOpen, handleClose } = useDialog();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Tooltip title="Preview">
        <Link style={{ color: "#2C9273", paddingLeft: "5px", paddingRight: "5px"}} onClick={handleOpen}>
          <VisibilityIcon size={20} /></Link>
      </Tooltip>
      <div>
      <Modal show={open} onHide={handleClose} centered size="xl">
        <AppModalHeader title="Invoice Preview" />
        <Modal.Body>
          <InvoicePreview invoiceID={props.invoiceID} />
        </Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
        {props.followupComment || props.tagType? (
          <EditFollowup
            showButton={true}
            followupComment={props.followupComment}
            invoiceID={props.invoiceID}
            tagType={props.tagType}
          />
        ) : (
          <FollowupInvoice
            invoiceID={props.invoiceID}
            isPaymentDone={props.isPaymentDone}
            isVoid={props.isVoid}
          />
        )}
        </div>
        <div style={{  marginRight: '10px'}}>
        <VoidInvoice
          invoiceID={props.invoiceID}
          isVoid={props.isVoid}
          isPaymentDone={props.isPaymentDone}
          isPreviewVoid={true}
          invoicePreviewClose={handleClose}
        />
        </div>
        <div style={{  marginRight: '10px' }}>
        <Button onClick={handleClose} className='btn-secondary btn-md px-6 text-high' style={{width:100}}>
          Close
        </Button>
        </div>
        </div>
      </Modal>
      </div>
    </div>
  );
}

export default InvoicePreviewAction
