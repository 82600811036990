import Swal from "sweetalert2";

export class AlertService {
  constructor() {
    this.toast = Swal.mixin({
      toast: true,
      position: "top-end",
      icon: "success",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });
  }

  _showMessage(type, title, message) {
    return Swal.fire({
      title: title || "Are you sure?",
      icon: type,
      html: message,
      showCancelButton: false,
      reverseButtons: true,
      allowOutsideClick: false,
    });
  }

  warningMessage(title, message) {
    return this._showMessage("warning", title, message);
  }

  errorMessage(title, message) {
    return this._showMessage("error", title, message);
  }

  successMessage(title, message) {
    return this._showMessage("success", title, message);
  }

  infoMessage(title, message) {
    return this._showMessage("info", title, message);
  }

  confirmMessage(title, message) {
    return Swal.fire({
      title: title || "Are you sure?",
      icon: "warning",
      html: message,
      heightAuto: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      allowOutsideClick: false,
    });
  }

  rejectWithReason(title, message) {
    return Swal.fire({
      title: title || "Are you sure?",
      icon: "warning",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      allowOutsideClick: false,
      input: "text",
      inputPlaceholder: "Enter reason",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });
  }

  successToast(message) {
    this.toast.fire(message || "Success");
  }
}