import React from "react";
import { useDialog } from "../../../../hooks/useDialog";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";
import { updateUserEmail } from "../../middleware/employeeList";

const schema = Yup.object().shape({
	oldEmail: Yup.string().email("Invalid email").required("Required"),
	newEmail: Yup.string().email("Invalid email").required("Required"),
});

export default function ResetEmail({ oldEmail }) {
	const { open, handleOpen, handleClose } = useDialog();

	const handleSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		await updateUserEmail(values);
		setSubmitting(false);
		handleClose();
	};

	return (
		<>
			<OverlayTrigger
				placement="top-end"
				overlay={<Tooltip id="tooltip-top">Reset Email</Tooltip>}
			>
				<Button variant="primary" onClick={handleOpen}>
					<i className="fas fa-envelope"></i>
				</Button>
			</OverlayTrigger>
			<Modal
				show={open}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Formik
					initialValues={{ oldEmail, newEmail: "" }}
					validationSchema={schema}
					onSubmit={handleSubmit}
				>
					{({ isSubmitting, isValid, dirty }) => (
						<Form>
							<AppModalHeader title="Reset Email" />
							<Modal.Body>
								<Field
									type="email"
									name="oldEmail"
									component={TextFormField}
									label="Old Email"
									placeholder="Enter old email"
									readOnly={true}
								/>
								<Field
									type="email"
									name="newEmail"
									component={TextFormField}
									label="New Email"
									placeholder="Enter new email"
								/>
							</Modal.Body>
							<AppModalFooter
								onClickCancel={handleClose}
								saveButtonDisabled={!isValid || !dirty || isSubmitting}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
