import { Box, Paper } from '@material-ui/core'
import React from 'react'
import MetaInfo from '../../../../shared/getMetaInfo'
import { useBulkPaymentsContext } from './context'
import { GoFileSymlinkFile } from "react-icons/go"

function PaymentOverview() {
    const { state } = useBulkPaymentsContext()
    const info = new MetaInfo()
    return (
        <Paper style={{ padding: '10px' }} >
            <div className='d-flex' >
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <b>Client: </b>
                            </td>
                            <td>
                                {info.clientIdToName(state.selectedClientId)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Payment method: </b>
                            </td>
                            <td>
                                {state.paymentMethod}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Payment date: </b>
                            </td>
                            <td>
                                {state.paymentDate}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Box width={100} />
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <b>Reference number: </b>
                            </td>
                            <td>
                                {state.referenceNumber}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Reference document: </b>
                            </td>
                            <td>
                                {state.attachment.publicURL.length ? (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={state.attachment.publicURL}
                                    >
                                        <GoFileSymlinkFile size={22} />
                                    </a>
                                ) : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Paper>
    )
}

export default PaymentOverview