import React from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MailingAddressEditModal from './mailingaddress';



const MailingAdress = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const {data,employeeID ,isPersonal} = props 
    return (
        <>
            <MailingAddressEditModal
                show={modalShow}
                country={data?.country}
                employeeID={employeeID}
                data={data}
                onHide={() => setModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Mailing Address</h5>
                        {
                            isPersonal ? 
                            <></> 
                            :
                            <Link to="#" className="linkbtn" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen"></i></Link>
                        }
                    </div>
                    <Row>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Line 1</div>
                                <div className="fs-16 fw-medium text-base">{data?.line1}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Line 2</div>
                                <div className="fs-16 fw-medium text-base">{data?.line2}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">State</div>
                                <div className="fs-16 fw-medium text-base">{data?.state}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Country</div>
                                <div className="fs-16 fw-medium text-base">{data?.country}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">City</div>
                                <div className="fs-16 fw-medium text-base">{data?.city}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Zip Code</div>
                                <div className="fs-16 fw-medium text-base">{data?.zip}</div>
                            </div>
                        </Col>
                       
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
export default MailingAdress;