import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Number} month
 * @param {Number} year
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getTimesheetsReport = (month, year, signal) => {
	if (isNaN(month) || isNaN(year)) {
		return Promise.reject(new Error("Month and year are required"));
	}
	const params = new URLSearchParams({ month, year });
	return defaultInstance({
		method: "GET",
		url: `/employees/timesheet-overview?${params}`,
		signal,
	});
};
