import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import CreatableSelectFormField from "../../../../../../shared/components/BootStrapFormFields/CreatableSelectFormField";
import { getAllCertifications, postCertifications } from "../../../../../../api/services/default/resourcemanagement";
import { useMutation, useQuery } from "@tanstack/react-query";

const schema = yup.object().shape({
  title: yup.string().required("Title is a required Field"),
  category: yup.string().required(),
  certifieddate: yup.date().required("Certified Date is a required Field"),
  expirydate: yup.date(),
  certificatedocument:yup.string().required(),
});

export default function CertificationEditModal(props) {
  const { certifiedData, employeeID, idx, onHide } = props;
  const optionMapper = (i) => ({ value: i, label: i });
  const dispatch = useDispatch();

  const {data, refetch } = useQuery({
    queryKey: ["getAllCertifications"],
    queryFn: () => getAllCertifications(),
   })

   
  const useCreateCertifications = useMutation({
		mutationFn: ({ data }) => postCertifications(data),
		onSuccess: () => {
			refetch()
		},
	});

  const handleField = async(value , cb) => {
    cb()
    await useCreateCertifications.mutateAsync({ data : {
      certificationData : value
    }})
  }

  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return certifiedData[idx];
    } else {
      return {};
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit Certification";
    } else {
      return "Create Certification";
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const callback = () => {
      actions.setSubmitting(false);
      onHide();
    };

    const formData = { ...values };

   
    let certifications = certifiedData?.map((item) => item) || [];

    if (!isNaN(idx)) {
      certifications[idx] = formData;
    } else {
      certifications.push(formData);
    }

    const payload = {
      certifications,
    };

    dispatch(updateProfile(payload, employeeID, payload, callback));
  };

  const certificationnFields = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.certifications?.fields?.filter((field) => field.name === "category")?.[0]?.values || [])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        initialValues={loadInitialValues()}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title={getTitleText()} />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="title"
                      type="text"
                      label={"Title *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="category"
                      type="select"
                      options={data?.certifications?.map((item) => ({
                        value: item,
                        label: item,
                      })) || []}
                      handleCreate={(value, cb) => {
                        handleField(value , cb)
                      }}
                      label={"Category *"}
                      component={CreatableSelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="certifieddate"
                      type="date"
                      label={"Certified Date *"}
                      component={DateFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="expirydate"
                      type="date"
                      label={"Expiry Date"}
                      component={DateFormField}
                    />
                  </Col>
                  <Col xl={4}>
                     <Field
											name="certificatedocument"
											component={FileUploadForm}
											label="Certificate Document*"
											type="text"
											employeeId={employeeID}
											sectionKey="certifications"
											/>
                  </Col>
                </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText={getSaveButtonText()}
                saveButtonDisabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty 
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
