import React ,{useEffect, useState}from 'react'
import { Tooltip } from '@material-ui/core';
import { Link } from "react-router-dom"
import InfoIcon from "@material-ui/icons/Info"
import { connect } from "react-redux";
import MetaInfo from "../../../../shared/getMetaInfo"
import FlairTable from "../../../../shared/FlairTable"
import validate from "../../../../shared/validation"
import { useInvoiceListContext } from "./context";
import MaterialTable, { MTableToolbar } from "material-table"
import EditInvoice from "./Actions/EditInvoice"
import InvoicePreviewAction from "./Actions/InvoicePreviewAction"
import InvoiceTimeline from "./Actions/InvoiceTimeline"
import MailToClient from "./Actions/MailToClient"
import VoidInvoice from "./Actions/VoidInvoice"
import Payment from "./Actions/Payment"
import PaymentsHistory from "../../../Payments/components/List/Actions/PaymentHistory"
import WarningIcon from "@material-ui/icons/Warning";
import EditVoidInvoice from './Actions/EditVoidInvoice';
import EditFollowup from './Actions/EditFollowup';
import { differenceInDays } from 'date-fns'
import EmployeeHoverCard from '../../../../shared/components/EmployeeHoverCard';
import Button from "react-bootstrap/Button"
import ActiveChip from "../../../../shared/lib/Chips/ActiveChip"; 
import InactiveChip from "../../../../shared/lib/Chips/InactiveChip"; 
import InfoChip from "../../../../shared/lib/Chips/InfoChip"; 
import WarningChip from "../../../../shared/lib/Chips/WarningChip"; 
import RedChip from "../../../../shared/lib/Chips/RedChip"; 
import Accounts from '../../../../utils/Accounts';

function InvoiceListTable(props) {
  const {type, loggedInEmployee, branchList, invoicesList = [], isLoading, employeeID } = props
  const { state, services} = useInvoiceListContext()
  const lookupBranchList = Object.assign({}, branchList);

  const columns = [
    {
      title: "Invoice no. ",
      field: "id",
      width: 180,
      dataType: "String",
      render: (row) => {
        if (row.isVoid) 
        return <span className='d-flex' >
          <EditVoidInvoice invoiceID={row.id} voidReason={row.voidReason} />
          <RedChip message={row.id} />
        </span>
        else if (row.isPaymentDone)
          return <ActiveChip message={row.id} />
        else if (row.isMailedToClient)
          return <span className='d-flex' >
            {row.followupComment && <EditFollowup invoiceID={row.id} followupComment={row.followupComment} tagType={row.tag} />}
            <InactiveChip message={row.id} />
          </span>
        return <InfoChip message={row.id} />
      },
    },
    {
      title: "Tag Type",
      field: "tag",
      align: "center",
      type: "string"
    },
    {
      title: "PC",
      field: "placementID",
      width: 100,
      dataType: "String",
      render: (rowData) => {
        if (rowData.placementID !== "--")
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID}>
              {rowData.placementID}
            </Link>
          )
        else
          return "--"
      },
    },
    {
      title: "Billable Client",
      field: "clientName",
      width: 150,
      dataType: "String",
      render: (rowData) => {
        if (rowData.clientID !== "--")
          return <Link to={"/console/clientslist/" + rowData.clientID}>{rowData.clientName}</Link>
        else
          return "--"
      },
    },
    {
      title: "Employee",
      field: "employeeName",
      width: 300,
      dataType: "String",
      render: (row) => {
        if(row.employeeID !== '--')
          return <EmployeeHoverCard employeeID={row.employeeID} />
        else
          return "--"
      }
    },
    // {
    //   title: "Client's Interest",
    //   field: "latestPaymentDate",
    //   dataType: "",
    //   render: ({ isClientApproved, isClientRejected, clientRejectedDetails = {} }) => {
    //     if (isClientApproved) return <span className="custom-chip chip-success">Approved</span>
    //     else if (isClientRejected)
    //       return (
    //         <span className="custom-chip chip-danger">
    //           Rejected&nbsp;
    //           <div>
    //             <Tooltip placement="top" title={clientRejectedDetails.reason}>
    //               <InfoIcon />
    //             </Tooltip>
    //           </div>
    //         </span>
    //       )
    //     return ""
    //   },
    // },
    {
      title: "Branch",
      field: "branch",
      width: 100,
      dataType: "",
      lookup: lookupBranchList,
    },
    {
      title: "Activity",
      field: "activity",
      width: 180,
    },
    {
      title: "Hrs",
      field: "hrs",
      align: "center",
      width: 100,
      type: "string"
    },
    {
      title: "Billing rate",
      field: "billingrate",
      align: "center",
      width: 120,
    },
    {
      title: "Net terms",
      field: "netTerms",
      align: "center",
      width: 100,
      type: "string"
    },
    {
      title: "Invoiced Date",
      field: "invoiceDate",
      width: 150,
      dataType: "Date",
    },
    {
      title: "Due Date",
      field: "invoiceDueDate",
      width: 150,
      dataType: "Date",
    },
    {
      title: "Due Days",
      field: "duedays",
      align: "center",
      width: 150,
      render: (row) => {
        if (!row.isPaymentDone && !row.isVoid)
          if(row.duedays <= 0)
            return <ActiveChip message="CURRENT" />
          else
            return <WarningChip message={row.duedays + " days"} />
        else
          return ""
      },
      type: "string"
    },
    {
      title: "Invoiced Amount",
      field: "invoicedAmount",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Received Amount",
      field: "receivedAmount",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Discounted Amount",
      field: "paymentDiscountAmount",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Latest payment Date",
      field: "latestPaymentDate",
      width: 150,
      dataType: "Date",
    },
    {
      title: "Open Balance",
      field: "openBalance",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      width: 250,
      render: (row) => (
        <div className="d-flex">
          <EditInvoice invoiceID={row.id} />
          <InvoicePreviewAction followupComment={row.followupComment} invoiceID={row.id} isVoid={row.isVoid} isPaymentDone={row.isPaymentDone} tagType={row.tag}/>
          {/* <InvoiceTimeline invoiceID={row.id} grandTotal={row.grandTotal} /> */}
          <PaymentsHistory id={row.id} totalAmount={row.grandTotal} />
          <MailToClient
            invoiceID={row.id}
            notifiers={row.notifiers}
            isMailedToClient={row.isMailedToClient}
            isVoid={row.isVoid}
            isPaymentDone={row.isPaymentDone}
            clientName={row.clientName}
            companyDetails={row.companyDetails}
            invoiceDueDate={row.invoiceDueDate}
            openBalance={row.openBalance}
            selectedTimesheets={row.selectedTimesheets}
            selectedExpenses={row.selectedExpenses}
          />
          <VoidInvoice invoiceID={row.id} isVoid={row.isVoid} isPaymentDone={row.isPaymentDone} isPreviewVoid={false} />
          {row.isMailedToClient && !row.isVoid && loggedInEmployee != row.employeeID ? (
            <Payment
              invoicedAmount={row.invoicedAmount}
              openBalance={row.openBalance}
              paymentDiscountAmount={row.paymentDiscountAmount}
              invoiceID={row.id}
              isPaymentDone={row.isPaymentDone}
              receivedAmountTillNow={row.receivedAmount}
              discountReceivedTillNow={row.paymentDiscountAmount}
            />
          ) : null}
        </div>
      ),
    },
  ]

  if(type === "all") {
    columns.splice(2, 0, { title: "Invoice Status", field: "invoiceStatus", cellStyle: { width: 150, minWidth: 150 }, dataType: "String" })
  }

  const sendActions = {
		icon: () => (
			<Link to='/console/invoiceslist/newinvoice'>
				<Button className="btn btn-success px-4 btn-md text-high">Add New Invoice</Button>
			</Link>
		),
		tooltip: 'Add New Invoice',
		isFreeAction: true,
		position: 'toolbar',
	}

  const rows = invoicesList?.map((invoice) => {
    if (employeeID && invoice.employeeID !== employeeID) {
      return null;
    }
    const branch = new MetaInfo().getEmployeeKey(invoice.employeeID, "branch")
    const timesheets = Object.values(invoice?.selectedTimesheets ?? {})
    const timesheetsActivity = Object.keys(invoice?.selectedTimesheets ?? {})
    const list = timesheetsActivity.sort((a, b) => Date.parse(new Date(a)) - Date.parse(new Date(b)))
    const openBalanceFormatted = invoice.grandTotal - (invoice?.receivedAmount || 0) - (invoice?.paymentDiscountAmount || 0)
    const invoiceName = invoice?.invoiceName;
    const submittedExpenses = invoice.selectedExpenses;
    const firstExpense = submittedExpenses.length > 0 ? submittedExpenses[0] : {};
    const expenseType = firstExpense?.expenseType;
    let activity;
    
    if (list.length > 0) {
      activity = list[0] + "-" + list[list.length - 1];
  } else if (invoiceName) {
      activity = invoiceName;
  } else if (expenseType) {
      activity = expenseType;
  } else {
      activity = "--";
  }

    return {
      id: invoice.id,
      createdAt: invoice.createdAt,
      createdBy: invoice.createdBy,
      invoiceDate: invoice.invoiceDate,
      invoiceDueDate: invoice.dueDate,
      invoiceDueDateTimestamp: invoice.dueDate,
      duedays:differenceInDays(new Date(), new Date(invoice.dueDate)),
      clientID: invoice.clientID ? invoice.clientID : "",
      clientName: invoice.clientID
        ? new MetaInfo().clientIdToName(invoice.clientID)
        : "--",
      activity: activity,
      companyDetails: invoice.companyDetails,
      invoiceBy: invoice.invoiceBy,
      placementID:  invoice.placementID || "--",
      employeeID:  invoice.employeeID || "--",
      invoiceStatus: invoice.type,
      employeeName:
        invoice.employeeID 
          ? new MetaInfo().emailToName(invoice.employeeID)
          : "--",
      isMailedToClient: invoice.isMailedToClient,
      isVoid: invoice.isVoid,
      isPaymentDone: invoice.isPaymentDone,
      selectedTimesheets: invoice?.selectedTimesheets || {},
      selectedExpenses: invoice.selectedExpenses || [],
      discount: invoice.discount,
      netTerms:invoice.netTerms,
      billingrate: (services.getBillingRatesInvolvedInTheTimesheets(timesheets) ?? []).join(', '),
      hrs: services.getTotalTime(timesheets.map(sh => sh.standardTime)),
      grandTotal: invoice?.grandTotal || 0,
      invoicedAmount: invoice?.grandTotal || 0,
      receivedAmount: invoice?.receivedAmount || 0,
      latestPaymentDate: invoice?.latestPaymentDate || "",
      // moreInformation: invoice.moreInformation,
      isClientApproved: invoice.isClientApproved,
      isClientRejected: invoice.isClientRejected,
      // clientRejectedDetails: invoice.isClientRejected
      //   ? invoice.clientRejectedDetails
      //   : "",
      openBalance: Accounts.roundOffAmount(openBalanceFormatted),
      notifiers: invoice.notifiers,
      branch: branch === undefined ? -1 : branchList.indexOf(branch),
      paymentDiscountAmount: invoice?.paymentDiscountAmount || 0,
      voidReason: invoice?.voidReason || '',
      followupComment: invoice?.followupComment || '',
      tag:invoice?.tag || ''
      // checked: invoice.checked,
    };
  }).filter(invoice => invoice !== null); 
  
  return (
    <div>
        <FlairTable
          title="Invoices Report"
          data={rows}
          columns={columns}
          isLoading={isLoading}
          actions={sendActions}
        />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || []
  };
};

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListTable)

