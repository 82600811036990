import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";

const schema = yup.object().shape({
	primary: yup.string().required("Primary is required"),
	optedfor401k: yup.string().required("Opted for 401k is required"),
	entrydate: yup.date().required("Entry Date is required"),
	startdate: yup.date().required("Start Date is required"),
	enddate: yup.date().required("End Date is required"),
});

export default function Section401kEditModal(props) {
	const { data, employeeID, onHide } = props;
	const dispatch = useDispatch();
	const optionMapper = (i) =>  ({ value: i, label: i });
	const optedStatus = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.['401k']?.fields?.filter((field) => field.name === "optedfor401k")?.[0]?.values || [])

	const handleSubmit = (values, actions) => {
		actions.setSubmitting(true);

		const callback = () => {
			onHide();
			actions.setSubmitting(false);
		};

		const payload = {
			"401k": values,
		};

		dispatch(updateProfile(payload, employeeID, payload, callback));
	};

	return (
		<Modal
			{...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Formik
				validationSchema={schema}
				initialValues={data}
				onSubmit={handleSubmit}
			>
				{(formik) => {
					return (
						<Form>
							<AppModalHeader title="Edit 401k" />
							<Modal.Body>
								<Row>
									<Col xl={4}>
										<Field
											name="primary"
											type="text"
											label={"Primary *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="optedfor401k"
											type="select"
											options={optedStatus?.map(optionMapper)}
											label={"Opted for 401k *"}
											component={SelectFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="entrydate"
											type="date"
											label={"Entry Date *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="startdate"
											type="date"
											label={"Start Date *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="enddate"
											type="date"
											label={"End Date *"}
											component={DateFormField}
										/>
									</Col>
								</Row>
							</Modal.Body>
							<AppModalFooter
								saveButtonDisabled={
									!formik.isValid || formik.isSubmitting || !formik.dirty
								}
							/>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
