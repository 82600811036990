import React from "react"
import CreateExpenses from "../Services/Expenses/components/CreateExpenses/Index"

function CreateExpensesPage(props) {
  console.log("page", props)
  return (
    <div>
      <CreateExpenses {...props} />
    </div>
  )
}

export default CreateExpensesPage
