import React, { useState } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { forgetPassword } from "../../middleware"

function Container(props) {

	const { forgetPassword } = props

  const [email, setEmail] = useState("")
  const [open, setOpen] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setOpen(false)
    forgetPassword(email)
  }

	return (
    <div>
      <Presentation
        email={email}
        setEmail={setEmail}
        handleSubmit={handleSubmit}
        open={open}
        setOpen={setOpen}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    forgetPassword: (email) => {
      dispatch(forgetPassword(email))
    },
  }
}

export default connect(null, mapDispatchToProps)(Container)
