import React from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Section401kEditModal from './401k';




const Section401k = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const {data,employeeID,isPersonal} = props 
    return (
        <>
            <Section401kEditModal
                show={modalShow}
                employeeID={employeeID}
                data={data}
                onHide={() => setModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">401 K</h5>
                        {
                            isPersonal ?
                            <></>
                            :
                            <Link to="#" className="linkbtn" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen"></i></Link>
                        }
                    </div>
                    <Row>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Opted for 401k</div>
                                <div className="fs-16 fw-medium text-base">{data?.optedfor401k}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Entry Date</div>
                                <div className="fs-16 fw-medium text-base">{data?.entrydate}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Primary</div>
                                <div className="fs-16 fw-medium text-base">{data?.primary}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Start Date</div>
                                <div className="fs-16 fw-medium text-base">{data?.startdate}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">End Date</div>
                                <div className="fs-16 fw-medium text-base">{data?.enddate}</div>
                            </div>
                        </Col>
                       
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
export default Section401k;