import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { RegexExp } from "../../../../../../shared/regex";

const schema = yup.object().shape({
  w4status:yup.string().required(),
  uploadedon:yup.date().required("Upload Date is required"),
  verifiedby:yup.string().required().matches(RegexExp.name),
  w4document:yup.string().required()
});

export default function W4EditModal(props) {
  const { data, employeeID, idx, onHide } = props;
  const optionMapper = (i) => ({ value: i, label: i });
 
  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return data[idx];
    } else {
      return {};
    }
  };

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit SSN FORM";
    } else {
      return "Create SSN FORM";
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const callback = () => {
      actions.setSubmitting(false);
      onHide();
    };

    const formData = { ...values };

    let w4 = data?.map((item) => item) ||[];

    if (!isNaN(idx)) {
      w4[idx] = formData;
    } else {
      w4.push(formData);
    }

    const payload = {
      w4,
    };

    dispatch(updateProfile(payload, employeeID, payload, callback));
  };
  const w4Status = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.w4?.fields?.filter((field) => field.name === "w4status")?.[0]?.values || [])
  const dispatch = useDispatch();
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Formik
          validationSchema={schema}
          initialValues={loadInitialValues()}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <AppModalHeader title={getTitleText()} />
                <Modal.Body>
                  <Form>
                    <Row>
                      <Col xl={4}>
                        <Field
                          name="w4status"
                          type="select"
                          options={w4Status?.map(optionMapper)}
                          label={"W4 Status *"}
                          component={SelectFormField}
                        />
                      </Col>
                      <Col xl={4}>
                        <Field
                          name="uploadedon"
                          type="text"
                          label={"Uploaded On *"}
                          component={DateFormField}
                        />
                      </Col>
                      <Col xl={4}>
                        <Field
                          name="verifiedby"
                          type="text"
                          label={"Verified By *"}
                          component={TextFormField}
                        />
                      </Col>
                      <Col xl={4}>
                         <Field
                          name="w4document"
                          component={FileUploadForm}
                          label={"W4 Document *"}
                          type="text"
                          employeeId={employeeID}
                          sectionKey="w4"
											/>
                      </Col>
                    </Row>
                  </Form>
                </Modal.Body>
                <AppModalFooter
                  saveButtonText={getSaveButtonText()}
                  saveButtonDisabled={
                    !formik.isValid ||
                    formik.isSubmitting ||
                    !formik.dirty 
                  }
                />
              </Form>
            );
          }}
        </Formik>
    </Modal>
  );
}
