import React, { Suspense, useEffect, useState } from "react";
import { InvoiceListContextProvider, useInvoiceListContext } from "./context";
import { Nav, Tab } from "react-bootstrap";
import InvoiceHistory from "./InvoiceHistory"
import validation from "../../../../shared/validation"
import { useHistory } from "react-router";
import Dashboard  from "../InvoicesDashboard";
import DueInvoices from "./Tabs/DueInvoices";
import GeneratedInvoices from "./Tabs/GeneratedInvoices";
import SentInvoices from "./Tabs/SentInvoices";
import VoidInvoices from "./Tabs/VoidInvoices";
import PaidInvoices from "./Tabs/PaidInvoices";
import AllInvoices from "./Tabs/AllInvoices";
import BulkPayments from "../BulkPayments";
import ARAging from "./ARAging";
import InvoiceDefaulters from "../Defaulters/InvoiceDefaulters";


function TabsViewComponent(props) {
  const {
    isEmployeeInvoices = false,
    employeeID = "",
    clientID = "",
    isClientInvoices = false,
  } = props;
  const tab = validation.getQueryParameter("tab") || "0";
  const [tabValue, setTabValue] = useState(tab)
  const history = useHistory();
  const pathName = window.location.pathname

  const setTabChangeValue = (newValue) => {
    history.push(`${pathName}?tab=${newValue}`)
    setTabValue(newValue);
  }

  useEffect(() => {
    setTabValue((prevTab) => isEmployeeInvoices || isClientInvoices? "1" : prevTab)
  }, [isEmployeeInvoices,isClientInvoices])

  return (
    <Tab.Container activeKey={tabValue} onSelect={setTabChangeValue}>
      <Nav as="ul" justify variant="pills" className="">
        {!isEmployeeInvoices && !isClientInvoices && (
          <Nav.Item as="li" key="0">
            <Nav.Link className="cursor-pointer" eventKey={"0"}>
              <span className="d-sm-block">Dashboard</span>
            </Nav.Link>
          </Nav.Item>
        )}

        <Nav.Item as="li" key="1">
          <Nav.Link className="cursor-pointer" eventKey={"1"}>
            <span className="d-sm-block">Due</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="2">
          <Nav.Link className="cursor-pointer" eventKey={"2"}>
            <span className="d-sm-block">Generated/Draft</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="3">
          <Nav.Link className="cursor-pointer" eventKey={"3"}>
            <span className="d-sm-block">Sent</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="4">
          <Nav.Link className="cursor-pointer" eventKey={"4"}>
            <span className="d-sm-block">Void</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="5">
          <Nav.Link className="cursor-pointer" eventKey={"5"}>
            <span className="d-sm-block">Paid</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="6">
          <Nav.Link className="cursor-pointer" eventKey={"6"}>
            <span className="d-sm-block">All</span>
          </Nav.Link>
        </Nav.Item>
        
        <Nav.Item as="li" key="9">
            <Nav.Link className="cursor-pointer" eventKey={"9"}>
              <span className="d-sm-block">Defaulters</span>
            </Nav.Link>
          </Nav.Item>
        {!isEmployeeInvoices && !isClientInvoices && (
          <> 
          <Nav.Item as="li" key="7">
            <Nav.Link className="cursor-pointer" eventKey={"7"}>
              <span className="d-sm-block">Bulk Payments</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="8">
            <Nav.Link className="cursor-pointer" eventKey={"8"}>
              <span className="d-sm-block">A/R</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="10">
            <Nav.Link className="cursor-pointer" eventKey={"10"}>
              <span className="d-sm-block">Invoice History</span>
            </Nav.Link>
          </Nav.Item>
          </>
        )}
      </Nav>
      <Tab.Content className="text-muted">
        {!isEmployeeInvoices && !isClientInvoices && (
          <Tab.Pane eventKey={"0"}>
             <Dashboard handleTabValue={setTabValue}  tabValue={tabValue}/>
          </Tab.Pane>
        )}
        <Tab.Pane eventKey={"1"}>
        {tabValue === "1" && (
        <DueInvoices
              clientID={clientID}
              employeeID={employeeID}
              isClientInvoices={isClientInvoices}
              isEmployeeInvoices={isEmployeeInvoices}
              key="1"
              tabValue={tabValue}
            />
        )}
        </Tab.Pane>
        <Tab.Pane eventKey={"2"}>
        {tabValue === "2" && (
        <GeneratedInvoices
              clientID={clientID}
              employeeID={employeeID}
              isClientInvoices={isClientInvoices}
              isEmployeeInvoices={isEmployeeInvoices}
              key="2"
              tabValue={tabValue}
            />
        )}
        </Tab.Pane>
        <Tab.Pane eventKey={"3"}>
        {tabValue === "3" && (
         <SentInvoices
              clientID={clientID}
              employeeID={employeeID}
              isClientInvoices={isClientInvoices}
              isEmployeeInvoices={isEmployeeInvoices}
              key="3"
              tabValue={tabValue}
            /> 
        )}
        </Tab.Pane>
        <Tab.Pane eventKey={"4"}>
        {tabValue === "4" && (
         <VoidInvoices
              clientID={clientID}
              employeeID={employeeID}
              isClientInvoices={isClientInvoices}
              isEmployeeInvoices={isEmployeeInvoices}
              key="4"
              tabValue={tabValue}
            /> 
        )}
        </Tab.Pane>
        <Tab.Pane eventKey={"5"}>
        {tabValue === "5" && (
        <PaidInvoices
              clientID={clientID}
              employeeID={employeeID}
              isClientInvoices={isClientInvoices}
              isEmployeeInvoices={isEmployeeInvoices}
              key="5"
              tabValue={tabValue}
            />
        )}
        </Tab.Pane>
        <Tab.Pane eventKey={"6"}>
        {tabValue === "6" && (
         <AllInvoices
              clientID={clientID}
              employeeID={employeeID}
              isClientInvoices={isClientInvoices}
              isEmployeeInvoices={isEmployeeInvoices}
              key="6"
              tabValue={tabValue}
            /> 
        )}
        </Tab.Pane>
        <Tab.Pane eventKey={"9"}>
        {tabValue === "9" && (
         <InvoiceDefaulters 
              clientID={clientID}
              employeeID={employeeID}
         />
        )}
        </Tab.Pane>
        {!isEmployeeInvoices && !isClientInvoices && (
          <>
            <Tab.Pane eventKey={"7"}>
            {tabValue === "7" && (
            <BulkPayments  tabValue={tabValue}/>     
            )}
            </Tab.Pane>
            <Tab.Pane eventKey={"10"}>
            {tabValue === "10" && (
            <InvoiceHistory  tabValue={tabValue}/>
            )}
            </Tab.Pane>
            <Tab.Pane eventKey={"8"}>
            {tabValue === "8" && (
            <ARAging  tabValue={tabValue}/>
            )}
            </Tab.Pane>
          </>
        )}
      </Tab.Content>
    </Tab.Container>
  );
}

const TabsView = (props) => (
  <InvoiceListContextProvider>
    <TabsViewComponent {...props} />{" "}
  </InvoiceListContextProvider>
);
export default TabsView;
