import React, { useState, useEffect, useContext } from 'react'
import Presentation from "./Presentation"
import { rejectTimesheet } from "../../../../middleware/index"
import { connect } from "react-redux"
import { Context } from "../../component_state/context"
import { useHistory } from "react-router-dom"

function Container(props) {
  const [contextState, handle] = useContext(Context)
  const { employeeID, placementID, id, isApproved, isRejected, isDefaulter, timesheetManager, isInvoiced } = contextState.viewingTimesheet
  const { _reject_timesheet, loggedInEmployee } = props
  const [state, stateSetter] = useState({
    open: false,
    description: "",
    hideBtn: true
  })
  const history = useHistory()

  const setState = (obj) => {
    stateSetter(prevState => ({
      ...prevState,
      ...obj
    }))
  }


  const setContextState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    })
  }


  useEffect(() => {
    // button hide conditions
    /* 
      1) His own timesheet
      2) if rejected
      3) if defaulter
    */
    // timesheetManager.includes(loggedInEmployee) &&
    if (loggedInEmployee !== employeeID && !isRejected && !isDefaulter && !isInvoiced) {
      setState({ hideBtn: false })
    } else {
      setState({ hideBtn: true })
    }
  }, [isRejected, isDefaulter, isInvoiced, employeeID])

  const onReject = () => {
    // sending payload as empty for now, in future there may be possibility of sending non-empty
    handleClose()
    _reject_timesheet({ reason: state.description }, employeeID, placementID, id, "viewing", "", "", history)
  }


  const handleClickOpen = () => {
    setState({ open: true })
  };
  const handleClose = () => {
    setState({ open: false })
  };

  return (
    <div>
      <Presentation
        state={state}
        setState={setState}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        onReject={onReject}
        employeeID={employeeID}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    _reject_timesheet: (payload, employeeID, placementID, timesheetID, condition, setState, state, history) => dispatch(rejectTimesheet(payload, employeeID, placementID, timesheetID, condition, setState, state, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
