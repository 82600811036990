import React, { useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";

const schema = yup.object().shape({
  passportnumber: yup.string().required("Passport Number is required"),
  placeofissue: yup.string().required("Place of Issue is required"),
  placeofbirth: yup.string().required("Place of Birth is required"),
  status: yup.string().required("Status is required"),
  expirydate: yup.date().required("Expiry Date is required"),
  issueddate: yup.date().required("Issued Date is required"),
  passportdocument: yup.string().required(),
});

export default function PassportEditModal(props) {
  const {
    data,
    employeeID,
    idx,
    onHide,
    isFromWorkQueue = false,
    onUpdate = () => {},
  } = props;
  const optionMapper = (i) => ({ value: i, label: i });

  const dispatch = useDispatch();
  const passportStatus = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.passport?.fields?.filter((field) => field.name === "status")?.[0]?.values || [])
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const callback = () => {
      actions.setSubmitting(false);
      onHide();
    };

    const formData = { ...values };

    if (isFromWorkQueue && onUpdate) {
      onUpdate(formData);
      return;
    }

    let docs = data?.map((item) => item) || [];

    if (!isNaN(idx)) {
      docs[idx] = formData;
    } else {
      docs.push(formData);
    }

    const payload = {
      passport: docs,
    };

    dispatch(updateProfile(payload, employeeID, payload, callback));
  };

  const loadInitialValues = () => {
    if (isFromWorkQueue && data) {
      return data;
    } else if (!isNaN(idx)) {
      return data[idx];
    } else {
      return {};
    }
  };

  const getSaveButtonText = () => {
    if (isFromWorkQueue && data) {
      return "Approve";
    } else if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus={!isFromWorkQueue}
    >
      <Formik
        validationSchema={schema}
        initialValues={loadInitialValues()}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader
                title={!isNaN(idx) ? "Edit Passport" : "Create Passport"}
              />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="passportnumber"
                      type="text"
                      label={"Passport Number *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="placeofissue"
                      type="text"
                      label={"Place of Issue *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="placeofbirth"
                      type="text"
                      label={"Place of Birth *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="status"
                      type="select"
                      options={passportStatus?.map(optionMapper)}
                      label={"Status *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="issueddate"
                      type="date"
                      label={"Issued Date *"}
                      component={DateFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="expirydate"
                      type="date"
                      label={"Expiry Date *"}
                      component={DateFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="passportdocument"
                      component={FileUploadForm}
                      label="Passport Document *"
                      type="text"
                      employeeId={employeeID}
                      sectionKey="passport"
                    />
                  </Col>
                </Row>
              </Modal.Body>
              {props.actions ? (
                props.actions(formik)
              ) : (
                <AppModalFooter
                  saveButtonText={getSaveButtonText()}
                  saveButtonDisabled={
                    !formik.isValid || formik.isSubmitting || !formik.dirty
                  }
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
