import React from "react"
import { Paper, Tabs, Tab, Badge } from "@material-ui/core"
import PropTypes from "prop-types"
import EmployeeSort from "../EmployeeSort"
import { Link } from "react-router-dom"
import TabPanel from "../../../../../shared/tabPanel"

export default function TabsView(props) {
  const { handleTabChange, tabValue, modules, listAll, employeesCount } = props
  return (
    <div>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab
            label="Active"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Active"
            //     max="100000"
            //     badgeContent={employeesCount.active}
            //   />
            // }
          />
          <Tab
            label="InActive"
            // label={
            //   <Badge
            //     color="primary"
            //     children="InActive"
            //     max="100000"
            //     badgeContent={employeesCount.inactive}
            //   />
            // }
          />
          <Tab
            label="Exit"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Suspended"
            //     max="100000"
            //     badgeContent={employeesCount.suspended}
            //   />
            // }
          />
          <Tab
            label="All"
            // label={
            //   <Badge
            //     color="primary"
            //     children="All"
            //     max="100000"
            //     badgeContent={employeesCount.all}
            //   />
            // }
          />
        </Tabs>
      </Paper>

      <TabPanel value={tabValue} index={0}>
        <EmployeeSort condition={1} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <EmployeeSort condition={2} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <EmployeeSort condition={3} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <EmployeeSort condition={0} />
      </TabPanel>
    </div>
  )
}
