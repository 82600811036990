import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import Presentation from "./Presentation"
import { addSectionToPlacement, updatePlacement } from "../../../../../middleware"
import { addDays } from "date-fns"
import { CalendarFormatter } from "../../../../../../../shared/CalendarFormatter"
import { uploadToStorage } from "../../../../../../../shared/fileUploader"
import CircularSpinner from "../../../../../../../shared/circularSpinner"

function Container(props) {
  const {
    placement,
    addSectionToPlacement,
    profile,
    payments_data,
    updatePlacement,
    invoices_data,
  } = props

  const initState = {
    OTbillingRate: 0,
    OTpayRate: 0,
    billingRate: 0,
    billingType: "",
    effectiveDate: "",
    effectiveUntil: "",
    employeePayRate: 0,
    payType: "",
    fixedPay: 0,
    purchaseOrderNumber: 0,
    percentage: 0,
    fillableSections: [],
    id: "",
    employeeID: profile.uid,
    data: [],
    update: false,
    index: null,
    disableOT: false,
    discountDetails: {
      name: '',
      type: '',
      value: 0
    }
  }

  const [state, setState] = useState(initState)
  const [file, setFile] = useState(null)
  const [fileURL, setFileURL] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    const type = state.discountDetails.type
    const value = state.discountDetails.value

    const getRate = (rate) => {
      let _rate = Number(rate)
      let discountAmount = 0
      if (type === 'byValue') {
        discountAmount = Number(value)
      } else if (type === 'byPercentage') {
        discountAmount = (_rate * Number(value)) / 100
      }

      _rate -= discountAmount
      return (_rate * state.percentage) / 100
    }
		
    const standardRate = getRate(state.billingRate)
    const otRate = getRate(state.OTbillingRate)
		setState((prevState) => ({
		  ...prevState,
		  employeePayRate: standardRate,
		  OTpayRate: otRate,
		}))
	}, [state.billingRate, state.percentage, state.OTbillingRate, state.discountDetails.type, state.discountDetails.value])

  useEffect(() => {
    if (isLoaded(payments_data) && !isEmpty(payments_data)) {
      const lastRecord = payments_data.data[payments_data.data.length - 1]
      const { effectiveUntil } = lastRecord
      if (!state.update)
        setState((prevState) => {
          return {
            ...prevState,
            effectiveDate: addDays(new Date(effectiveUntil), 1),
            effectiveUntil: "",
          }
        })
    } else {
      setState(() => {
        return {
          ...initState,
          effectiveDate: placement.startDate,
          effectiveUntil: placement.endDate,
        }
      })
    }
    setState((prevState) => ({
      ...prevState,
      fillableSections: placement.fillableSections,
      id: placement.id,
    }))
    if (isLoaded(invoices_data) && !isEmpty(invoices_data)) {
      const { OT } = invoices_data
      setState((state) => ({ ...state, disableOT: !OT }))
    }
    // return () => setState(() => ({ ...initState }))
  }, [placement, payments_data, invoices_data])

  const clearState = () => {
    setState((prevState) => ({
      ...prevState,
      OTbillingRate: 0,
      OTpayRate: 0,
      billingRate: 0,
      billingType: "",
      effectiveDate: "",
      effectiveUntil: "",
      employeePayRate: 0,
      payType: "",
      fixedPay: 0,
      purchaseOrderNumber: 0,
      percentage: 0,
      index: null,
      update: false,
      discountDetails: {
        name: '',
        type: '',
        value: 0
      }
    }))
    setFile(null)
    setFileURL(null)
    setIsUploading(false)
  }

  const handleUpdate = (e, idx) => {
    e.preventDefault()
    clearState()
    const data = payments_data?.data[idx]
    if ('attchmentDetails' in data) {
      setFileURL(data?.attchmentDetails?.url)
    }
    setState({
      ...initState,
      ...data,
      update: true,
      index: idx,
    })
  }

  const handleFile = (e) => {
    if (e.target.files.length > 0) {
			const _file = e.target.files[0]
			setFile(_file)
      setFileURL(URL.createObjectURL(_file))
		}
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const { closeTab } = props
      let attchmentDetails = {
        name: '',
        url: ''
      }
      if (file !== null) {
        setIsUploading(true)
        const fileName = [profile.name, placement.id, new Date().toISOString()].join('_')
        const filePath = `Placements/${placement.id}/Payments/${fileName}`
        const _fileURL = await uploadToStorage(file, filePath, fileName, 'file')
        attchmentDetails = {
          name: fileName,
          url: _fileURL
        }
        setFileURL(_fileURL)
        setIsUploading(false)
      }
      if (state.fillableSections.includes("payments")) {
        console.log("create")
        let payload = {
          data: [
            {
              OTbillingRate: state.OTbillingRate,
              OTpayRate: state.OTpayRate,
              billingRate: state.billingRate,
              billingType: state.billingType,
              effectiveDate: state.effectiveDate,
              effectiveUntil: state.effectiveUntil,
              employeePayRate: state.employeePayRate,
              payType: state.payType,
              fixedPay: state.fixedPay,
              purchaseOrderNumber: state.purchaseOrderNumber,
              percentage: state.percentage,
              discountDetails: state.discountDetails,
              attchmentDetails: attchmentDetails
            },
          ],
        }
        console.log(payload)
        addSectionToPlacement(payload, "payments", profile.uid, placement.id, clearState, closeTab)
      } else {
        let data = JSON.stringify(payments_data?.data)
        data = JSON.parse(data)
        const { index } = state
        const payment = {
          OTbillingRate: state.OTbillingRate,
          OTpayRate: state.OTpayRate,
          billingRate: state.billingRate,
          billingType: state.billingType,
          effectiveDate: state.effectiveDate,
          effectiveUntil: state.effectiveUntil,
          employeePayRate: state.employeePayRate,
          payType: state.payType,
          fixedPay: state.fixedPay,
          purchaseOrderNumber: state.purchaseOrderNumber,
          percentage: state.percentage,
          discountDetails: state.discountDetails,
          attchmentDetails: attchmentDetails
        }
        if (index !== null) {
          data[state.index] = payment
          if (state.index !== payments_data?.data.length - 1) {
            let temp = data[index + 1]
            let date = new Date(payment.effectiveUntil)
            console.log(date.toISOString())
            date.setDate(date.getDate() + 1)
            let updatedData = { ...temp, effectiveDate: date.toISOString() }
            console.log(date.toISOString())
            data[index + 1] = updatedData
          }
          if (index !== 0) {
            let temp = data[index - 1]
            let date = new Date(payment.effectiveDate)
            console.log(date.toISOString())
            date.setDate(date.getDate() - 1)
            let updatedData = { ...temp, effectiveUntil: date.toISOString() }
            console.log(date.toISOString())
            data[index - 1] = updatedData
          }
        } else {
          data = [...data, payment]
        }
        const payload = {
          data: data,
        }
        updatePlacement(payload, "payments", profile.uid, placement.id, clearState, closeTab)
      }
    } catch (error) {
      console.error(error)
      if (error.toString().match("invalid size")) {
        setIsUploading(false)
        setFile(null)
        setFileURL(null)
      }
    }
  }

  const handleChange_Strings = (event) => {
    const { name, value } = event.target
    setState({
      ...state,
      [name]: value,
    })
  }

  const handleChange_Numbers = (event) => {
    const { name, value } = event.target;
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setState({
        ...state,
        [name]: numericValue.toFixed(2),
      });
    }
  };
  

  const handleChange_Date = (key, value) => {
    if (!isNaN(Date.parse(value))) {
      const date = new Date(value)
      setState({
        ...state,
        [key]: CalendarFormatter.standardDateFormat(date),
      })
    }
  }

  const handleDiscount = (e) => {
    const { name, value } = e.target
    setState({
      ...state,
      discountDetails: {
        ...state.discountDetails,
        [name]: value
      }
    })
  }

  if (isLoaded(payments_data)) {
    return (
      <Presentation
        state={state}
        handleSubmit={handleSubmit}
        handleChange_Strings={handleChange_Strings}
        handleChange_Numbers={handleChange_Numbers}
        handleChange_Date={handleChange_Date}
        handleUpdate={handleUpdate}
        clearState={clearState}
        min_date={placement.startDate}
        handleDiscount={handleDiscount}
        handleFile={handleFile}
        fileURL={fileURL}
        isUploading={isUploading}
        historyData={payments_data?.data}
      />
    )
  }
  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { payments_data, invoices_data } = state.firestore.data
  return {
    payments_data,
    invoices_data,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addSectionToPlacement: (payload, sectionName, uid, placementID, callback, closeTab) => {
      dispatch(addSectionToPlacement(payload, sectionName, uid, placementID, callback, closeTab))
    },
    updatePlacement: (payload, sectionName, uid, placementID, callback, closeTab) => {
      dispatch(updatePlacement(payload, sectionName, uid, placementID, callback, closeTab))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const { placement } = props
    if (!placement.id) return []
    return [
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "payments",
              },
            ],
          },
        ],
        storeAs: "payments_data",
      },
    ]
  })
)(Container)
