import React, { useEffect, useState } from 'react'
import BackspaceIcon from '@material-ui/icons/Backspace';
import { Dialog, Typography, DialogContent, Button, DialogActions, IconButton, CircularProgress, FormHelperText, TextField } from "@material-ui/core"
import styles from "../../../styles/dialogStyles"
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { HiOutlineBan } from "react-icons/hi"
import { useInvoiceListContext } from "../context";
import SunEditor from "suneditor-react"
import WarningIcon from "@material-ui/icons/Warning";
import { Tooltip } from '@material-ui/core';
import parser from 'html-react-parser'


function EditVoidInvoice(props) {
  const { invoiceID, voidReason: theVoidReason } = props
  const { services } = useInvoiceListContext()
  const [open, setOpen] = React.useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [voidReason, setVoidReason] = useState('')

  useEffect(() => {
    setVoidReason(theVoidReason)
  }, [invoiceID, theVoidReason])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = async () => {
    try {
      setShowSpinner(true)
      handleClose()
      const isUpdatingVoidReason = 1
      await services.makeInvoiceVoid(invoiceID, voidReason, isUpdatingVoidReason)
      setShowSpinner(false)
    } catch (error) {
      setShowSpinner(false)
    }
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Tooltip
        placement="top"
        title={<div style={{ fontSize: "13px" }}>{parser(theVoidReason)}</div>}
      >
        <WarningIcon onClick={handleClickOpen} className='chip-danger c-pointer' style={{ fontSize: '19px', marginTop: '3px', background: 'transparent' }} />
      </Tooltip>

      <Dialog
        onClose={handleClose}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          You are about to update the void remarks{" "}
          <span className="custom-chip chip-danger">{`${invoiceID}`}</span> ?
        </DialogTitle>
        <DialogContent dividers>
          <SunEditor
            placeholder="Remarks*"
            onChange={(content) => setVoidReason(content)}
            setContents={voidReason}
            height={200}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "outdent",
                  "indent",
                  "list",
                  "table",
                  "link",
                  "image",
                  "fullScreen",
                  "showBlocks",
                  "print",
                  "save",
                ],
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleYes}
            // disabled={isVoiding}
            variant="contained"
            color="primary"
            disabled={voidReason.length < 1}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditVoidInvoice
