import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import CreatableSelectFormField from "../../../../../../shared/components/BootStrapFormFields/CreatableSelectFormField";
import { getAllSkills, postSkills } from "../../../../../../api/services/default/resourcemanagement"; 
import { useMutation, useQuery } from "@tanstack/react-query";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import { Constants } from "../../../../../../shared/constants";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";


const schema = yup.object().shape({
  skill: yup.string().required('skill is required'),
  rating: yup.string().required('rating is required'),
  review: yup.string()
});


export default function SkillsEditModal(props) {
  const { skillsdata, employeeid, idx, onHide } = props;
  const dispatch = useDispatch();
   const {data, refetch } = useQuery({
    queryKey: ["getAllSkills"],
    queryFn: () => getAllSkills(),
   })
  
  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return skillsdata[idx];
    } else {
      return {};
    }
  };
 

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit Skills";
    } else {
      return "Create Skills";
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    console.log(values)
    const callback = () => {
      actions.setSubmitting(false);
      onHide();
    };
    const formData = { ...values };
    let skills = skillsdata?.map((item) => item) || [];
    if (!isNaN(idx)) {
      skills[idx] = formData;
    } else {
      skills.push(formData);
    }
    const payload = {
      skills,
    };
    console.log({payload})
    dispatch(updateProfile(payload, employeeid, payload, callback));
  };


  const useCreateSkills = useMutation({
		mutationFn: ({ data }) => postSkills(data),
		onSuccess: () => {
			refetch()
		},
	});

  const handleField = async(value , cb) => {
    cb()
    await useCreateSkills.mutateAsync({ data : {
      skillData : value
    }})
  }

 


  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        initialValues={{ ...loadInitialValues() }}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title={getTitleText()} />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="skill"
                      type="select"
                      label={"Skills *"}
                      component={CreatableSelectFormField}
                      options={data?.skills?.map((item) => ({
                        value: item,
                        label: item,
                      })) || []}
                      handleCreate={(value, cb) => {
                        handleField(value , cb)
                      }}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="rating"
                      type="select"
                      label={"Rating *"} 
                     // Icon={<CircularProgress variant="determinate"  thickness={22} size={20} className="text-success" style={{opacity :  2 * 0.2 + 0.2}}  value={Math.round(100/4 * 2)}/>}
                      component={SelectFormField}
                      options={Constants.SKILL_RATINGS}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="review"
                      type="select"
                      label={"Review "} 
                      component={TextFormField}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText={getSaveButtonText()}
                saveButtonDisabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
