import React, { useState } from "react";
import { useAnnouncementContext } from "./context";
import useStyles from "../../shared/styles/dialogStyles";
import {
  AppBar,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import SimpleBackdrop from "../../shared/backDrop";
import { Autocomplete } from "@material-ui/lab";
import SunEditor from "suneditor-react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function AddAnnouncement(props) {
  const classes = useStyles();
  const { state, services } = useAnnouncementContext();

  const initState = {
    to: [],
    cc: [],
    bcc: [],
    groupType: "",
    groupList: [],
    selectedGroupList: [],
    subject:"",
    body:""
  };

  const [open, setOpen] = useState(false);
  const [announcement, setAnnouncement] = useState(initState);
  const [disableSend, setDisableSend] = useState(false);

  useEffect(() => {
    let disable = announcement.subject.length < 1 || announcement.body.length < 1 ||(announcement.to.length<1 && announcement.bcc.length<1 && announcement.selectedGroupList.length<1)
    setDisableSend(disable)
  }, [announcement.subject,announcement.body,announcement.to,announcement.bcc,announcement.selectedGroupList])
  
  const getData = () => {
    services.getEmployeesList();
    services.getBranchList(); 
    services.getCategoryList();
    services.getDepartmentList();  
    services.getEmployeeStatusList() 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnnouncement((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    });
  };


  const handleClickOpen = () => {
    getData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnnouncement(initState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      to : announcement.to,
      bcc : announcement.bcc,
      subject: announcement.subject,
      groupType: announcement.groupType,
      groupList: announcement.selectedGroupList,
      body: announcement.body
    }
    services.addNewAnnouncemnt(payload, handleClose);
  };

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  return (
    <>
      <SimpleBackdrop openBackdrop={state.isLoading} />
      <Button className="btn btn-success px-4 btn-md text-high " onClick={handleClickOpen}>
        Add new Announcement
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
      >
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component="h1" variant="h4" align="center">
                Add new Announcement
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <main className={classes.layout}>
        <p style={{color:"red"}}>*Please atleast one in to or bcc or groupList</p>
          <Paper className={classes.paper}>
            <React.Fragment>
              <Typography variant="h6" gutterBottom></Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={state.employeesList}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      onChange={(e, v) =>
                        handleChange({
                          target: { name: "to", value: v.map((x) => x.id) },
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth label="To" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={state.employeesList}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      onChange={(e, v) =>
                        handleChange({
                          target: { name: "bcc", value: v.map((x) => x.id) },
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth label="BCC" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Subject"
                      name="subject"
                      value={announcement.subject}
                      onChange={(e) =>
                        handleChange({
                          target: { name: "subject", value: e.target.value },
                        })
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      label="Group"
                      name="groupType"
                      value={announcement.group}
                      onChange={(e) => {
                        const groupType = e.target.value;
                        let groupList = [];
                        handleChange({
                          target: { name: "groupType", value: groupType },
                        });
                        if (groupType === "branch") {
                          groupList = state.branchList;
                        } else if (groupType === "department") {
                          groupList = state.departmentList;
                        } else if (groupType === "category") {
                          groupList = state.categoryList;
                        } else if (groupType === "employeeStatus") {
                          groupList = state.employeeStatusList;
                        }
                        handleChange({
                          target: { name: "groupList", value: groupList },
                        });
                        handleChange({
                          target: { name: "selectedGroupList", value: [] },
                        });
                      }}
                      size="small"
                    >
                      <MenuItem value="branch">Branch</MenuItem>
                      <MenuItem value="department">Department</MenuItem>
                      <MenuItem value="category">Category</MenuItem>
                      <MenuItem value="employeeStatus">Employee Status</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Group Lists</InputLabel>
                    <Select
                      multiple
                      value={announcement.selectedGroupList}
                      onChange={(e) => {
                        const selectedGroupList = e.target.value;
                        handleChange({
                          target: { name: "selectedGroupList", value: selectedGroupList },
                        });
                      }}
                      input={<OutlinedInput label="Group List" />}
                      style={{width: '100%',height:"35px"}}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {announcement.groupList.map((item, idx) => (
                        <MenuItem key={item} value={item} style={{height:"35px"}}>
                          <Checkbox
                            checked={announcement.selectedGroupList.indexOf(item) > -1}
                          />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <SunEditor
                      placeholder="Body"
                      setContents={announcement.body}
                      onChange={(value) =>
                        handleChange({ target: { name: 'body', value }})
                      }
                      height={200}
                      required
                      setOptions={{
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "font",
                            "fontSize",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link",
                            "image",
                            "video",
                            "showBlocks",
                            "codeView",
                          ],
                        ],
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="text-center" style={{marginTop:"10px"}}>
                  <Button type="submit" variant="contained" disabled = {disableSend} color="primary">
                    Send
                  </Button>
                </div>
              </form>
            </React.Fragment>
          </Paper>
        </main>
      </Dialog>
    </>
  );
}
